import { useMutation, useQuery } from "@tanstack/react-query";
import { z } from "zod";

import {
  type Market,
  type Plan,
  type ShopifyShop,
  type WebsiteDetails
} from "elevar-common-ts/src/apiTypes";

import { useWebsiteId } from "../../utils/idHooks";
import { apiRequest, getApiAuthToken, useExtendedQueryClient } from "../utils";

/* ========================================================================== */

export const shopifyAccessErrorSchema = z.object({
  cause: z.object({
    errors: z.object({ code: z.literal("SHOPIFY_LACKS_ACCESS") })
  })
});

export const shopifyScopeErrorSchema = z.object({
  cause: z.object({
    errors: z.object({ code: z.literal("SHOPIFY_LACKS_SCOPE") })
  })
});

/* ========================================================================== */

type ShopifyLoginArgs = { params: Record<string, string> };
type ShopifyLoginResponseData = { redirect_url: string };

export const shopifyLogin = ({ params }: ShopifyLoginArgs) => {
  return apiRequest<ShopifyLoginResponseData>({
    endpoint: "/shopify/gtm_suite/login",
    params,
    requiresAuth: Boolean(getApiAuthToken())
  });
};

/* ========================================================================== */

type ShopifyFinalizeDetailsArgs = { params: Record<string, string> };
type ShopifyFinalizeResponseData =
  | { is_auth: boolean; website: WebsiteDetails | null; token: string }
  | { redirect_url: string };

export const shopifyFinalize = ({ params }: ShopifyFinalizeDetailsArgs) => {
  return apiRequest<ShopifyFinalizeResponseData>({
    endpoint: "/shopify/gtm_suite/finalize",
    params,
    requiresAuth: Boolean(getApiAuthToken())
  });
};

/* ========================================================================== */

type ShopifyLinkWebsiteDetailsArgs = { data: Record<string, string> };
type ShopifyLinkWebsiteResponseData =
  | { is_auth: boolean; website: WebsiteDetails; token: string }
  | { redirect_url: string };

export const shopifyLinkWebsite = ({ data }: ShopifyLinkWebsiteDetailsArgs) => {
  return apiRequest<ShopifyLinkWebsiteResponseData>({
    endpoint: "/shopify/gtm_suite/link_website",
    method: "POST",
    data,
    requiresAuth: Boolean(getApiAuthToken())
  });
};

/* ========================================================================== */

type ShopifyApproveSubscriptionArgs = { params: Record<string, string> };
type ShopifyApproveSubscriptionResponseData = {
  is_auth: boolean;
  website: WebsiteDetails;
  token: string;
  previous_plan: Plan;
  had_previously_subscribed: boolean;
  completed_upgrade: boolean;
  product_identifier?: string;
};

const shopifyApproveSubscription = ({
  params
}: ShopifyApproveSubscriptionArgs) => {
  return apiRequest<ShopifyApproveSubscriptionResponseData>({
    endpoint: "/shopify/gtm_suite/approve_subscription",
    params
  });
};

export const useShopifyApproveSubscriptionMutation = () => {
  const queryClient = useExtendedQueryClient();

  return useMutation({
    mutationFn: shopifyApproveSubscription,
    onSuccess: async result => {
      await queryClient.extensions.invalidateOrRemoveQueriesList([
        ["websites", result.website.id, "details"]
      ]);
    }
  });
};

/* ========================================================================== */

type UpdateShopifyShopDetailsRequestData = Partial<
  Pick<ShopifyShop, "event_config" | "uses_custom_pixel">
>;

type UpdateShopifyShopDetailsArgs = {
  websiteId: number;
  data: UpdateShopifyShopDetailsRequestData;
};

const updateShopifyShopDetails = ({
  websiteId,
  data
}: UpdateShopifyShopDetailsArgs) => {
  return apiRequest<ShopifyShop, UpdateShopifyShopDetailsRequestData>({
    endpoint: `/shopify/shop/${websiteId}/`,
    method: "PATCH",
    data
  });
};

export const useShopifyShopDetailsMutation = () => {
  const websiteId = useWebsiteId();
  const queryClient = useExtendedQueryClient();

  return useMutation({
    mutationFn: (data: UpdateShopifyShopDetailsRequestData) => {
      return updateShopifyShopDetails({ websiteId, data });
    },
    onSuccess: async () => {
      await queryClient.extensions.invalidateOrRemoveQueriesList([
        ["websites", websiteId, "details"]
      ]);
    }
  });
};

/* ========================================================================== */

type FetchShopifyCheckoutSnippetArgs = { websiteId: number };
type FetchShopifyCheckoutSnippetResponseData = { template: string };

const fetchShopifyCheckoutSnippet = (args: FetchShopifyCheckoutSnippetArgs) => {
  return apiRequest<FetchShopifyCheckoutSnippetResponseData>({
    endpoint: `/shopify/shop/${args.websiteId}/shopify_checkout_snippet/`,
    method: "POST"
  });
};

export const useShopifyCheckoutSnippetQuery = () => {
  const websiteId = useWebsiteId();

  return useQuery({
    queryKey: ["websites", websiteId, "shopifyCheckoutSnippet"],
    queryFn: () => fetchShopifyCheckoutSnippet({ websiteId })
  });
};

/* ========================================================================== */

type FetchCustomPagesSnippetArgs = { websiteId: number };
type FetchCustomPagesSnippetResponseData = { template: string };

const fetchCustomPagesSnippet = ({
  websiteId
}: FetchCustomPagesSnippetArgs) => {
  return apiRequest<FetchCustomPagesSnippetResponseData>({
    endpoint: `/shopify/shop/${websiteId}/custom_pages_snippet/`,
    method: "POST"
  });
};

export const useCustomPagesSnippetQuery = () => {
  const websiteId = useWebsiteId();

  return useQuery({
    queryKey: ["websites", websiteId, "customPagesSnippet"],
    queryFn: () => fetchCustomPagesSnippet({ websiteId })
  });
};

/* -------------------------------------------------------------------------- */

type FetchShopifyApexDomainArgs = { websiteId: number };
type FetchShopifyApexDomainResponseData = { apex_domain: string };

export const fetchShopifyApexDomain = async ({
  websiteId
}: FetchShopifyApexDomainArgs) => {
  const data = await apiRequest<FetchShopifyApexDomainResponseData>({
    endpoint: `/shopify/shop/${websiteId}/fetch_apex_domain/`
  });
  return data.apex_domain;
};

/* ========================================================================== */

type ShopifyOAuthScope =
  | "read_themes"
  | "read_orders"
  | "read_checkouts"
  | "write_pixels"
  | "read_customer_events"
  | "read_customers"
  | "read_markets";

export const requiredScopes: Array<ShopifyOAuthScope> = [
  "read_themes",
  "read_orders",
  "read_checkouts",
  "write_pixels",
  "read_customer_events"
];

export type ShopifyOAuthScopes =
  | { scopes: Array<ShopifyOAuthScope> }
  | { redirect_url: string }
  | null;

type FetchShopifyOAuthScopesArgs = { websiteId: number };
type FetchShopifyOAuthScopesResponseData = Exclude<ShopifyOAuthScopes, null>;

const fetchShopifyOAuthScopes = async ({
  websiteId
}: FetchShopifyOAuthScopesArgs) => {
  try {
    const data = await apiRequest<FetchShopifyOAuthScopesResponseData>({
      endpoint: `/shopify/shop/${websiteId}/oauth_scopes/`
    });
    return data;
  } catch {
    return null;
  }
};

export const useShopifyOAuthScopesQuery = () => {
  const websiteId = useWebsiteId();

  return useQuery({
    queryKey: ["websites", websiteId, "shopifyOAuthScopes"],
    queryFn: () => fetchShopifyOAuthScopes({ websiteId })
  });
};

/* ========================================================================== */

type RedactCustomerRequestData = { type: "email" | "phone"; value: string };

type RedactCustomerArgs = {
  websiteId: number;
  data: RedactCustomerRequestData;
};

const redactCustomer = ({ websiteId, data }: RedactCustomerArgs) => {
  return apiRequest<unknown, RedactCustomerRequestData>({
    endpoint: `/shopify/shop/${websiteId}/redact_customer/`,
    method: "POST",
    data
  });
};

export const useRedactCustomerMutation = () => {
  const websiteId = useWebsiteId();

  return useMutation({
    mutationFn: (data: RedactCustomerRequestData) => {
      return redactCustomer({ websiteId, data });
    }
  });
};

/* ========================================================================== */

type AppEmbedCheckType = "PLACEHOLDER" | "CACHED" | "FRESH";
type CheckAppEmbedBlockArgs = { websiteId: number; type: AppEmbedCheckType };
type CheckAppEmbedBlockResponseData = { enabled: boolean };

const checkAppEmbedBlock = async ({
  websiteId,
  type
}: CheckAppEmbedBlockArgs) => {
  if (type === "PLACEHOLDER") {
    return { enabled: false };
  } else {
    return apiRequest<CheckAppEmbedBlockResponseData>({
      endpoint: `/shopify/shop/${websiteId}/app_embed_block_check/`,
      ...(type === "FRESH" ? { params: { force: "true" } } : {})
    });
  }
};

type UseAppEmbedBlockCheckQueryArgs = { type: AppEmbedCheckType };

export const useAppEmbedBlockCheckQuery = ({
  type
}: UseAppEmbedBlockCheckQueryArgs) => {
  const websiteId = useWebsiteId();

  return useQuery({
    queryKey: ["websites", websiteId, "appEmbedBlockCheck"],
    queryFn: () => checkAppEmbedBlock({ websiteId, type }),
    ...(type === "FRESH" ? { refetchOnWindowFocus: "always" } : {})
  });
};

/* -------------------------------------------------------------------------- */

type EnableAppEmbedBlockResponseData = { embed_block_deep_link: string };
type EnableAppEmbedBlockArgs = { websiteId: number };

const enableAppEmbedBlock = async ({ websiteId }: EnableAppEmbedBlockArgs) => {
  const response = await apiRequest<EnableAppEmbedBlockResponseData>({
    endpoint: `/shopify/shop/${websiteId}/app_embed_block_enable/`,
    method: "POST"
  });
  return { shopifyUrl: response.embed_block_deep_link };
};

export const useAppEmbedBlockEnableMutation = () => {
  const websiteId = useWebsiteId();
  const queryClient = useExtendedQueryClient();

  return useMutation({
    mutationFn: () => enableAppEmbedBlock({ websiteId }),
    onSuccess: async () => {
      await queryClient.extensions.invalidateOrRemoveQueriesList([
        ["websites", websiteId, "details"]
      ]);
    }
  });
};

/* ========================================================================== */

type CheckLegacySnippetsArgs = { websiteId: number };
type CheckLegacySnippetsResponseData = { found_render_tags: boolean };

const checkLegacySnippets = async ({ websiteId }: CheckLegacySnippetsArgs) => {
  return apiRequest<CheckLegacySnippetsResponseData>({
    endpoint: `/shopify/shop/${websiteId}/legacy_snippets_check/`
  });
};

export const useLegacySnippetsCheckMutation = () => {
  const websiteId = useWebsiteId();

  return useMutation({
    mutationFn: () => checkLegacySnippets({ websiteId })
  });
};

/* ========================================================================== */

type ShopifyMarketInternal = {
  id: number;
  tracking_market: number;
  enabled: boolean;
  deleted: boolean;
};

type ShopifyMarket = Market &
  Pick<ShopifyMarketInternal, "enabled" | "deleted">;

export type ShopifyMarkets = Array<ShopifyMarket>;

const fetchShopifyMarkets = async (
  websiteId: number
): Promise<Array<ShopifyMarket>> => {
  const shopifyMarkets = await apiRequest<Array<ShopifyMarketInternal>>({
    endpoint: `/shopify/websites/${websiteId}/markets`
  });
  const trackingMarkets = await apiRequest<Array<Market>>({
    endpoint: `/tracking/websites/${websiteId}/markets`
  });
  return shopifyMarkets.map(shopifyMarket => ({
    ...trackingMarkets.find(m => m.id === shopifyMarket.tracking_market)!,
    enabled: shopifyMarket.enabled,
    deleted: shopifyMarket.deleted
  }));
};

export const useShopifyMarketsQuery = () => {
  const websiteId = useWebsiteId();

  return useQuery({
    queryKey: ["websites", websiteId, "shopifyMarkets"],
    queryFn: () => fetchShopifyMarkets(websiteId)
  });
};
