import { uniq } from "lodash-es";
import { useState } from "react";
import styled, { useTheme } from "styled-components";

import { InputFieldText } from "elevar-design-system/src/inputs/InputFieldText";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import {
  LinkExternal,
  StyledLinkExternal
} from "elevar-design-system/src/links/LinkExternal";
import { linkStyles } from "elevar-design-system/src/links/links";
import { normalBodyStyles } from "elevar-design-system/src/typography/typography";

import { type ShopifyOAuthScopes } from "../../api/handlers/shopify";
import { PageCard } from "../../components/PageCard";
import { createSetupFlow } from "../../context/SetupFlowDetails";
import { ConsentModeReader } from "./ConsentModeReader";
import { destinationPbsb as destination } from "./data";
import {
  EventDestinationTable,
  getRecommendedEventState
} from "./EventDestinationTable";
import { FilterTransactions } from "./FilterTransactions";
import { MarketGroupSettings } from "./MarketGroupSettings";
import { Overview } from "./Overview";
import { StepSection } from "./StepSection";
import { Subscriptions } from "./Subscriptions";

/* ========================================================================== */

const setupGuideHref =
  "https://docs.getelevar.com/docs/how-to-setup-elevars-google-pub-sub-integration";

type SetupFlowContext = { shopifyOAuthScopes: ShopifyOAuthScopes };

const {
  SetupFlow,
  useConfigRequired,
  useSetupFlowDetails,
  useSetupFlowContext
} = createSetupFlow<SetupFlowContext>().destination(destination);

type DestinationPbsbProps = {
  isCompanyAdmin: boolean;
  shopifyOAuthScopes: ShopifyOAuthScopes;
};

export const DestinationPbsb: React.FC<DestinationPbsbProps> = ({
  isCompanyAdmin,
  shopifyOAuthScopes
}) => {
  return (
    <SetupFlow
      isCompanyAdmin={isCompanyAdmin}
      steps={[
        { details: { type: "SETTINGS" } },
        { details: { type: "EVENTS" } },
        { details: { type: "CONSENT_MODE" } },
        { details: { type: "FILTER_TRANSACTIONS" } },
        { details: { type: "SUBSCRIPTIONS" } }
      ]}
      context={{ shopifyOAuthScopes }}
    >
      <StepContent />
    </SetupFlow>
  );
};

/* ========================================================================== */

const StepContent: React.FC = () => {
  const { currentStep } = useSetupFlowDetails();

  switch (currentStep) {
    case 0:
      return <Step0 />;
    case 1:
      return <Step1 />;
    case 2:
      return <Step2 />;
    case 3:
      return <Step3 />;
    case 4:
      return <Step4 />;
    case 5:
      return <Step5 />;
  }
};

/* ========================================================================== */

type Events = ReturnType<typeof useConfigRequired>["enabledEvents"];

const recommendedEvents: Events = {
  addPaymentInfo: true,
  addShippingInfo: true,
  addToCart: true,
  beginCheckout: true,
  login: true,
  pageView: true,
  purchase: true,
  refund: true,
  removeFromCart: true,
  signUp: true,
  selectItem: true,
  subscribe: true,
  subscriptionPurchase: true,
  viewCart: true,
  viewItem: true,
  viewItemList: true,
  viewSearchResults: true
};

const Step0: React.FC = () => {
  const setupFlow = useSetupFlowDetails();

  return (
    <Overview
      destination={destination}
      config={setupFlow.config}
      configMutation={setupFlow.configMutation}
      configLabel={setupFlow.configLabel}
      completedStep={setupFlow.completedStep}
      isStepCompleted={setupFlow.isStepCompleted}
      setCurrentStep={setupFlow.setCurrentStep}
      getConfigSummaryItems={config => [
        {
          step: 1,
          type: "CUSTOM",
          render: () => (
            <>
              The Pub/Sub Topic ID used is <span>{config.topicId}</span>
            </>
          )
        },
        {
          step: 2,
          type: "EVENTS",
          selectedEvents: {
            enabledEvents: config.enabledEvents,
            enabledWebEvents: {},
            webhookOverrides: config.webhookOverrides
          },
          recommendedEvents: getRecommendedEventState({
            destination,
            enabledEvents: recommendedEvents,
            enabledWebEvents: {},
            webhookOverrides: config.webhookOverrides
          })
        },
        {
          step: 3,
          type: "CONSENT_MODE",
          inEnabled: config.consentMode.enabled
        },
        {
          step: 4,
          type: "FILTER_TRANSACTIONS",
          filters: config.orderFilters
        },
        {
          step: 5,
          type: "SUBSCRIPTIONS",
          filters: config.orderFilters,
          tagName: config.subscriptionTagName
        }
      ]}
      description={
        <Step0Explainer>
          Send meaningful data to {destination.name} so that you can focus on
          making strategic business decisions rather than worrying about your
          data.
        </Step0Explainer>
      }
      integrationNotes={{
        type: "ACTION_REQUIRED",
        content: (
          <div>
            If you don't already have a Google Cloud Project (GCP), please
            create one, as well as a new topic in the project. And grant{" "}
            <span>
              elevar-server-side-sa@elevar-engine.iam.gserviceaccount.com
            </span>{" "}
            publisher permissions to that topic.{" "}
            <LinkExternal href={setupGuideHref}>Go to Guide</LinkExternal>.
          </div>
        )
      }}
    />
  );
};

const Step0Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

/* ========================================================================== */

const Step1: React.FC = () => {
  const theme = useTheme();
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  const [projectId, setProjectId] = useState(config.projectId);
  const [topicId, setTopicId] = useState(config.topicId);

  const arePubSubFieldsFilledIn = projectId !== "" && topicId !== "";
  const arePubSubFieldsUnique = uniq([projectId, topicId]).length === 2;

  return (
    <Step1Wrapper>
      <PageCard>
        <StepSection
          title="Pub/Sub Settings"
          setupGuideHref={setupGuideHref}
          description={
            <Step1Explainer>
              In order to send your transactions data to this destination, we
              need the following information from your account.
            </Step1Explainer>
          }
        >
          <Step1SectionInnerWrapper>
            <div>
              <InputWrapper
                labelText="Google Project ID"
                disabled={setupFlow.isLoading}
                tooltip={{
                  maxWidth: `${theme.gridBase * 33}px`,
                  render: () => (
                    <Step1InputWrapperTooltipContent>
                      <p>
                        This is a unique identifier for a Google Cloud Project
                        you created.
                      </p>
                      <StyledLinkExternal
                        href="https://developers.google.com/workspace/guides/create-project"
                        text="How to find this?"
                      />
                    </Step1InputWrapperTooltipContent>
                  )
                }}
              >
                <InputFieldText
                  variant="SMALL"
                  disabled={setupFlow.isLoading}
                  value={projectId}
                  onChange={event => setProjectId(event.target.value)}
                  spellCheck={false}
                  autoCapitalize="off"
                  placeholder="i.e. product-id-1"
                />
              </InputWrapper>
            </div>
            <div>
              <InputWrapper
                labelText="Pub/Sub Topic ID"
                disabled={setupFlow.isLoading}
                tooltip={{
                  maxWidth: `${theme.gridBase * 32.5}px`,
                  render: () => (
                    <Step1InputWrapperTooltipContent>
                      <p>
                        This is a unique identifier for your Pub/Sub topic
                        within your project.
                      </p>
                      <StyledLinkExternal
                        href="https://cloud.google.com/pubsub/docs/create-topic#create_a_topic_2"
                        text="How to find this?"
                      />
                    </Step1InputWrapperTooltipContent>
                  )
                }}
              >
                <InputFieldText
                  variant="SMALL"
                  disabled={setupFlow.isLoading}
                  value={topicId}
                  onChange={event => setTopicId(event.target.value)}
                  spellCheck={false}
                  autoCapitalize="off"
                  placeholder="i.e. elevar-raw-data or my-topic"
                />
              </InputWrapper>
            </div>
          </Step1SectionInnerWrapper>
        </StepSection>
        <MarketGroupSettings
          config={config}
          destination={destination}
          isLoading={setupFlow.isLoading}
          isStepCompleted={setupFlow.isStepCompleted}
          saveButtonDisabledTooltipOverride={
            !arePubSubFieldsFilledIn
              ? "Please fill out all fields"
              : !arePubSubFieldsUnique
                ? "Please ensure all values are unique"
                : null
          }
          onSave={async data => {
            await setupFlow.configMutation({ ...data, projectId, topicId });
          }}
        />
      </PageCard>
    </Step1Wrapper>
  );
};

const Step1Wrapper = styled.div`
  > ${PageCard}:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }
`;

const Step1Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

const Step1SectionInnerWrapper = styled.div`
  > div:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 3}px;
  }
`;

const Step1InputWrapperTooltipContent = styled.div`
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;

  > p {
    ${normalBodyStyles};
    color: ${props => props.theme.palette.grey3};
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }

  > a {
    ${linkStyles};
  }
`;

/* ========================================================================== */

const Step2: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();
  const { shopifyOAuthScopes } = useSetupFlowContext();

  const [eventState, setEventState] = useState({
    enabledEvents: config.enabledEvents,
    enabledWebEvents: {},
    webhookOverrides: config.webhookOverrides
  });

  return (
    <PageCard>
      <StepSection
        title="Events"
        setupGuideHref={setupGuideHref}
        description={
          <Step2Explainer>
            Select what events you want to send to Pub/Sub.
          </Step2Explainer>
        }
      />
      <EventDestinationTable
        isLoading={setupFlow.isLoading}
        isStepCompleted={setupFlow.isStepCompleted}
        shopifyOAuthScopes={shopifyOAuthScopes}
        mutualExclusivityLevel="NONE"
        details={{
          eventState,
          setEventState,
          destinationName: destination.name
        }}
        recommended={{
          enabledEvents: recommendedEvents,
          enabledWebEvents: {}
        }}
        onSave={options => setupFlow.configMutation(eventState, options)}
      />
    </PageCard>
  );
};

const Step2Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

/* ========================================================================== */

const Step3: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  return (
    <ConsentModeReader
      details={{
        type: "DESTINATION",
        isStepCompleted: setupFlow.isStepCompleted,
        setupGuideHref,
        name: destination.name
      }}
      isLoading={setupFlow.isLoading}
      initial={config.consentMode}
      onSave={data => setupFlow.configMutation(data)}
    />
  );
};

/* ========================================================================== */

const Step4: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  return (
    <FilterTransactions
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      initialFilters={config.orderFilters}
      usesUnifiedCheckout={config.uses_unified_checkout}
      subscriptionTagName={config.subscriptionTagName}
      onSave={(data, options) => setupFlow.configMutation(data, options)}
    />
  );
};

/* ========================================================================== */

const Step5: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  return (
    <Subscriptions
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      details={{
        key: destination.configKey,
        config,
        onSave: (data, options) => setupFlow.configMutation(data, options)
      }}
    />
  );
};
