import dayjs from "dayjs";
import { useState } from "react";
import useMeasure from "react-use-measure";
import styled, { useTheme } from "styled-components";

import { ButtonPrimary } from "elevar-design-system/src/buttons/ButtonVariants";
import {
  IconCheckMark,
  IconChevronDown,
  IconChevronUp
} from "elevar-design-system/src/icons";
import { InputFieldSelect } from "elevar-design-system/src/inputs/InputFieldSelect";
import { InputFieldText } from "elevar-design-system/src/inputs/InputFieldText";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import { StyledLinkExternal } from "elevar-design-system/src/links/LinkExternal";
import { linkStyles } from "elevar-design-system/src/links/links";
import {
  normalBodyStyles,
  normalTextStyles,
  smallTextStyles
} from "elevar-design-system/src/typography/typography";

import { type ShopifyOAuthScopes } from "../../api/handlers/shopify";
import {
  useDestinationOAuthConnectMutation,
  useDestinationOAuthDisconnectMutation
} from "../../api/handlers/website";
import { ActionWarningModal } from "../../components/ActionWarningModal";
import { PageCard } from "../../components/PageCard";
import { createSetupFlow } from "../../context/SetupFlowDetails";
import { formatTitle } from "../../utils/format";
import { toast } from "../../utils/toast";
import { ConsentModeReader } from "./ConsentModeReader";
import { destinationTwtr as destination } from "./data";
import {
  EventDestinationTable,
  getRecommendedEventState
} from "./EventDestinationTable";
import { FilterTransactions } from "./FilterTransactions";
import { MarketGroupSettings } from "./MarketGroupSettings";
import { Overview } from "./Overview";
import { ProductIdentifier } from "./ProductIdentifier";
import { conversionValueOptions } from "./shared";
import { StepSection } from "./StepSection";
import { Subscriptions } from "./Subscriptions";

/* ========================================================================== */

const setupGuideHref = "https://docs.getelevar.com/docs/twitter";

type SetupFlowContext = { shopifyOAuthScopes: ShopifyOAuthScopes };

const {
  SetupFlow,
  useConfigRequired,
  useSetupFlowDetails,
  useSetupFlowContext
} = createSetupFlow<SetupFlowContext>().destination(destination);

type DestinationTwtrProps = {
  isCompanyAdmin: boolean;
  shopifyOAuthScopes: ShopifyOAuthScopes;
};

export const DestinationTwtr: React.FC<DestinationTwtrProps> = ({
  isCompanyAdmin,
  shopifyOAuthScopes
}) => {
  return (
    <SetupFlow
      isCompanyAdmin={isCompanyAdmin}
      steps={[
        { details: { type: "SETTINGS" } },
        { details: { type: "EVENTS" } },
        { details: { type: "CONSENT_MODE" } },
        { details: { type: "PRODUCT_IDENTIFIER" } },
        { details: { type: "FILTER_TRANSACTIONS" } },
        { details: { type: "SUBSCRIPTIONS" } }
      ]}
      context={{ shopifyOAuthScopes }}
    >
      <StepContent />
    </SetupFlow>
  );
};

/* ========================================================================== */

const StepContent: React.FC = () => {
  const { currentStep } = useSetupFlowDetails();

  switch (currentStep) {
    case 0:
      return <Step0 />;
    case 1:
      return <Step1 />;
    case 2:
      return <Step2 />;
    case 3:
      return <Step3 />;
    case 4:
      return <Step4 />;
    case 5:
      return <Step5 />;
    case 6:
      return <Step6 />;
  }
};

/* ========================================================================== */

type Events = ReturnType<typeof useConfigRequired>["enabledEvents"];

const recommendedEvents: Events = {
  addPaymentInfo: false,
  addShippingInfo: false,
  addToCart: true,
  beginCheckout: true,
  pageView: true,
  purchase: true,
  subscribe: false,
  viewCart: false,
  viewItem: true,
  viewItemList: false
};

const Step0: React.FC = () => {
  const setupFlow = useSetupFlowDetails();

  return (
    <Overview
      destination={destination}
      config={setupFlow.config}
      configMutation={setupFlow.configMutation}
      configLabel={setupFlow.configLabel}
      completedStep={setupFlow.completedStep}
      isStepCompleted={setupFlow.isStepCompleted}
      setCurrentStep={setupFlow.setCurrentStep}
      getConfigSummaryItems={config => [
        {
          step: 1,
          type: "CUSTOM",
          render: () => (
            <>
              The X (Twitter) Pixel ID used is <span>{config.adAccountId}</span>
            </>
          )
        },
        {
          step: 2,
          type: "EVENTS",
          selectedEvents: {
            enabledEvents: config.enabledEvents,
            enabledWebEvents: {},
            webhookOverrides: config.webhookOverrides
          },
          recommendedEvents: getRecommendedEventState({
            destination,
            enabledEvents: recommendedEvents,
            enabledWebEvents: {},
            webhookOverrides: config.webhookOverrides
          })
        },
        {
          step: 3,
          type: "CONSENT_MODE",
          inEnabled: config.consentMode.enabled
        },
        {
          step: 4,
          type: "PRODUCT_IDENTIFIER",
          value: config.dataConfig.productAttributeMapping
        },
        {
          step: 5,
          type: "FILTER_TRANSACTIONS",
          filters: config.orderFilters
        },
        {
          step: 6,
          type: "SUBSCRIPTIONS",
          filters: config.orderFilters,
          tagName: config.subscriptionTagName
        }
      ]}
      description={
        <Step0Explainer>
          Send meaningful data to {destination.name} so that you can focus on
          making strategic business decisions rather than worrying about your
          data.
        </Step0Explainer>
      }
      integrationNotes={{
        type: "HEADS_UP",
        content: (
          <div>
            {destination.name} has a limit of 60,000 events per account, per 15
            minute interval. If your store has more requests, we recommend
            disabling the page view and other events (see the “Events” step).
          </div>
        )
      }}
    />
  );
};

const Step0Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};

  > ul {
    margin-top: ${props => props.theme.gridBase}px;
    padding-left: ${props => props.theme.gridBase * 2}px;

    > li {
      list-style: disc;

      &:not(:last-child) {
        margin-bottom: ${props => props.theme.gridBase * 0.25}px;
      }
    }
  }
`;

/* ========================================================================== */

const Step1: React.FC = () => {
  const theme = useTheme();
  const [inputWrapperRef, { width: inputWrapperWidth }] = useMeasure();
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  const { mutateAsync: destinationOAuthConnectMutation } =
    useDestinationOAuthConnectMutation({ target: destination.configKey });
  const { mutateAsync: destinationOAuthDisconnectMutation } =
    useDestinationOAuthDisconnectMutation({ target: destination.configKey });

  const [isModalShown, setIsModalShown] = useState(false);
  const [pixelId, setPixelId] = useState(config.adAccountId ?? "");
  const [isAdvancedShown, setIsAdvancedShown] = useState(false);
  const [conversionValue, setConversionValue] = useState(
    config.dataConfig.conversionValue
  );

  const selectedConversionValueOption =
    conversionValueOptions.find(o => o.value === conversionValue) ?? null;

  const areXFieldsFilledIn = pixelId !== "";

  const isConnected = config.accessToken !== null;
  const authDate = config.last_authed_at ? dayjs(config.last_authed_at) : null;

  return (
    <Step1Wrapper>
      <PageCard>
        <StepSection
          title="X (Twitter) Settings"
          setupGuideHref={setupGuideHref}
          description={
            <Step1Explainer>
              To start sending your e-commerce funnel data to X (Twitter),
              please connect your account.
            </Step1Explainer>
          }
        >
          {isConnected ? (
            <>
              <Step1ConnectedWrapper>
                <div>
                  <div>
                    <div>
                      <IconCheckMark size="24px" />
                    </div>
                    <div>Account connected</div>
                  </div>
                  {authDate ? (
                    <div>
                      <div>Last updated:</div>
                      <div>{authDate.format("MMM DD YYYY")}</div>
                    </div>
                  ) : null}
                </div>
                <div>
                  <button onClick={() => setIsModalShown(true)}>
                    Disconnect
                  </button>
                </div>
              </Step1ConnectedWrapper>
              <div ref={inputWrapperRef}>
                <InputWrapper
                  labelText="X (Twitter) Pixel ID"
                  disabled={setupFlow.isLoading}
                  tooltip={{
                    maxWidth: `${theme.gridBase * 30.5}px`,
                    render: () => (
                      <Step1InputWrapperTooltipContent>
                        <p>
                          This is your unique identifier for your X (Twitter)
                          Ads Account.
                        </p>
                        <StyledLinkExternal
                          href="https://example.com"
                          text="How do I find this?"
                        />
                      </Step1InputWrapperTooltipContent>
                    )
                  }}
                >
                  <InputFieldText
                    variant="SMALL"
                    disabled={setupFlow.isLoading}
                    value={pixelId}
                    onChange={event => setPixelId(event.target.value)}
                    spellCheck={false}
                    autoCapitalize="off"
                    placeholder="abc123abc123abc"
                  />
                </InputWrapper>
              </div>
            </>
          ) : (
            <Step1ConnectButton
              variant="SMALL"
              state={setupFlow.isLoading ? "LOADING" : "IDLE"}
              onClick={async () => {
                setupFlow.setIsLoading(true);

                try {
                  const result = await destinationOAuthConnectMutation();
                  window.location.replace(result.redirect_url);
                } catch (error) {
                  setupFlow.setIsLoading(false);
                  toast.errorUnexpected(error);
                }
              }}
            >
              <destination.icon size="16px" color={theme.palette.white} />
              <div>Connect X (Twitter) Account</div>
            </Step1ConnectButton>
          )}
        </StepSection>
        {isConnected ? (
          <Step1AdvancedOptionsToggleButton
            onClick={() => setIsAdvancedShown(!isAdvancedShown)}
          >
            <div>Advanced Options</div>
            <div>
              {isAdvancedShown ? (
                <IconChevronUp size="16px" />
              ) : (
                <IconChevronDown size="16px" />
              )}
            </div>
          </Step1AdvancedOptionsToggleButton>
        ) : null}
        {isAdvancedShown ? (
          <Step1AdvancedOptionsWrapper inputWrapperWidth={inputWrapperWidth}>
            <InputWrapper
              labelText="Conversion Value"
              disabled={setupFlow.isLoading}
            >
              <InputFieldSelect
                variant="SMALL"
                disabled={setupFlow.isLoading}
                value={selectedConversionValueOption}
                setValue={option => setConversionValue(option.value)}
                options={conversionValueOptions}
                placeholder="Select a Conversion Value"
              />
            </InputWrapper>
          </Step1AdvancedOptionsWrapper>
        ) : null}
        {isConnected ? (
          <MarketGroupSettings
            config={config}
            destination={destination}
            isLoading={setupFlow.isLoading}
            isStepCompleted={setupFlow.isStepCompleted}
            saveButtonDisabledTooltipOverride={
              !areXFieldsFilledIn ? "Please fill out all fields" : null
            }
            onSave={async data => {
              await setupFlow.configMutation({
                ...data,
                adAccountId: pixelId,
                dataConfig: { conversionValue }
              });
            }}
          />
        ) : null}
      </PageCard>
      {isConnected ? (
        <ActionWarningModal
          isVisible={isModalShown}
          onClose={() => setIsModalShown(false)}
          isLoading={setupFlow.isLoading}
          subheading={formatTitle(destination.name, config.label)}
          heading="Disconnect X (Twitter) Account"
          text="By disconnecting your account, you're accepting that:"
          checkBoxItems={[
            "Your integration will be automatically set to offline",
            "Your integration will stop receiving conversions data",
            "Your conversion data accuracy will be interrupted"
          ]}
          confirmActionText="Disconnect"
          onConfirmAction={async () => {
            setupFlow.setIsLoading(true);
            await destinationOAuthDisconnectMutation();
            await setupFlow.configMutation({ live: false, completedStep: 0 });
            setupFlow.setIsLoading(false);
            toast.success("X (Twitter) disconnected");
          }}
          cancelActionText="Go Back"
        />
      ) : null}
    </Step1Wrapper>
  );
};

const Step1Wrapper = styled.div`
  > ${PageCard}:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }
`;

const Step1Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

const Step1InputWrapperTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;

  > p {
    margin-bottom: ${props => props.theme.gridBase * 0.75}px;
  }

  > a {
    ${linkStyles};
  }
`;

const Step1ConnectedWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.gridBase * 3}px;

  > div:first-child {
    > div:first-child {
      display: flex;
      gap: ${props => props.theme.gridBase}px;

      > div:first-child {
        color: ${props => props.theme.palette.green};
      }

      > div:last-child {
        ${normalBodyStyles};
        font-weight: 500;
      }
    }

    > div:not(:first-child) {
      ${smallTextStyles};
      display: flex;
      gap: ${props => props.theme.gridBase}px;
      margin-top: ${props => props.theme.gridBase * 1.5}px;

      > div:first-child {
        color: ${props => props.theme.palette.grey2};
      }

      > div:last-child {
        color: ${props => props.theme.palette.grey1};
      }
    }
  }

  > div:last-child {
    > button {
      ${normalBodyStyles};
      ${linkStyles};
    }
  }
`;

const Step1ConnectButton = styled(ButtonPrimary)`
  align-items: center;
  gap: ${props => props.theme.gridBase}px;
`;

const Step1AdvancedOptionsToggleButton = styled.button`
  margin-top: ${props => props.theme.gridBase * 3}px;
  display: flex;
  align-items: center;

  > div:first-child {
    ${normalTextStyles};
    margin-right: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    display: flex;
  }
`;

type Step1AdvancedOptionsWrapperProps = {
  inputWrapperWidth: number;
};

const Step1AdvancedOptionsWrapper = styled.div<Step1AdvancedOptionsWrapperProps>`
  width: ${props => props.inputWrapperWidth}px;
  padding-top: ${props => props.theme.gridBase * 2}px;
`;

/* ========================================================================== */

const Step2: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();
  const { shopifyOAuthScopes } = useSetupFlowContext();

  const [eventState, setEventState] = useState({
    enabledEvents: config.enabledEvents,
    enabledWebEvents: {},
    webhookOverrides: config.webhookOverrides
  });
  const [eventMapping, setEventMapping] = useState(config.eventMapping);

  return (
    <PageCard>
      <StepSection
        title="Events"
        setupGuideHref={setupGuideHref}
        description={
          <Step2Explainer>
            Select what events you want to send to X (Twitter).
          </Step2Explainer>
        }
      />
      <EventDestinationTable
        isLoading={setupFlow.isLoading}
        isStepCompleted={setupFlow.isStepCompleted}
        shopifyOAuthScopes={shopifyOAuthScopes}
        showBreakdownByDefault={true}
        mutualExclusivityLevel="NONE"
        details={{
          eventState,
          setEventState,
          destinationName: destination.name,
          eventMapping,
          setEventMapping
        }}
        recommended={{
          enabledEvents: recommendedEvents,
          enabledWebEvents: {}
        }}
        onSave={options => setupFlow.configMutation(eventState, options)}
      />
    </PageCard>
  );
};

const Step2Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

/* ========================================================================== */

const Step3: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  return (
    <ConsentModeReader
      details={{
        type: "DESTINATION",
        isStepCompleted: setupFlow.isStepCompleted,
        setupGuideHref,
        name: destination.name
      }}
      isLoading={setupFlow.isLoading}
      initial={config.consentMode}
      onSave={data => setupFlow.configMutation(data)}
    />
  );
};

/* ========================================================================== */

const Step4: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  const [productAttributeMapping, setProductAttributeMapping] = useState(
    config.dataConfig.productAttributeMapping
  );

  return (
    <ProductIdentifier
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      options={["variant_id", "product_id", "sku"]}
      productAttributeMapping={productAttributeMapping}
      setProductAttributeMapping={setProductAttributeMapping}
      onSave={async () => {
        await setupFlow.configMutation({
          dataConfig: { productAttributeMapping }
        });
      }}
    />
  );
};

/* ========================================================================== */

const Step5: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  return (
    <FilterTransactions
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      initialFilters={config.orderFilters}
      usesUnifiedCheckout={config.uses_unified_checkout}
      subscriptionTagName={config.subscriptionTagName}
      onSave={(data, options) => setupFlow.configMutation(data, options)}
    />
  );
};

/* ========================================================================== */

const Step6: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  return (
    <Subscriptions
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      details={{
        key: destination.configKey,
        config,
        onSave: (data, options) => setupFlow.configMutation(data, options)
      }}
    />
  );
};
