import { useState } from "react";
import styled, { useTheme } from "styled-components";

import { InputFieldText } from "elevar-design-system/src/inputs/InputFieldText";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import { StyledLinkExternal } from "elevar-design-system/src/links/LinkExternal";
import { linkStyles } from "elevar-design-system/src/links/links";
import { normalBodyStyles } from "elevar-design-system/src/typography/typography";

import { PageCard } from "../../components/PageCard";
import { createSetupFlow } from "../../context/SetupFlowDetails";
import { ConsentModeReader } from "./ConsentModeReader";
import { destinationRktn as destination } from "./data";
import { FilterTransactions } from "./FilterTransactions";
import { MarketGroupSettings } from "./MarketGroupSettings";
import { Overview } from "./Overview";
import { ProductIdentifier } from "./ProductIdentifier";
import { StepSection } from "./StepSection";
import { Subscriptions } from "./Subscriptions";

/* ========================================================================== */

const setupGuideHref =
  "https://docs.getelevar.com/docs/how-to-set-up-rakuten-in-elevars-server-side-destination";

const { SetupFlow, useConfigRequired, useSetupFlowDetails } =
  createSetupFlow().destination(destination);

type DestinationRktnProps = {
  isCompanyAdmin: boolean;
};

export const DestinationRktn: React.FC<DestinationRktnProps> = ({
  isCompanyAdmin
}) => {
  return (
    <SetupFlow
      isCompanyAdmin={isCompanyAdmin}
      steps={[
        { details: { type: "SETTINGS" } },
        { details: { type: "CONSENT_MODE" } },
        { details: { type: "PRODUCT_IDENTIFIER" } },
        { details: { type: "FILTER_TRANSACTIONS" } },
        { details: { type: "SUBSCRIPTIONS" } }
      ]}
    >
      <StepContent />
    </SetupFlow>
  );
};

/* ========================================================================== */

const StepContent: React.FC = () => {
  const { currentStep } = useSetupFlowDetails();

  switch (currentStep) {
    case 0:
      return <Step0 />;
    case 1:
      return <Step1 />;
    case 2:
      return <Step2 />;
    case 3:
      return <Step3 />;
    case 4:
      return <Step4 />;
    case 5:
      return <Step5 />;
  }
};

/* ========================================================================== */

const Step0: React.FC = () => {
  const setupFlow = useSetupFlowDetails();

  return (
    <Overview
      destination={destination}
      config={setupFlow.config}
      configMutation={setupFlow.configMutation}
      configLabel={setupFlow.configLabel}
      completedStep={setupFlow.completedStep}
      isStepCompleted={setupFlow.isStepCompleted}
      setCurrentStep={setupFlow.setCurrentStep}
      getConfigSummaryItems={config => [
        {
          step: 1,
          type: "CUSTOM",
          render: () => (
            <>
              The Rakuten Merchant ID used is <span>{config.adAccountId}</span>
            </>
          )
        },
        {
          step: 2,
          type: "CONSENT_MODE",
          inEnabled: config.consentMode.enabled
        },
        {
          step: 3,
          type: "PRODUCT_IDENTIFIER",
          value: config.dataConfig.productAttributeMapping
        },
        {
          step: 4,
          type: "FILTER_TRANSACTIONS",
          filters: config.orderFilters
        },
        {
          step: 5,
          type: "SUBSCRIPTIONS",
          filters: config.orderFilters,
          tagName: config.subscriptionTagName
        }
      ]}
      description={
        <Step0Explainer>
          Send meaningful data to {destination.name} so that you can focus on
          making strategic business decisions rather than worrying about your
          data.
        </Step0Explainer>
      }
    />
  );
};

const Step0Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

/* ========================================================================== */

const Step1: React.FC = () => {
  const theme = useTheme();
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  const [adAccountId, setAdAccountId] = useState(config.adAccountId);

  return (
    <Step1Wrapper>
      <PageCard>
        <StepSection
          title="Rakuten Settings"
          setupGuideHref={setupGuideHref}
          description={
            <Step1Explainer>
              Please provide the following details from your Rakuten account:
            </Step1Explainer>
          }
        >
          <InputWrapper
            labelText="Merchant ID"
            disabled={setupFlow.isLoading}
            tooltip={{
              maxWidth: `${theme.gridBase * 30}px`,
              render: () => (
                <Step1InputWrapperTooltipContent>
                  <p>
                    This is your unique account ID (not the Sitewide Tag ID).
                  </p>
                  <StyledLinkExternal
                    href="https://docs.getelevar.com/docs/how-to-find-rakuten-merchant-id-sitewide-tag"
                    text="Where do I find this?"
                  />
                </Step1InputWrapperTooltipContent>
              )
            }}
          >
            <InputFieldText
              variant="SMALL"
              disabled={setupFlow.isLoading}
              value={adAccountId}
              onChange={event => setAdAccountId(event.target.value)}
              spellCheck={false}
              autoCapitalize="off"
              placeholder="123456789"
            />
          </InputWrapper>
        </StepSection>
        <MarketGroupSettings
          config={config}
          destination={destination}
          isLoading={setupFlow.isLoading}
          isStepCompleted={setupFlow.isStepCompleted}
          saveButtonDisabledTooltipOverride={
            adAccountId === "" ? "Please fill out all fields" : null
          }
          onSave={async data => {
            await setupFlow.configMutation({ ...data, adAccountId });
          }}
        />
      </PageCard>
    </Step1Wrapper>
  );
};

const Step1Wrapper = styled.div`
  > ${PageCard}:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }
`;

const Step1Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

const Step1InputWrapperTooltipContent = styled.div`
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;

  > p {
    ${normalBodyStyles};
    color: ${props => props.theme.palette.grey3};
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }

  > a {
    ${linkStyles};
  }
`;

/* ========================================================================== */

const Step2: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  return (
    <ConsentModeReader
      details={{
        type: "DESTINATION",
        isStepCompleted: setupFlow.isStepCompleted,
        setupGuideHref,
        name: destination.name
      }}
      isLoading={setupFlow.isLoading}
      initial={config.consentMode}
      onSave={data => setupFlow.configMutation(data)}
    />
  );
};

/* ========================================================================== */

const Step3: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  const [productAttributeMapping, setProductAttributeMapping] = useState(
    config.dataConfig.productAttributeMapping
  );

  return (
    <ProductIdentifier
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      hasRecommendedOption={false}
      options={["product_id", "sku", "variant_id"]}
      productAttributeMapping={productAttributeMapping}
      setProductAttributeMapping={setProductAttributeMapping}
      onSave={async () => {
        await setupFlow.configMutation({
          dataConfig: { productAttributeMapping }
        });
      }}
    />
  );
};

/* ========================================================================== */

const Step4: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  return (
    <FilterTransactions
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      initialFilters={config.orderFilters}
      usesUnifiedCheckout={config.uses_unified_checkout}
      subscriptionTagName={config.subscriptionTagName}
      onSave={(data, options) => setupFlow.configMutation(data, options)}
    />
  );
};

/* ========================================================================== */

const Step5: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  return (
    <Subscriptions
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      details={{
        key: destination.configKey,
        config,
        onSave: (data, options) => setupFlow.configMutation(data, options)
      }}
    />
  );
};
