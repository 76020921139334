import { produce } from "immer";
import { pick, uniq } from "lodash-es";
import { useState } from "react";
import useMeasure from "react-use-measure";
import styled, { css, useTheme } from "styled-components";

import {
  reChargeChannel,
  unifiedCheckoutChannel,
  userFacingPredefinedChannels
} from "elevar-common-ts/src/channels";

import {
  iconButtonStyles,
  iconTextButtonStyles
} from "elevar-design-system/src/buttons/buttonStyles";
import {
  ButtonPrimary,
  ButtonSecondary
} from "elevar-design-system/src/buttons/ButtonVariants";
import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import {
  IconChevronDown,
  IconChevronUp,
  IconCircledPlus,
  IconCog,
  IconCross
} from "elevar-design-system/src/icons";
import { InputFieldCombobox } from "elevar-design-system/src/inputs/InputFieldCombobox";
import {
  InputFieldSelect,
  type Option
} from "elevar-design-system/src/inputs/InputFieldSelect";
import { InputFieldText } from "elevar-design-system/src/inputs/InputFieldText";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import { LabeledCheckBoxMulti } from "elevar-design-system/src/labeledCheckBoxes/LabeledCheckBoxMulti";
import { LabeledCheckBoxSingle } from "elevar-design-system/src/labeledCheckBoxes/LabeledCheckBoxSingle";
import {
  LinkExternal,
  StyledLinkExternal
} from "elevar-design-system/src/links/LinkExternal";
import { linkStyles } from "elevar-design-system/src/links/links";
import { Spinner } from "elevar-design-system/src/Spinner";
import {
  heading3Styles,
  normalBodyStyles,
  normalTextStyles
} from "elevar-design-system/src/typography/typography";

import { type ShopifyOAuthScopes } from "../../api/handlers/shopify";
import {
  type ContainerInfo,
  useContainerInfoQuery,
  useMyTrackingVersionMutation
} from "../../api/handlers/website";
import { Modal } from "../../components/Modal";
import { PageCard } from "../../components/PageCard";
import { useMyTrackingDetails } from "../../context/MyTrackingDetails";
import { createSetupFlow } from "../../context/SetupFlowDetails";
import { ConsentModeReader } from "./ConsentModeReader";
import { destinationGa4 as destination } from "./data";
import {
  EventDestinationTable,
  getRecommendedEventState
} from "./EventDestinationTable";
import { FilterTransactions } from "./FilterTransactions";
import { InputFieldChannelCode } from "./InputFieldChannelCode";
import { MarketGroupSettings } from "./MarketGroupSettings";
import { Overview } from "./Overview";
import { ProductIdentifier } from "./ProductIdentifier";
import { conversionValueOptions } from "./shared";
import { StepSection } from "./StepSection";
import { Subscriptions } from "./Subscriptions";
import { TransactionIdentifier } from "./TransactionIdentifier";
import { WebContainerSetup } from "./WebContainerSetup";

/* ========================================================================== */

type DestinationGa4Props = {
  isCompanyAdmin: boolean;
  shopifyOAuthScopes: ShopifyOAuthScopes;
};

export const DestinationGa4: React.FC<DestinationGa4Props> = ({
  isCompanyAdmin,
  shopifyOAuthScopes
}) => {
  const containerInfo = useContainerInfoQuery({ destination });

  if (containerInfo.error !== null) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  if (containerInfo.data === undefined) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  return (
    <DestinationGa4Inner
      isCompanyAdmin={isCompanyAdmin}
      shopifyOAuthScopes={shopifyOAuthScopes}
      containerInfo={containerInfo.data}
    />
  );
};

const CenteredWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* ========================================================================== */

const setupGuideHref =
  "https://docs.getelevar.com/docs/how-to-set-up-ga4-in-elevar-server-side-destination";

type SetupFlowContext = {
  shopifyOAuthScopes: ShopifyOAuthScopes;
  containerInfo: ContainerInfo;
};

const {
  SetupFlow,
  useConfigRequired,
  useSetupFlowDetails,
  useSetupFlowContext
} = createSetupFlow<SetupFlowContext>().destination(destination);

type DestinationGa4InnerProps = {
  isCompanyAdmin: boolean;
  shopifyOAuthScopes: ShopifyOAuthScopes;
  containerInfo: ContainerInfo;
};

const DestinationGa4Inner: React.FC<DestinationGa4InnerProps> = ({
  isCompanyAdmin,
  shopifyOAuthScopes,
  containerInfo
}) => {
  return (
    <SetupFlow
      isCompanyAdmin={isCompanyAdmin}
      steps={[
        { details: { type: "SETTINGS" } },
        { details: { type: "EVENTS" } },
        { details: { type: "CONSENT_MODE" } },
        { details: { type: "TRANSACTION_IDENTIFIER" } },
        { details: { type: "PRODUCT_IDENTIFIER" } },
        { details: { type: "FILTER_TRANSACTIONS" } },
        { details: { type: "SUBSCRIPTIONS" } },
        { details: { type: "WEB_CONTAINER_SETUP" } }
      ]}
      context={{ shopifyOAuthScopes, containerInfo }}
    >
      <StepContent />
    </SetupFlow>
  );
};

/* ========================================================================== */

const StepContent: React.FC = () => {
  const { currentStep } = useSetupFlowDetails();

  switch (currentStep) {
    case 0:
      return <Step0 />;
    case 1:
      return <Step1 />;
    case 2:
      return <Step2 />;
    case 3:
      return <Step3 />;
    case 4:
      return <Step4 />;
    case 5:
      return <Step5 />;
    case 6:
      return <Step6 />;
    case 7:
      return <Step7 />;
    case 8:
      return <Step8 />;
  }
};

/* ========================================================================== */

type Events = ReturnType<typeof useConfigRequired>["enabledEvents"];
type WebEvents = ReturnType<typeof useConfigRequired>["enabledWebEvents"];

/**
 * For GA4, we only want to expose a subset of the server events we support
 * to customers on the FE, as we have events that do not currently work as
 * we want them to (due to GA4 limitations). As of now this is unique to
 * GA4, so an ad-hoc approach is taken instead of components handling this.
 */
const getExposedEvents = (events: Events) => {
  return pick(events, [
    "addPaymentInfo",
    "addShippingInfo",
    "beginCheckout",
    "purchase",
    "refund",
    "subscriptionPurchase"
  ]);
};

const recommendedEvents: ReturnType<typeof getExposedEvents> = {
  addPaymentInfo: false,
  addShippingInfo: false,
  beginCheckout: false,
  purchase: true,
  refund: false,
  subscriptionPurchase: false
};

const recommendedWebEvents: WebEvents = {
  addPaymentInfo: true,
  addShippingInfo: true,
  addToCart: true,
  beginCheckout: true,
  login: true,
  pageView: true,
  purchase: false,
  removeFromCart: true,
  selectItem: true,
  signUp: true,
  subscribe: true,
  viewCart: true,
  viewItem: true,
  viewItemList: true,
  viewSearchResults: true
};

const Step0: React.FC = () => {
  const setupFlow = useSetupFlowDetails();

  return (
    <Overview
      destination={destination}
      config={setupFlow.config}
      configMutation={setupFlow.configMutation}
      configLabel={setupFlow.configLabel}
      completedStep={setupFlow.completedStep}
      isStepCompleted={setupFlow.isStepCompleted}
      setCurrentStep={setupFlow.setCurrentStep}
      getConfigSummaryItems={config => [
        {
          step: 1,
          type: "CUSTOM",
          render: () => (
            <>
              The GA4 Measurement ID used is <span>{config.measurementId}</span>
            </>
          )
        },
        {
          step: 2,
          type: "EVENTS",
          selectedEvents: {
            enabledEvents: getExposedEvents(config.enabledEvents),
            enabledWebEvents: config.enabledWebEvents,
            webhookOverrides: config.webhookOverrides
          },
          recommendedEvents: getRecommendedEventState({
            destination,
            // @ts-expect-error - GA4 only difference in shape
            enabledEvents: recommendedEvents,
            enabledWebEvents: recommendedWebEvents,
            webhookOverrides: config.webhookOverrides
          })
        },
        {
          step: 3,
          type: "CONSENT_MODE",
          inEnabled: config.consentMode.enabled
        },
        {
          step: 4,
          type: "TRANSACTION_IDENTIFIER",
          value: config.dataConfig.orderAttributeId
        },
        {
          step: 5,
          type: "PRODUCT_IDENTIFIER",
          value: config.dataConfig.productAttributeMapping
        },
        {
          step: 6,
          type: "FILTER_TRANSACTIONS",
          filters: config.orderFilters
        },
        {
          step: 7,
          type: "SUBSCRIPTIONS",
          filters: config.orderFilters,
          tagName: config.subscriptionTagName,
          tagSourceOverrides: config.dataConfig.tagSourceOverrides
        }
      ]}
      description={
        <Step0Explainer>
          Send meaningful data to {destination.name} so that you can focus on
          making strategic business decisions rather than worrying about your
          data.
        </Step0Explainer>
      }
    />
  );
};

const Step0Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

/* ========================================================================== */

type CustomDimension = ReturnType<
  typeof useConfigRequired
>["customDimensions"][number];

type CustomDimensionEditable = {
  parameter: CustomDimension["parameter"];
  value: CustomDimension["value"] | null;
  scope: CustomDimension["scope"] | null;
};

const ga4CustomDimensionOptions: Array<Option<CustomDimension["value"]>> = [
  { name: "Billing City", value: "Billing City" },
  { name: "Billing Country", value: "Billing Country" },
  { name: "Billing Province", value: "Billing Province" },
  { name: "Billing Zipcode", value: "Billing Zipcode" },
  { name: "Order Name", value: "Order Name" },
  { name: "Payment Method", value: "Payment Method" },
  { name: "Sales Channel", value: "Sales Channel" },
  { name: "Shipping City", value: "Shipping City" },
  { name: "Shipping Country", value: "Shipping Country" },
  { name: "Shipping Method", value: "Shipping Method" },
  { name: "Shipping Province", value: "Shipping Province" },
  { name: "Shipping Zipcode", value: "Shipping Zipcode" },
  { name: "Order Tags", value: "Order Tags" },
  { name: "Customer Tags", value: "Customer Tags" }
];

const customDimensionScopeOptions: Array<Option<CustomDimension["scope"]>> = [
  { name: "Event", value: "event" },
  { name: "User", value: "user" }
];

const Step1: React.FC = () => {
  const theme = useTheme();
  const [inputWrapperRef, { width: inputWrapperWidth }] = useMeasure();
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  const {
    channelSourceOverrides: initialChannelSourceOverrides,
    gatewaySourceOverrides: initialGatewaySourceOverrides,
    tagSourceOverrides: initialTagSourceOverrides
  } = config.dataConfig;

  const initialSubscriptionTagName = config.subscriptionTagName;

  const subscriptionChannelSourceOverrides = config.uses_unified_checkout
    ? initialChannelSourceOverrides.filter(
        override =>
          override.channelName === unifiedCheckoutChannel.code ||
          override.channelName === reChargeChannel.code
      )
    : [];
  const subscriptionTagSourceOverride = initialTagSourceOverrides.find(
    override => override.tagName === initialSubscriptionTagName
  );

  const [measurementId, setMeasurementId] = useState(config.measurementId);
  const [isAdvancedShown, setIsAdvancedShown] = useState(false);
  const [preferGaCookie, setPreferGaCookie] = useState(
    config.dataConfig.preferGaCookie
  );
  const [sendUserData, setSendUserData] = useState(
    config.dataConfig.sendUserData
  );
  const [isSendUserDataModalVisible, setIsSendUserDataModalVisible] =
    useState(false);
  const [isSendUserDataModalConfirmed, setIsSendUserDataModalConfirmed] =
    useState(false);
  const [conversionValue, setConversionValue] = useState(
    config.dataConfig.conversionValue
  );
  const [customDimensions, setCustomDimensions] = useState<
    Array<CustomDimensionEditable>
  >(config.customDimensions);
  const [channelSourceOverrides, setChannelSourceOverrides] = useState(
    initialChannelSourceOverrides
  );
  const [gatewaySourceOverrides, setGatewaySourceOverrides] = useState(
    initialGatewaySourceOverrides
  );
  const [tagSourceOverrides, setTagSourceOverrides] = useState(
    initialTagSourceOverrides
  );

  const selectedConversionValueOption =
    conversionValueOptions.find(o => o.value === conversionValue) ?? null;

  const areGa4FieldsFilledIn = measurementId !== "";
  const isMeasurementIdValid =
    measurementId.startsWith("G-") && measurementId.length > 2;

  const areCustomDimensionsValid = customDimensions.every(
    d => d.parameter !== "" && d.value !== null && d.scope !== null
  );
  const areCustomDimensionsUnique =
    uniq(customDimensions.map(d => `${d.value ?? ""}${d.scope ?? ""}`))
      .length === customDimensions.length;

  const areChannelOverridesValid = channelSourceOverrides.every(
    override => override.channelName !== ""
  );
  const areChannelOverridesUnique =
    uniq(channelSourceOverrides.map(override => override.channelName))
      .length === channelSourceOverrides.length;

  const areGatewayOverridesValid = gatewaySourceOverrides.every(
    override => override.gatewayName !== ""
  );
  const areGatewayOverridesUnique =
    uniq(gatewaySourceOverrides.map(override => override.gatewayName))
      .length === gatewaySourceOverrides.length;

  const areTagOverridesValid = tagSourceOverrides.every(
    override => override.tagName !== ""
  );
  const areTagOverridesUnique =
    uniq(tagSourceOverrides.map(override => override.tagName)).length ===
    tagSourceOverrides.length;

  return (
    <>
      <Step1Wrapper>
        <PageCard>
          <StepSection
            title="GA4 Settings"
            setupGuideHref={setupGuideHref}
            description={
              <Step1Explainer>
                In order to send your transactions data to this destination, we
                need the following information from your Google account.
              </Step1Explainer>
            }
          >
            <div ref={inputWrapperRef}>
              <InputWrapper
                labelText="GA4 Measurement ID"
                disabled={setupFlow.isLoading}
                tooltip={{
                  maxWidth: `${theme.gridBase * 34}px`,
                  render: () => (
                    <Step1InputWrapperTooltipContent>
                      This is the Google Tag ID for your Google Analytics 4
                      property. Please see{" "}
                      <LinkExternal href="https://support.google.com/analytics/answer/9539598?sjid=4123004851774404082-AP#find-G-ID">
                        here
                      </LinkExternal>{" "}
                      for how to find it in GA4.
                    </Step1InputWrapperTooltipContent>
                  )
                }}
              >
                <InputFieldText
                  variant="SMALL"
                  disabled={setupFlow.isLoading}
                  value={measurementId}
                  onChange={event => setMeasurementId(event.target.value)}
                  placeholder="G-12345"
                  spellCheck={false}
                  autoCapitalize="off"
                />
              </InputWrapper>
            </div>
          </StepSection>
          <Step1AdvancedOptionsToggleButton
            onClick={() => setIsAdvancedShown(!isAdvancedShown)}
          >
            <div>Advanced Options</div>
            <div>
              {isAdvancedShown ? (
                <IconChevronUp size="16px" />
              ) : (
                <IconChevronDown size="16px" />
              )}
            </div>
          </Step1AdvancedOptionsToggleButton>
          {isAdvancedShown ? (
            <Step1AdvancedOptionsWrapper inputWrapperWidth={inputWrapperWidth}>
              <div>
                <LabeledCheckBoxMulti
                  variant="NORMAL"
                  text="Prefer GA Cookie"
                  isDisabled={setupFlow.isLoading}
                  isChecked={preferGaCookie}
                  setIsChecked={isChecked => setPreferGaCookie(isChecked)}
                  tooltip={{
                    maxWidth: `${theme.gridBase * 50}px`,
                    render: () => (
                      <Step1AdvancedOptionsCheckboxTooltipContent>
                        If you are using analytics.js on your website (i.e. for
                        custom GA events), we recommend keeping this option
                        checked. If you aren't running analytics.js, you can
                        instead use Elevars user_id by unchecking this option.
                      </Step1AdvancedOptionsCheckboxTooltipContent>
                    )
                  }}
                />
                <LabeledCheckBoxMulti
                  variant="NORMAL"
                  text="Activate user-provided data collection"
                  isDisabled={setupFlow.isLoading}
                  isChecked={sendUserData}
                  setIsChecked={isChecked => {
                    if (isChecked) {
                      setIsSendUserDataModalVisible(true);
                    } else {
                      setSendUserData(false);
                      setIsSendUserDataModalConfirmed(false);
                    }
                  }}
                  tooltip={{
                    maxWidth: `${theme.gridBase * 33}px`,
                    render: () => (
                      <Step1AdvancedOptionsCheckboxTooltipContent>
                        <p>
                          Enabling this sends user-provided, first-party data to
                          GA4.
                        </p>
                        <StyledLinkExternal
                          href="https://support.google.com/analytics/answer/14077171"
                          text="How does this work?"
                        />
                      </Step1AdvancedOptionsCheckboxTooltipContent>
                    )
                  }}
                />
              </div>
              <div>
                <InputWrapper
                  labelText="Conversion Value"
                  disabled={setupFlow.isLoading}
                >
                  <InputFieldSelect
                    variant="SMALL"
                    disabled={setupFlow.isLoading}
                    value={selectedConversionValueOption}
                    setValue={option => setConversionValue(option.value)}
                    options={conversionValueOptions}
                    placeholder="Select a Conversion Value"
                  />
                </InputWrapper>
              </div>
              <div>
                <div>
                  Here you can further customize how to send data to GA4.
                </div>
                <div>
                  <Step1AdvancedOptionWrapper
                    showBottomSeparator={
                      customDimensions.length > 0 ||
                      channelSourceOverrides.length > 0
                    }
                  >
                    {customDimensions.length === 0 ? (
                      <Step1IconTextButton
                        disabled={setupFlow.isLoading}
                        onClick={() => {
                          setCustomDimensions([
                            { parameter: "", value: null, scope: null }
                          ]);
                        }}
                      >
                        <div>
                          <IconCog size="24px" />
                        </div>
                        <div>Specify Custom Dimensions</div>
                      </Step1IconTextButton>
                    ) : (
                      <Step1CustomItemsExistWrapper>
                        <div>
                          <div>Specify Custom Dimensions</div>
                          <div>
                            Are you using custom dimensions in GA4? Provide them
                            here for more accurate tracking. Be sure to{" "}
                            <LinkExternal href="https://docs.getelevar.com/docs/how-to-send-custom-dimensions-via-ga4-server-side">
                              set up your Custom Dimensions in GA4
                            </LinkExternal>{" "}
                            as well.
                          </div>
                        </div>
                        <div>
                          <div>
                            {customDimensions.map((dimension, index) => (
                              <Step1CustomItemWrapper key={index} columns={3}>
                                <div>
                                  <InputWrapper
                                    labelText="Data to Send"
                                    disabled={setupFlow.isLoading}
                                  >
                                    <InputFieldCombobox
                                      variant="SMALL"
                                      disabled={setupFlow.isLoading}
                                      value={
                                        ga4CustomDimensionOptions.find(
                                          o => o.value === dimension.value
                                        ) ?? null
                                      }
                                      setValue={({ value }) => {
                                        setCustomDimensions(
                                          produce(customDimensions, draft => {
                                            draft[index]!.value = value;
                                          })
                                        );
                                      }}
                                      options={ga4CustomDimensionOptions.toSorted(
                                        (a, b) => a.name.localeCompare(b.name)
                                      )}
                                      placeholder="Select"
                                    />
                                  </InputWrapper>
                                  <InputWrapper
                                    labelText="Parameter Name"
                                    tooltip={{
                                      maxWidth: `${theme.gridBase * 36}px`,
                                      render: () => (
                                        <Step1InputWrapperTooltipContent>
                                          <p>
                                            Type a name for the parameter you'd
                                            like to send. Use all lower case
                                            letters and underscores instead of
                                            spaces. For example,
                                            shipping_method, sales_channel,
                                            billing_zipcode, etc.
                                          </p>
                                          <StyledLinkExternal
                                            href="https://docs.getelevar.com/docs/how-to-send-custom-dimensions-via-ga4-server-side"
                                            text="Learn More"
                                          />
                                        </Step1InputWrapperTooltipContent>
                                      )
                                    }}
                                    disabled={setupFlow.isLoading}
                                  >
                                    <InputFieldText
                                      variant="SMALL"
                                      disabled={setupFlow.isLoading}
                                      value={dimension.parameter}
                                      onChange={event => {
                                        const value = event.target.value;
                                        setCustomDimensions(
                                          produce(customDimensions, draft => {
                                            draft[index]!.parameter = value;
                                          })
                                        );
                                      }}
                                      placeholder="e.g. sales_channel"
                                      spellCheck={false}
                                      autoCapitalize="off"
                                    />
                                  </InputWrapper>
                                  <InputWrapper
                                    labelText="Scope"
                                    tooltip={{
                                      maxWidth: `${theme.gridBase * 36}px`,
                                      render: () => (
                                        <Step1InputWrapperTooltipContent>
                                          <p>
                                            This should match the 'scope' value
                                            you have in GA4.Event scopes apply
                                            data to specific events while User
                                            scopes apply data to specific users.
                                          </p>
                                          <StyledLinkExternal
                                            href="https://docs.getelevar.com/docs/how-to-send-custom-dimensions-via-ga4-server-side"
                                            text="Learn More"
                                          />
                                        </Step1InputWrapperTooltipContent>
                                      )
                                    }}
                                    disabled={setupFlow.isLoading}
                                  >
                                    <InputFieldSelect
                                      variant="SMALL"
                                      disabled={setupFlow.isLoading}
                                      value={
                                        customDimensionScopeOptions.find(
                                          o => o.value === dimension.scope
                                        ) ?? null
                                      }
                                      setValue={({ value }) => {
                                        setCustomDimensions(
                                          produce(customDimensions, draft => {
                                            draft[index]!.scope = value;
                                          })
                                        );
                                      }}
                                      options={customDimensionScopeOptions}
                                      placeholder="Select"
                                    />
                                  </InputWrapper>
                                </div>
                                <div>
                                  <Step1OverrideItemRemoveButton
                                    aria-label={`Remove item ${index + 1}`}
                                    disabled={setupFlow.isLoading}
                                    onClick={() => {
                                      setCustomDimensions(
                                        produce(customDimensions, draft => {
                                          draft.splice(index, 1);
                                        })
                                      );
                                    }}
                                  >
                                    <IconCross size="16px" />
                                  </Step1OverrideItemRemoveButton>
                                </div>
                              </Step1CustomItemWrapper>
                            ))}
                          </div>
                          <Step1IconTextButton
                            disabled={setupFlow.isLoading}
                            onClick={() => {
                              setCustomDimensions([
                                ...customDimensions,
                                { parameter: "", value: null, scope: null }
                              ]);
                            }}
                          >
                            <div>
                              <IconCircledPlus size="24px" />
                            </div>
                            <div>Add Another</div>
                          </Step1IconTextButton>
                        </div>
                      </Step1CustomItemsExistWrapper>
                    )}
                  </Step1AdvancedOptionWrapper>
                  <Step1AdvancedOptionWrapper
                    showBottomSeparator={
                      channelSourceOverrides.length > 0 ||
                      gatewaySourceOverrides.length > 0
                    }
                  >
                    {channelSourceOverrides.length === 0 ? (
                      <Step1IconTextButton
                        disabled={setupFlow.isLoading}
                        onClick={() => {
                          setChannelSourceOverrides([
                            {
                              channelName: "",
                              utm_campaign: "",
                              utm_medium: "",
                              utm_source: ""
                            }
                          ]);
                        }}
                      >
                        <div>
                          <IconCog size="24px" />
                        </div>
                        <div>Customize by Channel</div>
                      </Step1IconTextButton>
                    ) : (
                      <Step1CustomItemsExistWrapper>
                        <div>
                          <div>Customize by Channel</div>
                          <div>
                            Note, a channel code is required. Please follow the
                            Setup Guide for details on where to find this.
                          </div>
                        </div>
                        <div>
                          <div>
                            {channelSourceOverrides.map((override, index) => (
                              <Step1CustomItemWrapper key={index} columns={4}>
                                <div>
                                  <InputWrapper
                                    labelText="Channel Code"
                                    tooltip={{
                                      text: 'Examples: "Point of Sale", "Wholesale", "Global-E"'
                                    }}
                                    disabled={
                                      setupFlow.isLoading ||
                                      subscriptionChannelSourceOverrides.includes(
                                        override
                                      )
                                    }
                                  >
                                    <InputFieldChannelCode
                                      value={override.channelName}
                                      setValue={value => {
                                        setChannelSourceOverrides(
                                          produce(
                                            channelSourceOverrides,
                                            draft => {
                                              draft[index]!.channelName = value;
                                            }
                                          )
                                        );
                                      }}
                                      options={userFacingPredefinedChannels.map(
                                        c => ({ name: c.name, value: c.code })
                                      )}
                                      placeholder="Code"
                                      disabled={
                                        setupFlow.isLoading ||
                                        subscriptionChannelSourceOverrides.includes(
                                          override
                                        )
                                      }
                                    />
                                  </InputWrapper>
                                  <InputWrapper
                                    labelText="Campaign Source"
                                    tooltip={{ text: 'Example: "google"' }}
                                    disabled={
                                      setupFlow.isLoading ||
                                      subscriptionChannelSourceOverrides.includes(
                                        override
                                      )
                                    }
                                  >
                                    <InputFieldText
                                      variant="SMALL"
                                      disabled={
                                        setupFlow.isLoading ||
                                        subscriptionChannelSourceOverrides.includes(
                                          override
                                        )
                                      }
                                      value={override.utm_source}
                                      onChange={event => {
                                        const value = event.target.value;
                                        setChannelSourceOverrides(
                                          produce(
                                            channelSourceOverrides,
                                            draft => {
                                              draft[index]!.utm_source = value;
                                            }
                                          )
                                        );
                                      }}
                                      placeholder="Source"
                                      spellCheck={false}
                                      autoCapitalize="off"
                                    />
                                  </InputWrapper>
                                  <InputWrapper
                                    labelText="Campaign Medium"
                                    tooltip={{ text: 'Example: "email"' }}
                                    disabled={
                                      setupFlow.isLoading ||
                                      subscriptionChannelSourceOverrides.includes(
                                        override
                                      )
                                    }
                                  >
                                    <InputFieldText
                                      variant="SMALL"
                                      disabled={
                                        setupFlow.isLoading ||
                                        subscriptionChannelSourceOverrides.includes(
                                          override
                                        )
                                      }
                                      value={override.utm_medium}
                                      onChange={event => {
                                        const value = event.target.value;
                                        setChannelSourceOverrides(
                                          produce(
                                            channelSourceOverrides,
                                            draft => {
                                              draft[index]!.utm_medium = value;
                                            }
                                          )
                                        );
                                      }}
                                      placeholder="Medium"
                                      spellCheck={false}
                                      autoCapitalize="off"
                                    />
                                  </InputWrapper>
                                  <InputWrapper
                                    labelText="Campaign Name"
                                    tooltip={{
                                      text: 'Example: "summer-sale"'
                                    }}
                                    disabled={
                                      setupFlow.isLoading ||
                                      subscriptionChannelSourceOverrides.includes(
                                        override
                                      )
                                    }
                                  >
                                    <InputFieldText
                                      variant="SMALL"
                                      disabled={
                                        setupFlow.isLoading ||
                                        subscriptionChannelSourceOverrides.includes(
                                          override
                                        )
                                      }
                                      value={override.utm_campaign}
                                      onChange={event => {
                                        const value = event.target.value;
                                        setChannelSourceOverrides(
                                          produce(
                                            channelSourceOverrides,
                                            draft => {
                                              draft[index]!.utm_campaign =
                                                value;
                                            }
                                          )
                                        );
                                      }}
                                      placeholder="Name"
                                      spellCheck={false}
                                      autoCapitalize="off"
                                    />
                                  </InputWrapper>
                                </div>
                                <div>
                                  <Step1OverrideItemRemoveButton
                                    aria-label={`Remove: ${override.channelName}`}
                                    disabled={
                                      setupFlow.isLoading ||
                                      subscriptionChannelSourceOverrides.includes(
                                        override
                                      )
                                    }
                                    onClick={() => {
                                      setChannelSourceOverrides(
                                        produce(
                                          channelSourceOverrides,
                                          draft => {
                                            draft.splice(index, 1);
                                          }
                                        )
                                      );
                                    }}
                                  >
                                    <IconCross size="16px" />
                                  </Step1OverrideItemRemoveButton>
                                </div>
                              </Step1CustomItemWrapper>
                            ))}
                          </div>
                          <Step1IconTextButton
                            disabled={setupFlow.isLoading}
                            onClick={() => {
                              setChannelSourceOverrides([
                                ...channelSourceOverrides,
                                {
                                  channelName: "",
                                  utm_campaign: "",
                                  utm_medium: "",
                                  utm_source: ""
                                }
                              ]);
                            }}
                          >
                            <div>
                              <IconCircledPlus size="24px" />
                            </div>
                            <div>Add Another</div>
                          </Step1IconTextButton>
                        </div>
                      </Step1CustomItemsExistWrapper>
                    )}
                  </Step1AdvancedOptionWrapper>
                  <Step1AdvancedOptionWrapper
                    showBottomSeparator={
                      gatewaySourceOverrides.length > 0 ||
                      tagSourceOverrides.length > 0
                    }
                  >
                    {gatewaySourceOverrides.length === 0 ? (
                      <Step1IconTextButton
                        disabled={setupFlow.isLoading}
                        onClick={() => {
                          setGatewaySourceOverrides([
                            {
                              gatewayName: "",
                              utm_campaign: "",
                              utm_medium: "",
                              utm_source: ""
                            }
                          ]);
                        }}
                      >
                        <div>
                          <IconCog size="24px" />
                        </div>
                        <div>Customize by Order Gateway</div>
                      </Step1IconTextButton>
                    ) : (
                      <Step1CustomItemsExistWrapper>
                        <div>
                          <div>Customize by Order Gateway</div>
                          <div>
                            Please follow the Setup Guide for details on where
                            to find this.
                          </div>
                        </div>
                        <div>
                          <div>
                            {gatewaySourceOverrides.map((override, index) => (
                              <Step1CustomItemWrapper key={index} columns={4}>
                                <div>
                                  <InputWrapper
                                    labelText="Order Gateway"
                                    tooltip={{
                                      text: 'Examples: "Money Order", "Paypal"'
                                    }}
                                    disabled={setupFlow.isLoading}
                                  >
                                    <InputFieldText
                                      variant="SMALL"
                                      disabled={setupFlow.isLoading}
                                      value={override.gatewayName}
                                      onChange={event => {
                                        const value = event.target.value;
                                        setGatewaySourceOverrides(
                                          produce(
                                            gatewaySourceOverrides,
                                            draft => {
                                              draft[index]!.gatewayName = value;
                                            }
                                          )
                                        );
                                      }}
                                      placeholder="Gateway"
                                      spellCheck={false}
                                      autoCapitalize="off"
                                    />
                                  </InputWrapper>
                                  <InputWrapper
                                    labelText="Campaign Source"
                                    tooltip={{ text: 'Example: "google"' }}
                                    disabled={setupFlow.isLoading}
                                  >
                                    <InputFieldText
                                      variant="SMALL"
                                      disabled={setupFlow.isLoading}
                                      value={override.utm_source}
                                      onChange={event => {
                                        const value = event.target.value;
                                        setGatewaySourceOverrides(
                                          produce(
                                            gatewaySourceOverrides,
                                            draft => {
                                              draft[index]!.utm_source = value;
                                            }
                                          )
                                        );
                                      }}
                                      placeholder="Source"
                                      spellCheck={false}
                                      autoCapitalize="off"
                                    />
                                  </InputWrapper>
                                  <InputWrapper
                                    labelText="Campaign Medium"
                                    tooltip={{ text: 'Example: "email"' }}
                                    disabled={setupFlow.isLoading}
                                  >
                                    <InputFieldText
                                      variant="SMALL"
                                      disabled={setupFlow.isLoading}
                                      value={override.utm_medium}
                                      onChange={event => {
                                        const value = event.target.value;
                                        setGatewaySourceOverrides(
                                          produce(
                                            gatewaySourceOverrides,
                                            draft => {
                                              draft[index]!.utm_medium = value;
                                            }
                                          )
                                        );
                                      }}
                                      placeholder="Medium"
                                      spellCheck={false}
                                      autoCapitalize="off"
                                    />
                                  </InputWrapper>
                                  <InputWrapper
                                    labelText="Campaign Name"
                                    tooltip={{
                                      text: 'Example: "summer-sale"'
                                    }}
                                    disabled={setupFlow.isLoading}
                                  >
                                    <InputFieldText
                                      variant="SMALL"
                                      disabled={setupFlow.isLoading}
                                      value={override.utm_campaign}
                                      onChange={event => {
                                        const value = event.target.value;
                                        setGatewaySourceOverrides(
                                          produce(
                                            gatewaySourceOverrides,
                                            draft => {
                                              draft[index]!.utm_campaign =
                                                value;
                                            }
                                          )
                                        );
                                      }}
                                      placeholder="Name"
                                      spellCheck={false}
                                      autoCapitalize="off"
                                    />
                                  </InputWrapper>
                                </div>
                                <div>
                                  <Step1OverrideItemRemoveButton
                                    aria-label={`Remove: ${override.gatewayName}`}
                                    disabled={setupFlow.isLoading}
                                    onClick={() => {
                                      setGatewaySourceOverrides(
                                        produce(
                                          gatewaySourceOverrides,
                                          draft => {
                                            draft.splice(index, 1);
                                          }
                                        )
                                      );
                                    }}
                                  >
                                    <IconCross size="16px" />
                                  </Step1OverrideItemRemoveButton>
                                </div>
                              </Step1CustomItemWrapper>
                            ))}
                          </div>
                          <Step1IconTextButton
                            disabled={setupFlow.isLoading}
                            onClick={() => {
                              setGatewaySourceOverrides([
                                ...gatewaySourceOverrides,
                                {
                                  gatewayName: "",
                                  utm_campaign: "",
                                  utm_medium: "",
                                  utm_source: ""
                                }
                              ]);
                            }}
                          >
                            <div>
                              <IconCircledPlus size="24px" />
                            </div>
                            <div>Add Another</div>
                          </Step1IconTextButton>
                        </div>
                      </Step1CustomItemsExistWrapper>
                    )}
                  </Step1AdvancedOptionWrapper>
                  <Step1AdvancedOptionWrapper showBottomSeparator={false}>
                    {tagSourceOverrides.length === 0 ? (
                      <Step1IconTextButton
                        disabled={setupFlow.isLoading}
                        onClick={() => {
                          setTagSourceOverrides([
                            {
                              tagName: "",
                              utm_campaign: "",
                              utm_medium: "",
                              utm_source: ""
                            }
                          ]);
                        }}
                      >
                        <div>
                          <IconCog size="24px" />
                        </div>
                        <div>Customize by Tag</div>
                      </Step1IconTextButton>
                    ) : (
                      <Step1CustomItemsExistWrapper>
                        <div>
                          <div>Customize by Tag</div>
                          <div>
                            Please follow the Setup Guide for details on where
                            to find this.
                          </div>
                        </div>
                        <div>
                          <div>
                            {tagSourceOverrides.map((override, index) => (
                              <Step1CustomItemWrapper key={index} columns={4}>
                                <div>
                                  <InputWrapper
                                    labelText="Tag Name"
                                    tooltip={{
                                      text: 'Examples: "Subscription", "Customer Service"'
                                    }}
                                    disabled={
                                      setupFlow.isLoading ||
                                      override === subscriptionTagSourceOverride
                                    }
                                  >
                                    <InputFieldText
                                      variant="SMALL"
                                      disabled={
                                        setupFlow.isLoading ||
                                        override ===
                                          subscriptionTagSourceOverride
                                      }
                                      value={override.tagName}
                                      onChange={event => {
                                        const value = event.target.value;
                                        setTagSourceOverrides(
                                          produce(tagSourceOverrides, draft => {
                                            draft[index]!.tagName = value;
                                          })
                                        );
                                      }}
                                      placeholder="Tag"
                                      spellCheck={false}
                                      autoCapitalize="off"
                                    />
                                  </InputWrapper>
                                  <InputWrapper
                                    labelText="Campaign Source"
                                    tooltip={{ text: 'Example: "google"' }}
                                    disabled={
                                      setupFlow.isLoading ||
                                      override === subscriptionTagSourceOverride
                                    }
                                  >
                                    <InputFieldText
                                      variant="SMALL"
                                      disabled={
                                        setupFlow.isLoading ||
                                        override ===
                                          subscriptionTagSourceOverride
                                      }
                                      value={override.utm_source}
                                      onChange={event => {
                                        const value = event.target.value;
                                        setTagSourceOverrides(
                                          produce(tagSourceOverrides, draft => {
                                            draft[index]!.utm_source = value;
                                          })
                                        );
                                      }}
                                      placeholder="Source"
                                      spellCheck={false}
                                      autoCapitalize="off"
                                    />
                                  </InputWrapper>
                                  <InputWrapper
                                    labelText="Campaign Medium"
                                    tooltip={{ text: 'Example: "email"' }}
                                    disabled={
                                      setupFlow.isLoading ||
                                      override === subscriptionTagSourceOverride
                                    }
                                  >
                                    <InputFieldText
                                      variant="SMALL"
                                      disabled={
                                        setupFlow.isLoading ||
                                        override ===
                                          subscriptionTagSourceOverride
                                      }
                                      value={override.utm_medium}
                                      onChange={event => {
                                        const value = event.target.value;
                                        setTagSourceOverrides(
                                          produce(tagSourceOverrides, draft => {
                                            draft[index]!.utm_medium = value;
                                          })
                                        );
                                      }}
                                      placeholder="Medium"
                                      spellCheck={false}
                                      autoCapitalize="off"
                                    />
                                  </InputWrapper>
                                  <InputWrapper
                                    labelText="Campaign Name"
                                    tooltip={{
                                      text: 'Example: "summer-sale"'
                                    }}
                                    disabled={
                                      setupFlow.isLoading ||
                                      override === subscriptionTagSourceOverride
                                    }
                                  >
                                    <InputFieldText
                                      variant="SMALL"
                                      disabled={
                                        setupFlow.isLoading ||
                                        override ===
                                          subscriptionTagSourceOverride
                                      }
                                      value={override.utm_campaign}
                                      onChange={event => {
                                        const value = event.target.value;
                                        setTagSourceOverrides(
                                          produce(tagSourceOverrides, draft => {
                                            draft[index]!.utm_campaign = value;
                                          })
                                        );
                                      }}
                                      placeholder="Name"
                                      spellCheck={false}
                                      autoCapitalize="off"
                                    />
                                  </InputWrapper>
                                </div>
                                <div>
                                  <Step1OverrideItemRemoveButton
                                    aria-label={`Remove: ${override.tagName}`}
                                    disabled={
                                      setupFlow.isLoading ||
                                      override === subscriptionTagSourceOverride
                                    }
                                    onClick={() => {
                                      setTagSourceOverrides(
                                        produce(tagSourceOverrides, draft => {
                                          draft.splice(index, 1);
                                        })
                                      );
                                    }}
                                  >
                                    <IconCross size="16px" />
                                  </Step1OverrideItemRemoveButton>
                                </div>
                              </Step1CustomItemWrapper>
                            ))}
                          </div>
                          <Step1IconTextButton
                            disabled={setupFlow.isLoading}
                            onClick={() => {
                              setTagSourceOverrides([
                                ...tagSourceOverrides,
                                {
                                  tagName: "",
                                  utm_campaign: "",
                                  utm_medium: "",
                                  utm_source: ""
                                }
                              ]);
                            }}
                          >
                            <div>
                              <IconCircledPlus size="24px" />
                            </div>
                            <div>Add Another</div>
                          </Step1IconTextButton>
                        </div>
                      </Step1CustomItemsExistWrapper>
                    )}
                  </Step1AdvancedOptionWrapper>
                </div>
              </div>
            </Step1AdvancedOptionsWrapper>
          ) : null}
          <MarketGroupSettings
            config={config}
            destination={destination}
            isLoading={setupFlow.isLoading}
            isStepCompleted={setupFlow.isStepCompleted}
            saveButtonDisabledTooltipOverride={
              !areGa4FieldsFilledIn
                ? "Please provide your GA4 Measurement ID"
                : !isMeasurementIdValid
                  ? "The GA4 Measurement ID field must start with 'G-'"
                  : !areCustomDimensionsValid
                    ? "Please fill in all custom dimension fields"
                    : !areCustomDimensionsUnique
                      ? "Provide unique data and scope for custom dimensions"
                      : !areChannelOverridesValid
                        ? "All channel overrides must have a channel code"
                        : !areChannelOverridesUnique
                          ? "All channel overrides must use unique channel codes"
                          : !areGatewayOverridesValid
                            ? "All gateway overrides must have a gateway name"
                            : !areGatewayOverridesUnique
                              ? "All gateway overrides must use unique gateways names"
                              : !areTagOverridesValid
                                ? "All tag overrides must have a tag name"
                                : !areTagOverridesUnique
                                  ? "All tag overrides must use unique tag names"
                                  : null
            }
            onSave={async data => {
              await setupFlow.configMutation({
                ...data,
                measurementId,
                dataConfig: {
                  conversionValue,
                  channelSourceOverrides,
                  gatewaySourceOverrides,
                  tagSourceOverrides,
                  preferGaCookie,
                  sendUserData
                },
                customDimensions: customDimensions as Array<CustomDimension>
              });
            }}
          />
        </PageCard>
      </Step1Wrapper>
      <Modal
        isVisible={isSendUserDataModalVisible}
        onClose={() => setIsSendUserDataModalVisible(false)}
      >
        <Step1ModalContents>
          <Step1ModalTitle>
            Activate user-provided data collection
          </Step1ModalTitle>
          <Step1ModalBody>
            To activate this feature, please make sure you turn it on in GA4.{" "}
            <LinkExternal href="https://support.google.com/analytics/answer/14077171">
              How do I do this?
            </LinkExternal>
          </Step1ModalBody>
          <LabeledCheckBoxSingle
            variant="NORMAL"
            isChecked={isSendUserDataModalConfirmed}
            setIsChecked={setIsSendUserDataModalConfirmed}
          >
            I have this feature turned on in GA4
          </LabeledCheckBoxSingle>
          <Step1ModalButtons>
            <ButtonSecondary
              variant="SMALL"
              onClick={() => setIsSendUserDataModalVisible(false)}
            >
              Go Back
            </ButtonSecondary>
            <ButtonPrimary
              variant="SMALL"
              state={isSendUserDataModalConfirmed ? "IDLE" : "DISABLED"}
              onClick={() => {
                setSendUserData(true);
                setIsSendUserDataModalVisible(false);
              }}
            >
              Activate
            </ButtonPrimary>
          </Step1ModalButtons>
        </Step1ModalContents>
      </Modal>
    </>
  );
};

const Step1Wrapper = styled.div`
  > ${PageCard}:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }
`;

const Step1Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

const Step1AdvancedOptionsToggleButton = styled.button`
  margin-top: ${props => props.theme.gridBase * 3}px;
  display: flex;
  align-items: center;

  > div:first-child {
    ${normalTextStyles};
    margin-right: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    display: flex;
  }
`;

type Step1AdvancedOptionsWrapperProps = {
  inputWrapperWidth: number;
};

const Step1AdvancedOptionsWrapper = styled.div<Step1AdvancedOptionsWrapperProps>`
  padding-top: ${props => props.theme.gridBase * 2}px;

  > div:nth-child(1) {
    width: max-content;
    margin-bottom: ${props => props.theme.gridBase * 2}px;
  }

  > div:nth-child(2) {
    width: ${props => props.inputWrapperWidth}px;
    margin-bottom: ${props => props.theme.gridBase * 3}px;
  }

  > div:nth-child(3) {
    > div:first-child {
      ${normalBodyStyles};
      color: ${props => props.theme.palette.grey2};
      margin-bottom: ${props => props.theme.gridBase * 3}px;
    }
  }
`;

const Step1AdvancedOptionsCheckboxTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;

  > p {
    margin-bottom: ${props => props.theme.gridBase}px;
  }
`;

type Step1AdvancedOptionWrapperProps = {
  showBottomSeparator: boolean;
};

const Step1AdvancedOptionWrapper = styled.div<Step1AdvancedOptionWrapperProps>`
  ${props =>
    props.showBottomSeparator
      ? css`
          margin-bottom: ${props => props.theme.gridBase * 3}px;
          border-bottom: 1px solid ${props => props.theme.palette.grey6};
          padding-bottom: ${props => props.theme.gridBase * 3}px;
        `
      : css`
          margin-bottom: ${props => props.theme.gridBase * 1.5}px;
        `}
`;

const Step1IconTextButton = styled.button`
  ${iconTextButtonStyles};
`;

const Step1CustomItemsExistWrapper = styled.div`
  > div:first-child {
    margin-bottom: ${props => props.theme.gridBase * 2}px;

    > div:first-child {
      ${heading3Styles};
      margin-bottom: ${props => props.theme.gridBase}px;
    }

    > div:last-child {
      ${normalBodyStyles};
      color: ${props => props.theme.palette.grey2};

      > a {
        ${linkStyles};
      }
    }
  }
`;

type Step1CustomItemWrapperProps = {
  columns: 3 | 4;
};

const Step1CustomItemWrapper = styled.div<Step1CustomItemWrapperProps>`
  display: flex;
  margin-bottom: ${props => props.theme.gridBase * 2}px;

  > div:first-child {
    display: grid;
    gap: ${props => props.theme.gridBase}px;
    grid-template-columns: ${props =>
      (() => {
        switch (props.columns) {
          case 3:
            return `repeat(3, ${props.theme.gridBase * 30}px)`;
          case 4:
            return `
              minmax(
                ${props.theme.gridBase * 30}px,
                ${props.theme.gridBase * 40}px
              )
              repeat(3, minmax(
                ${props.theme.gridBase * 15}px,
                ${props.theme.gridBase * 30}px
              ))
            `;
        }
      })()};

    @media screen and (max-width: 1600px) {
      width: 100%;
      grid-template-columns: 1fr;
      gap: ${props => props.theme.gridBase * 1.5}px;
      justify-items: stretch;
    }
  }

  > div:last-child {
    margin-left: ${props => props.theme.gridBase}px;
  }

  &:not(:last-child) {
    @media screen and (max-width: 1600px) {
      margin-bottom: ${props => props.theme.gridBase * 2}px;
      border-bottom: 1px solid ${props => props.theme.palette.grey6};
      padding-bottom: ${props => props.theme.gridBase * 2}px;
    }
  }
`;

const Step1InputWrapperTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;

  > p {
    margin-bottom: ${props => props.theme.gridBase * 0.75}px;
  }

  a {
    ${linkStyles};
  }
`;

const Step1OverrideItemRemoveButton = styled.button`
  ${iconButtonStyles};
  color: ${props => props.theme.palette.grey3};
  margin-top: ${props => props.theme.gridBase * 4.5}px;
`;

const Step1ModalContents = styled.div`
  width: ${props => props.theme.gridBase * 38}px;
`;

const Step1ModalTitle = styled.div`
  ${heading3Styles};
  text-align: center;
  color: ${props => props.theme.palette.grey1};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const Step1ModalBody = styled.div`
  ${normalBodyStyles};
  text-align: center;
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 1.5}px;

  > a {
    ${linkStyles};
  }
`;

const Step1ModalButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: ${props => props.theme.gridBase}px;
  margin-top: ${props => props.theme.gridBase * 2}px;
`;

/* ========================================================================== */

const Step2: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();
  const { shopifyOAuthScopes } = useSetupFlowContext();

  const [eventState, setEventState] = useState({
    enabledEvents: getExposedEvents(config.enabledEvents),
    enabledWebEvents: config.enabledWebEvents,
    webhookOverrides: config.webhookOverrides
  });

  return (
    <PageCard>
      <StepSection
        title="Events"
        setupGuideHref={setupGuideHref}
        description={
          <Step2Explainer>
            Select what events you want to send to GA4.
          </Step2Explainer>
        }
      />
      <EventDestinationTable
        isLoading={setupFlow.isLoading}
        isStepCompleted={setupFlow.isStepCompleted}
        shopifyOAuthScopes={shopifyOAuthScopes}
        mutualExclusivityLevel="EVENT"
        details={{
          eventState,
          setEventState,
          destinationName: destination.name
        }}
        recommended={{
          enabledEvents: recommendedEvents,
          enabledWebEvents: recommendedWebEvents
        }}
        onSave={options => setupFlow.configMutation(eventState, options)}
      />
    </PageCard>
  );
};

const Step2Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

/* ========================================================================== */

const Step3: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  return (
    <ConsentModeReader
      details={{
        type: "DESTINATION",
        isStepCompleted: setupFlow.isStepCompleted,
        setupGuideHref,
        name: destination.name
      }}
      isLoading={setupFlow.isLoading}
      initial={config.consentMode}
      onSave={data => setupFlow.configMutation(data)}
    />
  );
};

/* ========================================================================== */

const Step4: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  const [orderAttributeId, setOrderAttributeId] = useState(
    config.dataConfig.orderAttributeId
  );

  return (
    <TransactionIdentifier
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      media={{
        type: "VIDEO",
        url: "https://player.vimeo.com/video/618246666"
      }}
      options={["name", "order_number", "id"]}
      orderAttributeId={orderAttributeId}
      setOrderAttributeId={setOrderAttributeId}
      onSave={async () => {
        await setupFlow.configMutation({
          dataConfig: { orderAttributeId }
        });
      }}
    />
  );
};

/* ========================================================================== */

const Step5: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  const [productAttributeMapping, setProductAttributeMapping] = useState(
    config.dataConfig.productAttributeMapping
  );

  return (
    <ProductIdentifier
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      media={{
        type: "VIDEO",
        url: "https://player.vimeo.com/video/618246470"
      }}
      options={["sku", "product_id", "variant_id"]}
      productAttributeMapping={productAttributeMapping}
      setProductAttributeMapping={setProductAttributeMapping}
      onSave={async () => {
        await setupFlow.configMutation({
          dataConfig: { productAttributeMapping }
        });
      }}
    />
  );
};

/* ========================================================================== */

const Step6: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  return (
    <FilterTransactions
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      initialFilters={config.orderFilters}
      usesUnifiedCheckout={config.uses_unified_checkout}
      subscriptionTagName={config.subscriptionTagName}
      onSave={(data, options) => setupFlow.configMutation(data, options)}
    />
  );
};

/* ========================================================================== */

const Step7: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  return (
    <Subscriptions
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      media={{
        type: "VIDEO",
        url: "https://player.vimeo.com/video/618245719"
      }}
      details={{
        key: destination.configKey,
        config,
        onSave: (data, options) => setupFlow.configMutation(data, options)
      }}
    />
  );
};

/* ========================================================================== */

const Step8: React.FC = () => {
  const { eventsConnectorConfig } = useMyTrackingDetails();
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();
  const { containerInfo } = useSetupFlowContext();

  const { mutateAsync: myTrackingVersionMutation } =
    useMyTrackingVersionMutation();

  return (
    <WebContainerSetup
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      containerUrl={containerInfo.web_container_url ?? ""}
      eventsConnectorConfig={eventsConnectorConfig}
      details={{ destination, config }}
      onSave={async () => {
        setupFlow.setIsLoading(true);
        await myTrackingVersionMutation("destination-ga4");
        await setupFlow.configMutation({});
        setupFlow.setIsLoading(false);
      }}
    />
  );
};
