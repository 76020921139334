import { type PascalCase } from "type-fest";

import {
  type Plan as PlanFromApi,
  type PlanId,
  type PlanUsageId,
  type SupportTier
} from "elevar-common-ts/src/apiTypes";

/* ========================================================================== */

export type ManagePlanStep = "SELECT_PLAN" | "SELECT_PRODUCT" | "CHECKOUT";

/* ========================================================================== */

type PlanDetailsItemValueBoolean = { type: "BOOLEAN"; value: boolean };
type PlanDetailsItemValueString = { type: "STRING"; value: string };
type PlanDetailsItemValueStringBig = { type: "STRING_BIG"; value: string };
type PlanDetailsItemValueLink = { type: "LINK"; text: string; href: string };

export type PlanDetailsItemValue =
  | PlanDetailsItemValueBoolean
  | PlanDetailsItemValueString
  | PlanDetailsItemValueStringBig
  | PlanDetailsItemValueLink;

type PlanUsageKey = "Orders Included/Month" | "Extra Order Fee";

type PlanFeatureKey =
  | "Session Enrichment"
  | "Built-in Consent Mode"
  | "Conversion Accuracy Monitoring"
  | "Facebook Conversion API"
  | "Multiple Pixels per Destination"
  | "Google Ads"
  | "Real-Time Reporting"
  | "Email Alerts"
  | "Shopify Markets";

type PlanSupportKey = "Support Level" | "Onboarding Phone Calls";

type PlanServicesKey =
  | "One-Time Audits & Setups"
  | "Tracking Management Tier 1"
  | "Tracking Management Tier 2";

export type Plan = {
  id: PlanId;
  usageId: PlanUsageId | null;
  name: string;
  dollarPrice: number;
  interval: PlanFromApi["interval"];
  supportTier: PascalCase<NonNullable<SupportTier>>;
  usage: Record<PlanUsageKey, PlanDetailsItemValueString>;
  features: Record<PlanFeatureKey, PlanDetailsItemValue>;
  support: Record<PlanSupportKey, PlanDetailsItemValue>;
  services: Record<PlanServicesKey, PlanDetailsItemValue>;
  description?: string;
  code?: string;
};

export const starterPlan: Plan = {
  id: "FM_STARTER_FIXED_0",
  usageId: "FM_STARTER_USAGE_40",
  name: "Starter",
  dollarPrice: 0,
  interval: "month",
  supportTier: "Standard",
  usage: {
    "Orders Included/Month": { type: "STRING", value: "100" },
    "Extra Order Fee": { type: "STRING", value: "0.40" }
  },
  features: {
    "Session Enrichment": { type: "BOOLEAN", value: true },
    "Built-in Consent Mode": { type: "BOOLEAN", value: true },
    "Conversion Accuracy Monitoring": { type: "BOOLEAN", value: true },
    "Facebook Conversion API": { type: "BOOLEAN", value: true },
    "Multiple Pixels per Destination": { type: "BOOLEAN", value: true },
    "Google Ads": { type: "BOOLEAN", value: true },
    "Real-Time Reporting": { type: "BOOLEAN", value: false },
    "Email Alerts": { type: "BOOLEAN", value: false },
    "Shopify Markets": { type: "BOOLEAN", value: false }
  },
  support: {
    "Support Level": { type: "STRING", value: "Standard" },
    "Onboarding Phone Calls": { type: "BOOLEAN", value: true }
  },
  services: {
    "One-Time Audits & Setups": {
      type: "STRING",
      value: "Available for Purchase"
    },
    "Tracking Management Tier 1": {
      type: "STRING",
      value: "Available for Purchase"
    },
    "Tracking Management Tier 2": {
      type: "LINK",
      text: "Contact Sales",
      href: "https://www.getelevar.com/book-call-with-sales/"
    }
  }
};

const essentialsPlan: Plan = {
  id: "FM_ESSENTIALS_FIXED_200",
  usageId: "FM_ESSENTIALS_USAGE_15",
  name: "Essentials",
  dollarPrice: 200,
  interval: "month",
  supportTier: "Standard",
  usage: {
    "Orders Included/Month": { type: "STRING", value: "1000" },
    "Extra Order Fee": { type: "STRING", value: "0.15" }
  },
  features: {
    "Session Enrichment": { type: "BOOLEAN", value: true },
    "Built-in Consent Mode": { type: "BOOLEAN", value: true },
    "Conversion Accuracy Monitoring": { type: "BOOLEAN", value: true },
    "Facebook Conversion API": { type: "BOOLEAN", value: true },
    "Multiple Pixels per Destination": { type: "BOOLEAN", value: true },
    "Google Ads": { type: "BOOLEAN", value: true },
    "Real-Time Reporting": { type: "BOOLEAN", value: true },
    "Email Alerts": { type: "BOOLEAN", value: true },
    "Shopify Markets": { type: "BOOLEAN", value: false }
  },
  support: {
    "Support Level": { type: "STRING", value: "Standard" },
    "Onboarding Phone Calls": { type: "BOOLEAN", value: true }
  },
  services: {
    "One-Time Audits & Setups": {
      type: "STRING",
      value: "Available for Purchase"
    },
    "Tracking Management Tier 1": {
      type: "STRING",
      value: "Available for Purchase"
    },
    "Tracking Management Tier 2": {
      type: "LINK",
      text: "Contact Sales",
      href: "https://www.getelevar.com/book-call-with-sales/"
    }
  }
};

const growthPlan: Plan = {
  id: "FM_GROWTH_FIXED_450",
  usageId: "FM_GROWTH_USAGE_04",
  name: "Growth",
  dollarPrice: 450,
  interval: "month",
  supportTier: "Standard",
  usage: {
    "Orders Included/Month": { type: "STRING", value: "10,000" },
    "Extra Order Fee": { type: "STRING", value: "0.04" }
  },
  features: {
    "Session Enrichment": { type: "BOOLEAN", value: true },
    "Built-in Consent Mode": { type: "BOOLEAN", value: true },
    "Conversion Accuracy Monitoring": { type: "BOOLEAN", value: true },
    "Facebook Conversion API": { type: "BOOLEAN", value: true },
    "Multiple Pixels per Destination": { type: "BOOLEAN", value: true },
    "Google Ads": { type: "BOOLEAN", value: true },
    "Real-Time Reporting": { type: "BOOLEAN", value: true },
    "Email Alerts": { type: "BOOLEAN", value: true },
    "Shopify Markets": { type: "BOOLEAN", value: true }
  },
  support: {
    "Support Level": { type: "STRING", value: "Standard" },
    "Onboarding Phone Calls": { type: "BOOLEAN", value: true }
  },
  services: {
    "One-Time Audits & Setups": {
      type: "STRING",
      value: "Available for Purchase"
    },
    "Tracking Management Tier 1": {
      type: "STRING",
      value: "Available for Purchase"
    },
    "Tracking Management Tier 2": {
      type: "LINK",
      text: "Contact Sales",
      href: "https://www.getelevar.com/book-call-with-sales/"
    }
  }
};

const businessPlan: Plan = {
  id: "FM_BUSINESS_FIXED_950",
  usageId: "FM_BUSINESS_USAGE_03",
  name: "Business",
  dollarPrice: 950,
  interval: "month",
  supportTier: "Priority",
  usage: {
    "Orders Included/Month": { type: "STRING", value: "50,000" },
    "Extra Order Fee": { type: "STRING", value: "0.03" }
  },
  features: {
    "Session Enrichment": { type: "BOOLEAN", value: true },
    "Built-in Consent Mode": { type: "BOOLEAN", value: true },
    "Conversion Accuracy Monitoring": { type: "BOOLEAN", value: true },
    "Facebook Conversion API": { type: "BOOLEAN", value: true },
    "Multiple Pixels per Destination": { type: "BOOLEAN", value: true },
    "Google Ads": { type: "BOOLEAN", value: true },
    "Real-Time Reporting": { type: "BOOLEAN", value: true },
    "Email Alerts": { type: "BOOLEAN", value: true },
    "Shopify Markets": { type: "BOOLEAN", value: true }
  },
  support: {
    "Support Level": { type: "STRING", value: "Priority" },
    "Onboarding Phone Calls": { type: "BOOLEAN", value: true }
  },
  services: {
    "One-Time Audits & Setups": {
      type: "STRING",
      value: "Available for Purchase"
    },
    "Tracking Management Tier 1": {
      type: "STRING",
      value: "Available for Purchase"
    },
    "Tracking Management Tier 2": {
      type: "LINK",
      text: "Contact Sales",
      href: "https://www.getelevar.com/book-call-with-sales/"
    }
  }
};

export const plans = [
  starterPlan,
  essentialsPlan,
  growthPlan,
  businessPlan
] as const;

export type CurrentPlan = { local: Plan | null; remote: PlanFromApi };

/* ========================================================================== */

export type AddOnId = "BASIC_TRACKING_MGMT_500";

export type AddOn = {
  id: AddOnId;
  name: string;
  description: string;
  whatsIncluded: Array<string>;
  whatsRequired: Array<string>;
  dollarPrice: number;
};

export const trackingManagementTier1AddOn: AddOn = {
  id: "BASIC_TRACKING_MGMT_500",
  name: "Tracking Management Tier 1",
  description:
    "Let Elevar's analysts manage all your ongoing tagging & tracking needs.",
  whatsIncluded: [
    "Implement new destinations",
    "Fix broken tags",
    "Analytics event tagging",
    "Google Analytics support (both UA and GA4)"
  ],
  whatsRequired: ["Requires an Expert Install"],
  dollarPrice: 500
};

/* ========================================================================== */

type ProductId = "expert-install";

export type Product = {
  id: ProductId;
  name: string;
  description: string;
  whatsIncluded: Array<string>;
  dollarPrice: number;
};

export const expertInstallProduct: Product = {
  id: "expert-install",
  name: "Expert Install",
  description:
    "Prefer to have Elevar's analysts manage the installation, server-side configurations, deploying and testing all of your tracking? Purchase this add-on & complete your onboarding form to get started. Not available for headless sites.",
  whatsIncluded: [
    "Data Layer setup",
    "Google Analytics tagging",
    "Facebook Conversion API",
    "Pre-built tags setup",
    "Server-side setup"
  ],
  dollarPrice: 1000
};

export const products: Array<Product> = [expertInstallProduct];
