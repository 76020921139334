import { produce } from "immer";
import { startCase } from "lodash-es";
import { transparentize } from "polished";
import { useId, useState } from "react";
import styled, { css, type DefaultTheme, useTheme } from "styled-components";

import { type MarketGroup } from "elevar-common-ts/src/apiTypes";
import {
  type OptionalPromise,
  unsafeTypedEntries
} from "elevar-common-ts/src/utils";

import {
  ButtonPrimary,
  ButtonPrimaryAsLinkExternal,
  ButtonSecondaryAsLinkExternal
} from "elevar-design-system/src/buttons/ButtonVariants";
import { IconExternalLink } from "elevar-design-system/src/icons";
import { LabeledRadioText } from "elevar-design-system/src/labeledRadios/LabeledRadioText";
import {
  LinkExternal,
  StyledLinkExternal
} from "elevar-design-system/src/links/LinkExternal";
import { linkStyles } from "elevar-design-system/src/links/links";
import {
  heading3Styles,
  normalBodyStyles,
  subheadingStyles
} from "elevar-design-system/src/typography/typography";

import {
  useGlobalConfigMutation,
  useMarketGroupsMutation
} from "../../api/handlers/website";
import { ConsentWarningModal } from "../../components/ConsentWarningModal";
import { EventTable } from "../../components/EventTable";
import { PageCard } from "../../components/PageCard";
import { useMyTrackingDetails } from "../../context/MyTrackingDetails";
import { useCompanyId, useWebsiteId } from "../../utils/idHooks";
import { getNormalizedMarketGroups } from "../../utils/markets";
import { toast } from "../../utils/toast";
import { StepSection } from "./StepSection";

/* ========================================================================== */

const getConsentFallbackTooltip = (
  theme: DefaultTheme,
  key: keyof MarketGroup["consentFallback"]
) => {
  switch (key) {
    case "ad_storage":
      return {
        maxWidth: theme.gridBase * 38,
        content: () => (
          <>
            Tracking will treat ad storage as granted if no consent information
            is provided.
          </>
        )
      };
    case "analytics_storage":
      return {
        maxWidth: theme.gridBase * 42,
        content: () => (
          <>
            Tracking will treat analytics storage as granted if no consent
            information is provided.
          </>
        )
      };
    case "functionality_storage":
      return {
        maxWidth: theme.gridBase * 42,
        content: () => (
          <>
            Tracking will treat functionality storage as granted if no consent
            information is provided.
          </>
        )
      };
    case "personalization_storage":
      return {
        maxWidth: theme.gridBase * 42,
        content: () => (
          <>
            Tracking will treat personalization storage as granted if no consent
            information is provided.
          </>
        )
      };
    case "security_storage":
      return {
        maxWidth: theme.gridBase * 42,
        content: () => (
          <>
            Tracking will treat security storage as granted if no consent
            information is provided.
          </>
        )
      };
  }
};

/* ========================================================================== */

type ConsentModeWriterProps = {
  isStepCompleted: boolean;
  isMarketsAllowed: boolean;
  isUpgradingOrUpdating: boolean;
  onSaveAndContinue: () => OptionalPromise<void>;
};

export const ConsentModeWriter: React.FC<ConsentModeWriterProps> = ({
  isStepCompleted,
  isMarketsAllowed,
  isUpgradingOrUpdating,
  onSaveAndContinue
}) => {
  const theme = useTheme();
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();
  const radioGroupName = useId();
  const { eventsConnectorConfig } = useMyTrackingDetails();

  const { mutateAsync: globalConfigMutation } = useGlobalConfigMutation();
  const { mutateAsync: marketGroupsMutation } = useMarketGroupsMutation();

  const marketsEnabled =
    isMarketsAllowed && eventsConnectorConfig.globalConfig.marketsEnabled;

  const [consentModeEnabled, setConsentModeEnabled] = useState(
    eventsConnectorConfig.globalConfig.consentModeEnabled
  );
  const [isConsentModeWarningModalShown, setIsConsentModeWarningModalShown] =
    useState(false);
  const [hasVisitedConsentGuide, setHasVisitedConsentGuide] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [marketGroups, setMarketGroups] = useState(() =>
    getNormalizedMarketGroups({
      marketsEnabled,
      groups: eventsConnectorConfig.marketGroups
    })
  );

  const GuideButton = hasVisitedConsentGuide
    ? ButtonSecondaryAsLinkExternalWithIcon
    : ButtonPrimaryAsLinkExternalWithIcon;

  const websiteUrl = `/company/${companyId}/website/${websiteId}`;
  const supportTicketUrl = `${websiteUrl}/support?modalVisibleDefault=TRUE`;

  return (
    <>
      <Step3Wrapper>
        <PageCard>
          <StepSection
            title="Would you like to enable our Google Consent Mode integration?"
            description={
              <Step3Explainer>
                By enabling, your tracking will rely on Google Tag Manager's
                Consent Mode to conditionally send data based on the consent
                choices of your shoppers.
              </Step3Explainer>
            }
          >
            <LabeledRadioText
              groupName={radioGroupName}
              isSelected={!consentModeEnabled}
              setIsSelected={() => setConsentModeEnabled(false)}
              text="No"
              isDisabled={isLoading}
            />
            <LabeledRadioText
              groupName={radioGroupName}
              isSelected={consentModeEnabled}
              setIsSelected={() => setIsConsentModeWarningModalShown(true)}
              text="Yes"
              isDisabled={isLoading}
            />
          </StepSection>
          {!consentModeEnabled ? (
            <Step3ButtonWrapper>
              <ButtonPrimary
                variant="SMALL"
                state={isLoading ? "LOADING" : "IDLE"}
                onClick={async () => {
                  setIsLoading(true);
                  await globalConfigMutation({ consentModeEnabled });

                  if (isStepCompleted) {
                    toast.success("Source updated");
                  } else {
                    await onSaveAndContinue();
                  }

                  setIsLoading(false);
                }}
              >
                {isStepCompleted ? "Save" : "Save & Continue"}
              </ButtonPrimary>
            </Step3ButtonWrapper>
          ) : null}
        </PageCard>
        {consentModeEnabled ? (
          <PageCard>
            {isUpgradingOrUpdating &&
            eventsConnectorConfig.globalConfig.consentModeEnabled ? (
              <HeadsUpWrapper spacing="BOTTOM">
                <div>⚠ Update Consentmo Integration</div>
                <p>
                  If you use <span>Consentmo</span> as your consent provider,
                  please update your consent integration to be compatible with
                  the latest best practices.
                </p>
                <div>
                  <ButtonPrimaryAsLinkExternal
                    variant="SMALL"
                    href="https://docs.getelevar.com/docs/updating-consentmo-for-shopify-custom-pixel"
                  >
                    Go to Guide
                  </ButtonPrimaryAsLinkExternal>
                  <StyledLinkExternal
                    href={supportTicketUrl}
                    text="Having trouble? Connect with our Support Team"
                  />
                </div>
              </HeadsUpWrapper>
            ) : null}
            <StepSection
              title="Set Up Your Consent Integration"
              description={
                <Step3Explainer>
                  Follow{" "}
                  <LinkExternal
                    href="https://docs.getelevar.com/docs/compatible-consent-providers"
                    onClick={() => setHasVisitedConsentGuide(true)}
                  >
                    our guide
                  </LinkExternal>{" "}
                  to configure your consent provider to work with GTM & Elevar.
                </Step3Explainer>
              }
              descriptionSpacing={false}
            >
              {consentModeEnabled !==
              eventsConnectorConfig.globalConfig.consentModeEnabled ? (
                <Step3ButtonWrapper>
                  <GuideButton
                    variant="SMALL"
                    href="https://docs.getelevar.com/docs/compatible-consent-providers"
                    onClick={() => setHasVisitedConsentGuide(true)}
                  >
                    <div>Go to Guide</div>
                    <div>
                      <IconExternalLink size="16px" />
                    </div>
                  </GuideButton>
                  {hasVisitedConsentGuide ? (
                    <ButtonPrimary
                      variant="SMALL"
                      state={isLoading ? "LOADING" : "IDLE"}
                      onClick={async () => {
                        setIsLoading(true);
                        await globalConfigMutation({ consentModeEnabled });

                        if (isStepCompleted) {
                          toast.success("Source updated");
                        }

                        setIsLoading(false);
                      }}
                    >
                      Mark as Complete
                    </ButtonPrimary>
                  ) : null}
                </Step3ButtonWrapper>
              ) : null}
            </StepSection>
          </PageCard>
        ) : null}
        {consentModeEnabled &&
        consentModeEnabled ===
          eventsConnectorConfig.globalConfig.consentModeEnabled ? (
          <PageCard>
            <StepSection
              title="What categories would you like to grant if no consent is provided?"
              description={
                <Step3Explainer>
                  If your consent banner doesn't pass a shopper's consent
                  choices (for example, if a shopper uses an ad blocker), select
                  what consent categories your server-side events will view as
                  granted by the shopper.
                </Step3Explainer>
              }
            >
              {marketGroups.map((group, index) => (
                <Step3ConsentFallbackTableWrapper key={index}>
                  {marketsEnabled ? (
                    <div>{group.markets.map(m => m.name).join(", ")}</div>
                  ) : null}
                  <EventTable
                    rows={unsafeTypedEntries(group.consentFallback).map(
                      ([key, value]) => ({
                        key,
                        name: startCase(key),
                        tooltip: getConsentFallbackTooltip(theme, key),
                        context: { isChecked: value }
                      })
                    )}
                    columns={[
                      {
                        type: "CHECK_BOX",
                        name: "",
                        size: {
                          min: `${theme.gridBase * 10}px`,
                          max: `${theme.gridBase * 18}px`
                        },
                        getAccessors: ({ key, context }) => ({
                          isChecked: context.isChecked,
                          setIsChecked: x =>
                            setMarketGroups(
                              produce(marketGroups, draft => {
                                draft[index]!.consentFallback = {
                                  ...draft[index]!.consentFallback,
                                  [key]: x
                                };
                              })
                            ),
                          isDisabled: isLoading
                        })
                      }
                    ]}
                  />
                </Step3ConsentFallbackTableWrapper>
              ))}
            </StepSection>
            <Step3ButtonWrapper>
              <ButtonPrimary
                variant="SMALL"
                state={isLoading ? "LOADING" : "IDLE"}
                onClick={async () => {
                  setIsLoading(true);

                  await Promise.all([
                    globalConfigMutation({ consentModeEnabled }),
                    marketGroupsMutation({
                      markets_enabled: marketsEnabled,
                      market_groups: marketGroups.map(group => ({
                        id: group.id!,
                        consentFallback: group.consentFallback,
                        markets: group.markets.map(market => ({
                          source: market.source,
                          external_id: market.external_id
                        }))
                      }))
                    })
                  ]);

                  if (isStepCompleted) {
                    toast.success("Source updated");
                  } else {
                    await onSaveAndContinue();
                  }

                  setIsLoading(false);
                }}
              >
                {isStepCompleted ? "Save" : "Save & Continue"}
              </ButtonPrimary>
            </Step3ButtonWrapper>
          </PageCard>
        ) : null}
      </Step3Wrapper>
      <ConsentWarningModal
        type="WRITER"
        isVisible={isConsentModeWarningModalShown}
        onClose={() => setIsConsentModeWarningModalShown(false)}
        onEnable={() => setConsentModeEnabled(true)}
      />
    </>
  );
};

/* ========================================================================== */

const Step3Wrapper = styled.div`
  > ${PageCard}:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }
`;

const Step3Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};

  a {
    ${linkStyles};
  }
`;

const Step3ConsentFallbackTableWrapper = styled.div`
  > div:first-child {
    ${subheadingStyles};
    color: ${props => props.theme.palette.grey3};
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }

  &:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 2.5}px;
  }
`;

const Step3ButtonWrapper = styled.div`
  display: flex;
  gap: ${props => props.theme.gridBase * 2}px;
  margin-top: ${props => props.theme.gridBase * 3}px;
`;

const buttonWithIconStyles = css`
  align-items: center;
  gap: ${props => props.theme.gridBase}px;

  > div:last-child {
    display: flex;
  }
`;

const ButtonPrimaryAsLinkExternalWithIcon = styled(ButtonPrimaryAsLinkExternal)`
  ${buttonWithIconStyles};
`;

const ButtonSecondaryAsLinkExternalWithIcon = styled(
  ButtonSecondaryAsLinkExternal
)`
  ${buttonWithIconStyles};
`;

type HeadsUpWrapperProps = {
  spacing: "TOP" | "BOTTOM";
};

const HeadsUpWrapper = styled.div<HeadsUpWrapperProps>`
  padding: ${props => props.theme.gridBase * 2}px;
  background-color: ${props => transparentize(0.9, props.theme.palette.red1)};
  border-radius: 4px;
  margin-top: ${props =>
    props.spacing === "TOP" ? props.theme.gridBase * 3 : 0}px;
  margin-bottom: ${props =>
    props.spacing === "BOTTOM" ? props.theme.gridBase * 3 : 0}px;

  > div:first-child {
    ${heading3Styles};
    margin-bottom: ${props => props.theme.gridBase}px;
  }

  > p {
    ${normalBodyStyles};

    > span {
      font-weight: 600;
    }
  }

  > div:last-child {
    display: flex;
    align-items: center;
    gap: ${props => props.theme.gridBase * 2.5}px;
    margin-top: ${props => props.theme.gridBase * 2}px;

    @media screen and (max-width: 1360px) {
      flex-direction: column;
      align-items: flex-start;
      gap: ${props => props.theme.gridBase * 2}px;
    }
  }
`;
