import { type OverrideProperties } from "type-fest";

import { type MarketGroup } from "elevar-common-ts/src/apiTypes";

/* ========================================================================== */

export const predefinedMarketInfo = {
  unconfigured: {
    name: "Unconfigured Market",
    source: "_Required",
    external_id: "unconfigured"
  },
  none: {
    name: "No Market ID",
    source: "_Required",
    external_id: "no_market_id"
  }
} as const;

export const consentFallbackDefaults: MarketGroup["consentFallback"] = {
  ad_storage: false,
  analytics_storage: false,
  functionality_storage: false,
  personalization_storage: false,
  security_storage: false
};

/* ========================================================================== */

type CreateableMarketGroup = OverrideProperties<
  MarketGroup,
  { id: number | null }
>;

const dummyMarketId = 0;

type GetNormalizedMarketGroups = (args: {
  marketsEnabled: boolean;
  groups: Array<MarketGroup | CreateableMarketGroup>;
  exclusions?: "NO_MARKET_ID" | "NO_MARKET_ID+UNCONFIGURED_MARKET";
}) => Array<CreateableMarketGroup>;

export const getNormalizedMarketGroups: GetNormalizedMarketGroups = ({
  marketsEnabled,
  groups,
  exclusions
}) => {
  const excludeNoMarketId = (groupsToFilter: typeof groups) => {
    return groupsToFilter
      .map(group => ({
        ...group,
        markets: group.markets.filter(
          m => m.external_id !== predefinedMarketInfo.none.external_id
        )
      }))
      .filter(g => g.markets.length > 0);
  };

  const excludeUnconfiguredMarket = (groupsToFilter: typeof groups) => {
    return groupsToFilter.map(group => ({
      ...group,
      markets: group.markets.filter(
        m => m.external_id !== predefinedMarketInfo.unconfigured.external_id
      )
    }));
  };

  if (marketsEnabled) {
    if (exclusions === "NO_MARKET_ID") {
      return excludeNoMarketId(groups);
    } else if (exclusions === "NO_MARKET_ID+UNCONFIGURED_MARKET") {
      return excludeUnconfiguredMarket(excludeNoMarketId(groups));
    } else {
      return groups;
    }
  } else {
    const group = groups[0];
    return [
      {
        id: group?.id ?? null,
        gtm_container: group?.gtm_container ?? "",
        consentFallback: group?.consentFallback ?? consentFallbackDefaults,
        markets: [
          { id: dummyMarketId, ...predefinedMarketInfo.unconfigured },
          { id: dummyMarketId, ...predefinedMarketInfo.none }
        ]
      }
    ];
  }
};
