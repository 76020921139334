import { useState } from "react";
import {
  matchPath,
  Prompt,
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch
} from "react-router-dom";
import styled, { useTheme } from "styled-components";

import {
  type ConsentModeConfig,
  type WebsiteDetails
} from "elevar-common-ts/src/apiTypes";

import { ButtonPrimary } from "elevar-design-system/src/buttons/ButtonVariants";
import {
  IconCheckMark,
  IconCircledPause,
  IconEnrich,
  IconFacebook,
  IconKlaviyo,
  IconTrash
} from "elevar-design-system/src/icons";
import { InputFieldText } from "elevar-design-system/src/inputs/InputFieldText";
import { StyledLinkExternal } from "elevar-design-system/src/links/LinkExternal";
import { Option } from "elevar-design-system/src/Option";
import { Tabs } from "elevar-design-system/src/Tabs";
import { Toggle } from "elevar-design-system/src/Toggle";
import { Tooltip } from "elevar-design-system/src/Tooltip";
import {
  heading2Styles,
  heading3Styles,
  normalBodyStyles
} from "elevar-design-system/src/typography/typography";

import { useRedactCustomerMutation } from "../../api/handlers/shopify";
import { useGlobalConfigMutation } from "../../api/handlers/website";
import { ActionWarningModal } from "../../components/ActionWarningModal";
import { BackLink } from "../../components/BackLink";
import { InputFieldPhone } from "../../components/InputFieldPhone";
import { PageCard } from "../../components/PageCard";
import { Status } from "../../components/Status";
import { RedirectWithoutLastLocation } from "../../context/LastLocation";
import { useMyTrackingDetails } from "../../context/MyTrackingDetails";
import { emptyStringCleaveValues } from "../../utils/cleave";
import { useCompanyId, useWebsiteId } from "../../utils/idHooks";
import { toast } from "../../utils/toast";
import { isEmail } from "../../utils/validate";
import { ConsentModeReader } from "./ConsentModeReader";
import { sourceEnrich } from "./data";

/* ========================================================================== */

const websitePath = "/company/:companyId/website/:websiteId";
const basePath = `${websitePath}/my-tracking/source-enrich`;
const overviewPath = `${basePath}/overview`;
const consentModePath = `${basePath}/consent-mode`;
const dataManagementPath = `${basePath}/data-management`;

type SourceSessionEnrichmentProps = {
  website: WebsiteDetails;
};

export const SourceSessionEnrichment: React.FC<
  SourceSessionEnrichmentProps
> = ({ website }) => {
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();
  const { sourceInfo, eventsConnectorConfig } = useMyTrackingDetails();

  const isOn = sourceInfo.enrich?.isOn ?? false;

  const websiteUrl = `/company/${companyId}/website/${websiteId}`;
  const myTrackingUrl = `${websiteUrl}/my-tracking`;
  const enrichUrl = `${myTrackingUrl}/source-${sourceEnrich.shorthand}`;
  const overviewUrl = `${enrichUrl}/overview`;

  return (
    <Switch>
      <Route
        exact={true}
        path={overviewPath}
        render={() => (
          <SourceSessionEnrichmentPage isOn={isOn}>
            <OverviewRoute websiteName={website.name} isOn={isOn} />
          </SourceSessionEnrichmentPage>
        )}
      />
      <Route
        exact={true}
        path={consentModePath}
        render={() => (
          <SourceSessionEnrichmentPage isOn={isOn}>
            <ConsentModeRoute
              initialConsentState={
                eventsConnectorConfig.globalConfig.sessionEnrichmentConsent
              }
            />
          </SourceSessionEnrichmentPage>
        )}
      />
      <Route
        exact={true}
        path={dataManagementPath}
        render={() => (
          <SourceSessionEnrichmentPage isOn={isOn}>
            <DataManagementRoute />
          </SourceSessionEnrichmentPage>
        )}
      />
      <RedirectWithoutLastLocation to={overviewUrl} />
    </Switch>
  );
};

/* ========================================================================== */

type SourceSessionEnrichmentPageProps = {
  isOn: boolean;
  children: React.ReactNode;
};

const SourceSessionEnrichmentPage: React.FC<
  SourceSessionEnrichmentPageProps
> = ({ isOn, children }) => {
  const theme = useTheme();
  const match = useRouteMatch();
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();

  const isTabActive = (path: string) => matchPath(match.url, path) !== null;

  const websiteUrl = `/company/${companyId}/website/${websiteId}`;
  const myTrackingUrl = `${websiteUrl}/my-tracking`;
  const overviewUrl = `${myTrackingUrl}/source-enrich/overview`;
  const consentModeUrl = `${myTrackingUrl}/source-enrich/consent-mode`;
  const dataManagementUrl = `${myTrackingUrl}/source-enrich/data-management`;

  return (
    <PageWrapper>
      <PageBackLink to={myTrackingUrl} />
      <PageHeader>
        <div>
          <div>
            <IconEnrich size="24px" />
          </div>
          <div>Session Enrichment</div>
        </div>
        <div>
          {isOn ? (
            <Status
              textColor={theme.palette.white}
              backgroundColor={theme.palette.green}
              icon={<IconCheckMark size="16px" />}
              text="On"
            />
          ) : (
            <Status
              textColor={theme.palette.white}
              backgroundColor={theme.palette.grey5}
              icon={<IconCircledPause size="16px" />}
              text="Off"
            />
          )}
        </div>
      </PageHeader>
      <TabsWrapper>
        <Tabs
          items={[
            {
              title: "Overview",
              isActive: isTabActive(overviewPath),
              type: "INTERNAL_LINK",
              to: overviewUrl
            },
            {
              title: "Consent Mode",
              isActive: isTabActive(consentModePath),
              type: "INTERNAL_LINK",
              to: consentModeUrl
            },
            {
              title: "Data Management",
              isActive: isTabActive(dataManagementPath),
              type: "INTERNAL_LINK",
              to: dataManagementUrl
            }
          ]}
        />
      </TabsWrapper>
      <PageContent>
        <div>{children}</div>
        <div>
          <InfoPageCard>
            <div>Identify Users Without Relying on Cookies</div>
            <div>
              <div>
                <div>
                  <IconFacebook size="24px" />
                </div>
                <div>20% Increase in Meta Performance</div>
              </div>
              <div>
                Meta can't optimize for what it can't see. It's essential to
                send 100% of conversion events and provide the most
                comprehensive user data to maximize match quality scores. Elevar
                delivers on both fronts.
              </div>
            </div>
            <div>
              <div>
                <div>
                  <IconKlaviyo size="24px" />
                </div>
                <div>Send 3x More Abandoned Cart Emails</div>
              </div>
              <div>
                Leveraging Elevar's Session Enrichment, our customers
                consistently experience a 50% or greater increase in product
                view and add-to-cart events. This results in more abandoned flow
                emails being sent and a direct boost in ROI from your Elevar
                integration.
              </div>
            </div>
            <StyledLinkExternal
              href="https://www.getelevar.com/how-to/improve-klaviyo-abandoned-flow-performance/"
              text="Learn More About Session Enrichment"
            />
          </InfoPageCard>
        </div>
      </PageContent>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  padding-top: ${props => props.theme.gridBase * 3.5}px;
  padding-bottom: ${props => props.theme.gridBase * 4}px;
  padding-left: ${props => props.theme.gridBase * 4}px;
  padding-right: ${props => props.theme.gridBase * 4}px;
`;

const PageBackLink = styled(BackLink)`
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.gridBase * 2.75}px;

  > div:first-child {
    display: flex;
    align-items: center;
    gap: ${props => props.theme.gridBase * 1.5}px;

    > div:first-child {
      color: ${props => props.theme.palette.purple2};
    }

    > div:last-child {
      ${heading2Styles};
    }
  }
`;

const TabsWrapper = styled.div`
  margin-left: ${props => props.theme.gridBase * -2}px;
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const PageContent = styled.div`
  display: grid;
  column-gap: ${props => props.theme.gridBase * 3}px;
  grid-template-rows: auto auto;
  grid-template-columns: 2fr minmax(
      ${props => props.theme.gridBase * 56.5}px,
      1fr
    );
`;

const InfoPageCard = styled(PageCard)`
  height: max-content;
  padding-top: ${props => props.theme.gridBase * 4}px;
  padding-bottom: ${props => props.theme.gridBase * 4}px;

  > div:first-child {
    ${heading3Styles};
    margin-bottom: ${props => props.theme.gridBase * 4}px;
  }

  > div:not(:first-child) {
    ${normalBodyStyles};
    margin-bottom: ${props => props.theme.gridBase * 4}px;

    > div:first-child {
      display: flex;
      gap: ${props => props.theme.gridBase * 1.5}px;
      margin-bottom: ${props => props.theme.gridBase * 1.5}px;

      > div:first-child {
        display: flex;
      }

      > div:last-child {
        font-weight: 500;
      }
    }

    > div:last-child {
      ${normalBodyStyles};
      color: ${props => props.theme.palette.grey2};
    }
  }
`;

/* ========================================================================== */

type OverviewRouteProps = {
  websiteName: string;
  isOn: boolean;
};

const OverviewRoute: React.FC<OverviewRouteProps> = ({ websiteName, isOn }) => {
  const { mutateAsync: globalConfigMutation } = useGlobalConfigMutation();

  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <>
      <OverviewPageCard>
        <div>
          <div>Overview</div>
          <div>
            Boost your marketing performance with Elevar's Session Enrichment.
            Empower data-driven campaigns by recognizing returning users &
            enhancing data sent to Facebook CAPI, Klaviyo, Google Ads, and more!
          </div>
        </div>
        <div>
          <div>Settings</div>
          <button
            onClick={async () => {
              if (isOn) {
                setIsModalVisible(true);
              } else {
                await globalConfigMutation({ sessionEnrichmentEnabled: true });
              }
            }}
          >
            <Toggle isOn={isOn} />
            <div>Enrich shopper sessions with extra data</div>
          </button>
        </div>
      </OverviewPageCard>
      <ActionWarningModal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        isLoading={false}
        subheading={websiteName}
        heading="Disable Session Enrichment"
        text="By disabling enrichment, you accept the following:"
        checkBoxItems={[
          "This will reduce the quality of my data",
          "This will delete historical stored enrichment data",
          "This will negatively impact my destinations' performance"
        ]}
        confirmActionText="Turn Off"
        onConfirmAction={async () => {
          setIsModalVisible(false);
          await globalConfigMutation({ sessionEnrichmentEnabled: false });
        }}
        cancelActionText="Keep On"
      />
    </>
  );
};

const OverviewPageCard = styled(PageCard)`
  height: max-content;

  > div:first-child {
    margin-bottom: ${props => props.theme.gridBase * 2.5}px;
    border-bottom: 1px solid ${props => props.theme.palette.grey7};
    padding-bottom: ${props => props.theme.gridBase * 2.5}px;

    > div:first-child {
      ${heading3Styles};
      margin-bottom: ${props => props.theme.gridBase}px;
    }

    > div:last-child {
      ${normalBodyStyles};
      color: ${props => props.theme.palette.grey2};
    }
  }

  > div:last-child {
    > div {
      ${heading3Styles};
      margin-bottom: ${props => props.theme.gridBase * 2}px;
    }

    > button {
      ${normalBodyStyles};
      display: flex;
      gap: ${props => props.theme.gridBase * 1.5}px;
      color: ${props => props.theme.palette.grey2};
    }
  }
`;

/* ========================================================================== */

type ConsentModeRouteLocationState =
  | { isFromOnboardingQuestion?: boolean }
  | undefined;

type ConsentModeRouteProps = {
  initialConsentState: ConsentModeConfig;
};

const ConsentModeRoute: React.FC<ConsentModeRouteProps> = ({
  initialConsentState
}) => {
  const location = useLocation<ConsentModeRouteLocationState>();
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();

  const { mutateAsync: globalConfigMutation } = useGlobalConfigMutation();

  const [isLoading, setIsLoading] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const isFromOnboardingQuestion =
    location.state?.isFromOnboardingQuestion ?? false;

  const websiteUrl = `/company/${companyId}/website/${websiteId}`;
  const myTrackingUrl = `${websiteUrl}/my-tracking`;

  return (
    <>
      <ConsentModeReader
        details={{ type: "SESSION_ENRICHMENT" }}
        isLoading={isLoading}
        initial={{
          ...initialConsentState,
          ...(isFromOnboardingQuestion ? { enabled: true } : {})
        }}
        onSave={async data => {
          setIsLoading(true);
          await globalConfigMutation({
            sessionEnrichmentConsent: data.consentMode
          });
          toast.success("Source updated");

          if (isFromOnboardingQuestion) {
            setShouldRedirect(true);
          } else {
            setIsLoading(false);
          }
        }}
      />
      {shouldRedirect ? (
        <Redirect to={myTrackingUrl} />
      ) : isFromOnboardingQuestion ? (
        <Prompt
          message={
            "You made some changes that are unsaved. " +
            "Are you sure you want to navigate away?"
          }
        />
      ) : null}
    </>
  );
};

/* ========================================================================== */

type DataManagementMode = "EMAIL" | "PHONE";

const option1 = { name: "By Email", value: "EMAIL" } as const;
const option2 = { name: "By Phone Number", value: "PHONE" } as const;

const DataManagementRoute: React.FC = () => {
  const theme = useTheme();

  const { mutateAsync: redactCustomerMutation } = useRedactCustomerMutation();

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState(emptyStringCleaveValues);
  const [mode, setMode] = useState<DataManagementMode>("EMAIL");
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isEmailValid = mode !== "EMAIL" || isEmail(email);
  const isPhoneValid = mode !== "PHONE" || phone.prettyValue.length > 1;

  return (
    <DataManagementPageCard>
      <div>
        <div>Data Management</div>
        <div>Manage what user data lives on Elevar's servers.</div>
      </div>
      <div>
        <div>
          <Option
            variant="SMALL"
            disabled={isLoading}
            firstOption={option1}
            secondOption={option2}
            activeOption={mode === option1.value ? option1 : option2}
            setActiveOption={option => {
              setErrorOccurred(false);
              setMode(option.value);
            }}
          />
        </div>
        <div>
          {mode === "EMAIL" ? (
            <InputFieldText
              variant="SMALL"
              disabled={isLoading}
              error={errorOccurred}
              value={email}
              onChange={event => setEmail(event.target.value)}
              placeholder="email@address.com"
              spellCheck={false}
              autoCapitalize="off"
            />
          ) : (
            <InputFieldPhone
              variant="SMALL"
              disabled={isLoading}
              error={errorOccurred}
              value={phone.rawValue}
              onChange={event => {
                setPhone({
                  prettyValue: event.target.value,
                  rawValue: event.target.rawValue
                });
              }}
            />
          )}
        </div>
        <div>
          <Tooltip
            placement="right"
            text={
              !isEmailValid
                ? "Please provide a valid email above"
                : !isPhoneValid
                  ? "Please provide a valid phone number above"
                  : ""
            }
            disabled={isEmailValid && isPhoneValid}
          >
            <DataManagementTooltipInner>
              <ButtonPrimaryWithIcon
                variant="SMALL"
                state={
                  isLoading
                    ? "LOADING"
                    : isEmailValid && isPhoneValid
                      ? "IDLE"
                      : "DISABLED"
                }
                onClick={async () => {
                  setIsLoading(true);
                  setErrorOccurred(false);

                  try {
                    await redactCustomerMutation(
                      mode === "EMAIL"
                        ? { type: "email", value: email }
                        : { type: "phone", value: phone.prettyValue }
                    );

                    if (mode === "EMAIL") {
                      setEmail("");
                    } else {
                      setPhone(emptyStringCleaveValues);
                    }

                    toast.success(
                      "All data associated with this user has been deleted"
                    );
                  } catch {
                    setErrorOccurred(true);
                    toast.errorExpected(
                      "The email / phone number entered might not be valid"
                    );
                  } finally {
                    setIsLoading(false);
                  }
                }}
              >
                <IconTrash size="16px" color={theme.palette.white} />
                <span>Delete Records</span>
              </ButtonPrimaryWithIcon>
            </DataManagementTooltipInner>
          </Tooltip>
        </div>
      </div>
    </DataManagementPageCard>
  );
};

const DataManagementPageCard = styled(PageCard)`
  > div:first-child {
    margin-bottom: ${props => props.theme.gridBase * 2}px;

    > div:first-child {
      ${heading3Styles};
      margin-bottom: ${props => props.theme.gridBase}px;
    }

    > div:last-child {
      ${normalBodyStyles};
      color: ${props => props.theme.palette.grey2};
    }
  }

  > div:last-child {
    max-width: ${props => props.theme.gridBase * 52.5}px;

    > div:not(:last-child) {
      margin-bottom: ${props => props.theme.gridBase * 2}px;
    }
  }
`;

const DataManagementTooltipInner = styled.span`
  display: inline-block;
`;

const ButtonPrimaryWithIcon = styled(ButtonPrimary)`
  display: flex;
  align-items: center;

  > span {
    margin-left: ${props => props.theme.gridBase}px;
  }
`;
