import dayjs from "dayjs";
import { saveAs } from "file-saver";
import { produce } from "immer";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled, { useTheme } from "styled-components";

import {
  type Container as GtmContainer,
  type TemplateParameter as GtmTemplateParameter
} from "elevar-common-ts/src/gtmTypes";

import {
  ButtonPrimary,
  ButtonSecondary
} from "elevar-design-system/src/buttons/ButtonVariants";
import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import {
  IconCircledInfo,
  IconDownloadCloud,
  IconServer,
  IconSuccessCloud,
  IconWeb
} from "elevar-design-system/src/icons";
import { InputFieldText } from "elevar-design-system/src/inputs/InputFieldText";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import {
  LinkExternal,
  StyledLinkExternal
} from "elevar-design-system/src/links/LinkExternal";
import { linkStyles } from "elevar-design-system/src/links/links";
import { scrollbarMixin } from "elevar-design-system/src/scrollbar";
import { Spinner } from "elevar-design-system/src/Spinner";
import { richTextStyles } from "elevar-design-system/src/typography/richText";
import {
  heading2Styles,
  heading3Styles,
  normalBodyStyles,
  normalTextStyles,
  smallTextStyles
} from "elevar-design-system/src/typography/typography";

import { useCloudStorageFileQuery } from "../../api/handlers/cloudStorage";
import { type ContainerDetails } from "../../api/handlers/containerDetails";
import { BackLink } from "../../components/BackLink";
import { PageCard } from "../../components/PageCard";
import { VideoPlayer } from "../../components/VideoPlayer";
import { useCompanyId, useWebsiteId } from "../../utils/idHooks";
import { track } from "../../utils/track";
import { ContainerTypeIcon } from "./ContainerTypeIcon";

import langleyImageSrc from "../../images/avatar-langley.webp";
import marioImageSrc from "../../images/avatar-mario.webp";

/* ========================================================================== */

type PreBuiltTagsItemProps = {
  containerDetails: ContainerDetails;
};

export const PreBuiltTagsItem: React.FC<PreBuiltTagsItemProps> = ({
  containerDetails
}) => {
  const cloudStorageFile = useCloudStorageFileQuery<Partial<GtmContainer>>(
    containerDetails.containerJson
  );

  if (cloudStorageFile.error) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  if (cloudStorageFile.data === undefined) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  return (
    <PreBuiltTagsItemInner
      containerDetails={containerDetails}
      containerContents={cloudStorageFile.data}
    />
  );
};

const CenteredWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* ========================================================================== */

type TabState = "TAGS" | "TRIGGERS" | "VARIABLES" | "TEMPLATES";

type Parameter = {
  details: ContainerDetails["parameters"][number];
  state: string;
};

type PreBuiltTagsItemInnerProps = {
  containerDetails: ContainerDetails;
  containerContents: Partial<GtmContainer>;
};

const PreBuiltTagsItemInner: React.FC<PreBuiltTagsItemInnerProps> = ({
  containerDetails,
  containerContents
}) => {
  const theme = useTheme();
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();

  const [currentTab, setCurrentTab] = useState<TabState>("TAGS");
  const [isDownloaded, setIsDownloaded] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const [parameters, setParameters] = useState(() => {
    return containerDetails.parameters.map<Parameter>(parameter => ({
      details: parameter,
      state: ""
    }));
  });

  useEffect(() => {
    track.preBuiltTagsItemView(containerDetails);
  }, [containerDetails]);

  const includesDataRaw = containerContents.containerVersion;

  const includesData = {
    tags: includesDataRaw?.tag?.map(i => i.name) ?? [],
    triggers: includesDataRaw?.trigger?.map(i => i.name) ?? [],
    variables: includesDataRaw?.variable?.map(i => i.name) ?? [],
    templates: includesDataRaw?.customTemplate?.map(i => i.name) ?? []
  };

  const getIncludesDataForCurrentTab = () => {
    switch (currentTab) {
      case "TAGS":
        return includesData.tags;
      case "TRIGGERS":
        return includesData.triggers;
      case "VARIABLES":
        return includesData.variables;
      case "TEMPLATES":
        return includesData.templates;
    }
  };

  const websiteUrl = `/company/${companyId}/website/${websiteId}`;
  const supportUrl = `${websiteUrl}/support`;
  const includesDataForCurrentTab = getIncludesDataForCurrentTab();

  return (
    <PageWrapper>
      <PageHeader>
        <PageBackLink to={`${websiteUrl}/pre-built-tags`} />
        <PageHeaderInner>
          <PageIcon>
            <ContainerTypeIcon type={containerDetails.typeIcon} />
          </PageIcon>
          <PageHeading>{containerDetails.name}</PageHeading>
        </PageHeaderInner>
      </PageHeader>
      <PageContent>
        <PageCard>
          <AboutHeading>About Container</AboutHeading>
          <AboutDescription
            dangerouslySetInnerHTML={{ __html: containerDetails.description }}
          />
          <AboutInfoWrapper>
            <div>
              <AboutInfoHeading>GTM Container Type</AboutInfoHeading>
              <AboutInfoValue>
                {containerDetails.containerType === "WEB" && (
                  <>
                    <div>
                      <IconWeb size="16px" />
                    </div>
                    <div>Web</div>
                  </>
                )}
                {containerDetails.containerType === "SS" && (
                  <>
                    <div>
                      <IconServer size="16px" />
                    </div>
                    <div>Server-Side</div>
                  </>
                )}
              </AboutInfoValue>
            </div>
            <div>
              <AboutInfoHeading>Last Updated</AboutInfoHeading>
              <AboutInfoValue>
                {dayjs(containerDetails.updatedAt).format("MMM DD YYYY")}
              </AboutInfoValue>
            </div>
            <div>
              <AboutInfoHeading>Last Downloaded</AboutInfoHeading>
              <AboutInfoValue>
                {containerDetails.lastDownloadedAt
                  ? dayjs(containerDetails.lastDownloadedAt).format(
                      "MMM DD YYYY"
                    )
                  : "Never"}
              </AboutInfoValue>
            </div>
          </AboutInfoWrapper>
          <AboutIncludesHeading>Includes</AboutIncludesHeading>
          <AboutIncludesWrapper>
            <div>
              <AboutIncludesButtonsWrapper>
                <AboutIncludesButton
                  isActive={currentTab === "TAGS"}
                  onClick={() => {
                    setCurrentTab("TAGS");
                    track.tabChange({
                      location: "preBuiltTags.item",
                      tabName: "Tags"
                    });
                  }}
                >
                  <div>Tags</div>
                  <div>{includesData.tags.length}</div>
                </AboutIncludesButton>
                <AboutIncludesButton
                  isActive={currentTab === "TRIGGERS"}
                  onClick={() => {
                    setCurrentTab("TRIGGERS");
                    track.tabChange({
                      location: "preBuiltTags.item",
                      tabName: "Triggers"
                    });
                  }}
                >
                  <div>Triggers</div>
                  <div>{includesData.triggers.length}</div>
                </AboutIncludesButton>
                <AboutIncludesButton
                  isActive={currentTab === "VARIABLES"}
                  onClick={() => {
                    setCurrentTab("VARIABLES");
                    track.tabChange({
                      location: "preBuiltTags.item",
                      tabName: "Variables"
                    });
                  }}
                >
                  <div>Variables</div>
                  <div>{includesData.variables.length}</div>
                </AboutIncludesButton>
                <AboutIncludesButton
                  isActive={currentTab === "TEMPLATES"}
                  onClick={() => {
                    setCurrentTab("TEMPLATES");
                    track.tabChange({
                      location: "preBuiltTags.item",
                      tabName: "Templates"
                    });
                  }}
                >
                  <div>Templates</div>
                  <div>{includesData.templates.length}</div>
                </AboutIncludesButton>
              </AboutIncludesButtonsWrapper>
            </div>
            <div>
              <AboutIncludesTabContents key={currentTab}>
                <div>
                  <div>
                    {includesDataForCurrentTab.map((item, index) => (
                      <AboutIncludesTabContentsItem key={index}>
                        <div>{item}</div>
                      </AboutIncludesTabContentsItem>
                    ))}
                  </div>
                </div>
              </AboutIncludesTabContents>
            </div>
          </AboutIncludesWrapper>
          <AboutSecondaryDescription>
            <div>
              <IconCircledInfo size="16px" />
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: containerDetails.secondaryDescription
              }}
            />
          </AboutSecondaryDescription>
        </PageCard>
        <DownloadContainerPageCard>
          <DownloadContainerMainContent>
            {isDownloaded ? (
              <>
                <DownloadContainerHeader>
                  <div>
                    <DownloadContainerHeaderIconWrapper>
                      <IconSuccessCloud
                        size="24px"
                        color={theme.palette.green}
                      />
                    </DownloadContainerHeaderIconWrapper>
                    <DownloadContainerHeading>
                      Download Successful
                    </DownloadContainerHeading>
                  </div>
                  {containerDetails.setupGuideLink ? (
                    <div>
                      <StyledLinkExternal
                        href={containerDetails.setupGuideLink}
                        text="Setup Guide"
                      />
                    </div>
                  ) : null}
                </DownloadContainerHeader>
                <DownloadContainerExplainer>
                  Here's a video on how to Import and Publish your container in
                  GTM.
                </DownloadContainerExplainer>
                <DownloadContainerVideoPlayer url="https://player.vimeo.com/video/513197028" />
                <DownloadContainerContactWrapper>
                  <DownloadContainerContactImages>
                    <div>
                      <img
                        src={langleyImageSrc}
                        alt="support rep avatar"
                        width={theme.gridBase * 4}
                        height={theme.gridBase * 4}
                      />
                    </div>
                    <div>
                      <img
                        src={marioImageSrc}
                        alt="support rep avatar"
                        width={theme.gridBase * 4}
                        height={theme.gridBase * 4}
                      />
                    </div>
                  </DownloadContainerContactImages>
                  <DownloadContainerContactText>
                    Need Help?&nbsp;{" "}
                    <Link to={supportUrl}>Visit your Support page</Link>
                  </DownloadContainerContactText>
                </DownloadContainerContactWrapper>
                <ButtonSecondary
                  variant="SMALL"
                  onClick={() => {
                    setIsDownloaded(false);
                    track.preBuiltTagsItemDownloadAgain(containerDetails);
                  }}
                >
                  Download Again
                </ButtonSecondary>
              </>
            ) : (
              <>
                <DownloadContainerHeader>
                  <div>
                    <DownloadContainerHeaderIconWrapper>
                      <IconDownloadCloud
                        size="24px"
                        color={theme.palette.purple2}
                      />
                    </DownloadContainerHeaderIconWrapper>
                    <DownloadContainerHeading>
                      Download Container
                    </DownloadContainerHeading>
                  </div>
                  {containerDetails.setupGuideLink ? (
                    <div>
                      <StyledLinkExternal
                        href={containerDetails.setupGuideLink}
                        text="Setup Guide"
                      />
                    </div>
                  ) : null}
                </DownloadContainerHeader>
                {parameters.length > 0 ? (
                  <>
                    <DownloadContainerExplainer>
                      You can optionally fill in the inputs below to get an
                      install ready version of this container.
                      {containerDetails.parameterHelpLink ? (
                        <LinkExternal href={containerDetails.parameterHelpLink}>
                          Where can I find these values?
                        </LinkExternal>
                      ) : null}
                    </DownloadContainerExplainer>
                    <div>
                      {parameters.map((parameter, index) => (
                        <DownloadContainerInputWrapper
                          key={index}
                          labelText={parameter.details.inputLabel}
                          tooltip={{
                            maxWidth: `${theme.gridBase * 37.5}px`,
                            render: () => (
                              <DownloadContainerTooltipContent
                                dangerouslySetInnerHTML={{
                                  __html: parameter.details.tooltipContents
                                }}
                              />
                            )
                          }}
                          disabled={isDownloading}
                        >
                          <InputFieldText
                            variant="SMALL"
                            disabled={isDownloading}
                            value={parameter.state}
                            onChange={event => {
                              setParameters(
                                produce(parameters, draft => {
                                  const item = draft.find(
                                    i =>
                                      i.details.variableName ===
                                      parameter.details.variableName
                                  );

                                  if (item) {
                                    item.state = event.target.value;
                                  }
                                })
                              );
                            }}
                            placeholder={parameter.details.inputPlaceholder}
                            spellCheck={false}
                            autoCapitalize="off"
                          />
                        </DownloadContainerInputWrapper>
                      ))}
                    </div>
                  </>
                ) : null}
                <DownloadContainerButton
                  variant="SMALL"
                  state={isDownloading ? "LOADING" : "IDLE"}
                  onClick={() => {
                    setIsDownloading(true);

                    const filteredParameters = parameters.filter(
                      parameter => parameter.state !== ""
                    );

                    const newContainerContents = produce(
                      containerContents,
                      draft => {
                        const variables = draft.containerVersion?.variable;

                        if (variables) {
                          filteredParameters.forEach(parameter => {
                            const variable = variables.find(
                              v => v.name === parameter.details.variableName
                            );

                            if (variable?.type === "c") {
                              const variableParameter =
                                variable.parameter?.find(
                                  (p): p is GtmTemplateParameter =>
                                    p.type === "TEMPLATE" && p.key === "value"
                                );

                              if (variableParameter) {
                                variableParameter.value = parameter.state;
                              }
                            }
                          });
                        }
                      }
                    );

                    const blob = new Blob(
                      [JSON.stringify(newContainerContents)],
                      { type: "application/json" }
                    );

                    const time = dayjs().format("YYYY-MM-DD_HH-mm-ss");
                    saveAs(blob, `${containerDetails.name}_${time}.json`);

                    setIsDownloaded(true);
                    setIsDownloading(false);

                    track.preBuiltTagsItemDownload(containerDetails);

                    if (filteredParameters.length > 0) {
                      track.preBuiltTagsItemDownloadWithParameters(
                        containerDetails
                      );
                    }
                  }}
                >
                  Download Container
                </DownloadContainerButton>
              </>
            )}
          </DownloadContainerMainContent>
        </DownloadContainerPageCard>
      </PageContent>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  padding-top: ${props => props.theme.gridBase * 3.5}px;
  padding-bottom: ${props => props.theme.gridBase * 4}px;
  padding-left: ${props => props.theme.gridBase * 4}px;
  padding-right: ${props => props.theme.gridBase * 4}px;
`;

const PageHeader = styled.div`
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const PageBackLink = styled(BackLink)`
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const PageHeaderInner = styled.div`
  display: flex;
  align-items: center;
`;

const PageIcon = styled.div`
  display: flex;
  margin-right: ${props => props.theme.gridBase * 1.5}px;
`;

const PageHeading = styled.div`
  ${heading2Styles};
`;

const PageContent = styled.div`
  display: grid;
  gap: ${props => props.theme.gridBase * 4}px;
  grid-template-columns:
    minmax(${props => props.theme.gridBase * 64}px, auto)
    minmax(
      ${props => props.theme.gridBase * 37.5}px,
      ${props => props.theme.gridBase * 50}px
    );
`;

const AboutHeading = styled.div`
  ${heading3Styles};
  margin-bottom: ${props => props.theme.gridBase * 0.5}px;
`;

const AboutDescription = styled.div`
  ${normalBodyStyles};
  ${richTextStyles};
  color: ${props => props.theme.palette.grey3};
  margin-bottom: ${props => props.theme.gridBase * 4}px;
`;

const AboutInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: ${props => props.theme.gridBase * 4}px;
`;

const AboutInfoHeading = styled.div`
  ${normalTextStyles};
  font-weight: 500;
  margin-bottom: ${props => props.theme.gridBase * 0.5}px;
`;

const AboutInfoValue = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  display: flex;
  align-items: center;

  > :not(:last-child) {
    display: flex;
  }

  > :not(:first-child) {
    margin-left: ${props => props.theme.gridBase}px;
  }
`;

const AboutIncludesHeading = styled.div`
  ${heading3Styles};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const AboutIncludesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const AboutIncludesButtonsWrapper = styled.div`
  margin-left: ${props => props.theme.gridBase * -1.5}px;
  padding-right: ${props => props.theme.gridBase * 1.5}px;
`;

type AboutIncludesButtonProps = {
  isActive: boolean;
};

const AboutIncludesButton = styled.button<AboutIncludesButtonProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 2px;
  padding-top: ${props => props.theme.gridBase}px;
  padding-bottom: ${props => props.theme.gridBase}px;
  padding-left: ${props => props.theme.gridBase * 1.5}px;
  padding-right: ${props => props.theme.gridBase}px;
  background-color: ${props =>
    props.isActive ? props.theme.palette.grey8 : props.theme.palette.white};
  transition: background-color ${props => props.theme.other.transition};

  &:hover {
    background-color: ${props => props.theme.palette.grey8};
  }

  &:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase}px;
  }

  > div:first-child {
    ${normalTextStyles};
    font-weight: 500;
    color: ${props =>
      props.isActive ? props.theme.palette.blue1 : props.theme.palette.grey4};
    transition: color ${props => props.theme.other.transition};
  }

  > div:last-child {
    ${smallTextStyles};
    font-size: ${props => props.theme.fontBase * 0.625}px;
    line-height: ${props => props.theme.gridBase * 2}px;
    width: ${props => props.theme.gridBase * 2.5}px;
    height: ${props => props.theme.gridBase * 2.5}px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: ${props => props.theme.palette.white};
    background-color: ${props =>
      props.isActive ? props.theme.palette.blue1 : props.theme.palette.grey6};
    transition: background-color ${props => props.theme.other.transition};
  }
`;

const AboutIncludesTabContents = styled.div`
  border: 1px solid ${props => props.theme.palette.grey7};
  border-radius: 4px;

  > div {
    ${scrollbarMixin};
    overflow: auto;
    height: ${props => props.theme.gridBase * 35.5}px;
    margin-right: ${props => props.theme.gridBase}px;

    > div {
      padding: ${props => props.theme.gridBase * 3}px;
    }
  }
`;

const AboutIncludesTabContentsItem = styled.div`
  &:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 2}px;
  }

  > div {
    ${normalTextStyles};
    color: ${props => props.theme.palette.grey2};
    display: flex;

    &::before {
      content: "";
      display: block;
      border-radius: 50%;
      width: ${props => props.theme.gridBase * 0.5}px;
      height: ${props => props.theme.gridBase * 0.5}px;
      background-color: ${props => props.theme.palette.grey2};
      margin-top: ${props => props.theme.gridBase}px;
      margin-right: ${props => props.theme.gridBase * 1.5}px;
    }
  }
`;

const AboutSecondaryDescription = styled.div`
  display: inline-flex;
  background-color: ${props => props.theme.palette.grey8};
  padding-top: ${props => props.theme.gridBase}px;
  padding-bottom: ${props => props.theme.gridBase}px;
  padding-left: ${props => props.theme.gridBase * 1.25}px;
  padding-right: ${props => props.theme.gridBase * 2}px;
  border-radius: 4px;

  > div:first-child {
    display: flex;
    color: ${props => props.theme.palette.grey3};
    margin-top: ${props => props.theme.gridBase * 0.25}px;
    margin-right: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    ${smallTextStyles};
    ${richTextStyles};
    line-height: ${props => props.theme.gridBase * 2.5}px;
  }
`;

const DownloadContainerPageCard = styled(PageCard)`
  padding: 0;
  height: max-content;
  overflow: hidden;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: ${props => props.theme.gridBase}px;
    background: ${props => props.theme.other.gradientRight};
  }
`;

const DownloadContainerMainContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.gridBase * 3}px;
`;

const DownloadContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${props => props.theme.gridBase * 2}px;
  border-bottom: 1px solid ${props => props.theme.palette.grey6};
  margin-bottom: ${props => props.theme.gridBase * 2}px;

  > div {
    display: flex;
  }

  @media screen and (max-width: 1360px) {
    display: block;

    > div:not(:last-child) {
      margin-bottom: ${props => props.theme.gridBase}px;
    }
  }
`;

const DownloadContainerHeaderIconWrapper = styled.div`
  display: flex;
  margin-right: ${props => props.theme.gridBase * 1.5}px;
`;

const DownloadContainerHeading = styled.div`
  ${heading3Styles};
`;

const DownloadContainerExplainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};

  > a {
    ${linkStyles};
    display: block;
    width: max-content;
    margin-top: ${props => props.theme.gridBase * 0.5}px;
  }
`;

const DownloadContainerVideoPlayer = styled(VideoPlayer)`
  margin-top: ${props => props.theme.gridBase * 2}px;
  margin-bottom: ${props => props.theme.gridBase * 2.5}px;
`;

const DownloadContainerContactWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.gridBase * 2.5}px;
`;

const DownloadContainerContactImages = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-right: ${props => props.theme.gridBase * 2}px;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${props => props.theme.gridBase * 4.5}px;
    height: ${props => props.theme.gridBase * 4.5}px;

    > img {
      border-radius: 50%;
      filter: drop-shadow(2px 2px 8px rgba(33, 43, 54, 0.16));
    }

    &:not(:last-child) {
      margin-left: ${props => props.theme.gridBase * -1}px;
    }
  }

  @media screen and (max-width: 1360px) {
    display: none;
  }
`;

const DownloadContainerContactText = styled.div`
  ${normalBodyStyles};

  > a {
    ${linkStyles};
  }
`;

const DownloadContainerInputWrapper = styled(InputWrapper)`
  &:first-child {
    margin-top: ${props => props.theme.gridBase * 3}px;
  }

  &:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 3}px;
  }

  &:last-child {
    margin-bottom: ${props => props.theme.gridBase * 2}px;
  }
`;

const DownloadContainerTooltipContent = styled.div`
  ${normalBodyStyles};
  ${richTextStyles};
  color: ${props => props.theme.palette.grey2};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;
`;

const DownloadContainerButton = styled(ButtonPrimary)`
  margin-top: ${props => props.theme.gridBase}px;
`;
