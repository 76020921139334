import {
  IconApi,
  IconAppLovin,
  IconAttentive,
  IconAwin,
  IconCJAffiliate,
  IconCriteo,
  IconEnrich,
  IconFacebook,
  IconGA,
  IconGoogleAds,
  IconGoogleCloud,
  IconImpact,
  IconIterable,
  IconKlaviyo,
  IconMicrosoftAds,
  IconMixpanel,
  IconOmetria,
  IconOutbrain,
  IconPartnerize,
  IconPepperjam,
  IconPinterest,
  IconPostscript,
  IconRakuten,
  IconReddit,
  IconRtbHouse,
  IconSendlane,
  IconShareASale,
  IconShopify,
  IconSmartly,
  IconSnapchat,
  IconTaboola,
  IconTikTok,
  IconTwitter,
  IconVoluum,
  IconWeb,
  IconYotpo
} from "elevar-design-system/src/icons";

/* ========================================================================== */

export const sourceAgnostic = {
  name: "API",
  state: "BETA",
  shorthand: "agnostic",
  icon: IconApi,
  stepCount: 4,
  configKey: "agnosticSource"
} as const;

export const sourceShopify = {
  name: "Shopify",
  state: "STABLE",
  shorthand: "shopify",
  icon: IconShopify,
  stepCount: 6,
  configKey: "shopifySource"
} as const;

export const sourceCustomPages = {
  name: "Non-Shopify Subdomains",
  state: "STABLE",
  shorthand: "custom-pages",
  icon: IconWeb,
  stepCount: 2,
  configKey: "shopifyCustomPagesSource"
} as const;

export const sourceEnrich = {
  name: "Session Enrichment",
  state: "STABLE",
  shorthand: "enrich",
  icon: IconEnrich
} as const;

export type Source =
  | typeof sourceAgnostic
  | typeof sourceShopify
  | typeof sourceCustomPages;

/* ========================================================================== */

const normalLimit = 5;

export const destinationGa4 = {
  name: "GA4",
  state: "STABLE",
  shorthand: "ga4",
  icon: IconGA,
  stepCount: 8,
  configKey: "ga4",
  limit: normalLimit,
  showInSummaryDrawer: true
} as const;

export const destinationFb = {
  name: "Facebook",
  state: "STABLE",
  shorthand: "fb",
  icon: IconFacebook,
  stepCount: 8,
  configKey: "facebook",
  limit: normalLimit,
  showInSummaryDrawer: true
} as const;

export const destinationIr = {
  name: "Impact Radius",
  state: "STABLE",
  shorthand: "ir",
  icon: IconImpact,
  stepCount: 6,
  configKey: "impact_radius",
  limit: normalLimit,
  showInSummaryDrawer: true
} as const;

export const destinationTt = {
  name: "TikTok",
  state: "STABLE",
  shorthand: "tt",
  icon: IconTikTok,
  stepCount: 8,
  configKey: "tiktok",
  limit: normalLimit,
  showInSummaryDrawer: true
} as const;

export const destinationSas = {
  name: "ShareASale",
  state: "BETA",
  shorthand: "sas",
  icon: IconShareASale,
  stepCount: 6,
  configKey: "shareasale",
  limit: normalLimit,
  showInSummaryDrawer: true
} as const;

export const destinationVl = {
  name: "Voluum",
  state: "STABLE",
  shorthand: "vl",
  icon: IconVoluum,
  stepCount: 6,
  configKey: "voluum",
  limit: normalLimit,
  showInSummaryDrawer: true
} as const;

export const destinationCrt = {
  name: "Criteo",
  state: "STABLE",
  shorthand: "crt",
  icon: IconCriteo,
  stepCount: 6,
  configKey: "criteo",
  limit: 1,
  showInSummaryDrawer: false
} as const;

export const destinationSnp = {
  name: "Snapchat",
  state: "STABLE",
  shorthand: "snp",
  icon: IconSnapchat,
  stepCount: 8,
  configKey: "snapchat",
  limit: 1,
  showInSummaryDrawer: true
} as const;

export const destinationSmt = {
  name: "Smartly",
  state: "BETA",
  shorthand: "smt",
  icon: IconSmartly,
  stepCount: 4,
  configKey: "smartly",
  limit: normalLimit,
  showInSummaryDrawer: true
} as const;

export const destinationPin = {
  name: "Pinterest",
  state: "STABLE",
  shorthand: "pin",
  icon: IconPinterest,
  stepCount: 8,
  configKey: "pinterest",
  limit: 1,
  showInSummaryDrawer: true
} as const;

export const destinationGads = {
  name: "Google Ads",
  state: "BETA",
  shorthand: "gads",
  icon: IconGoogleAds,
  stepCount: 8,
  configKey: "google_ads",
  limit: normalLimit,
  showInSummaryDrawer: true
} as const;

export const destinationKlvo = {
  name: "Klaviyo",
  state: "STABLE",
  shorthand: "klvo",
  icon: IconKlaviyo,
  stepCount: 5,
  configKey: "klaviyo",
  limit: normalLimit,
  showInSummaryDrawer: true
} as const;

export const destinationPrze = {
  name: "Partnerize",
  state: "BETA",
  shorthand: "prze",
  icon: IconPartnerize,
  stepCount: 6,
  configKey: "partnerize",
  limit: normalLimit,
  showInSummaryDrawer: true
} as const;

export const destinationOutb = {
  name: "Outbrain",
  state: "STABLE",
  shorthand: "outb",
  icon: IconOutbrain,
  stepCount: 5,
  configKey: "outbrain",
  limit: 1,
  showInSummaryDrawer: true
} as const;

export const destinationAwin = {
  name: "Awin",
  state: "STABLE",
  shorthand: "awin",
  icon: IconAwin,
  stepCount: 7,
  configKey: "awin",
  limit: 1,
  showInSummaryDrawer: true
} as const;

export const destinationAttv = {
  name: "Attentive",
  state: "BETA",
  shorthand: "attv",
  icon: IconAttentive,
  stepCount: 5,
  configKey: "attentive",
  limit: normalLimit,
  showInSummaryDrawer: true
} as const;

export const destinationPbsb = {
  name: "Pub/Sub",
  state: "STABLE",
  shorthand: "pbsb",
  icon: IconGoogleCloud,
  stepCount: 5,
  configKey: "pubsub",
  limit: normalLimit,
  showInSummaryDrawer: true
} as const;

export const destinationRktn = {
  name: "Rakuten",
  state: "BETA",
  shorthand: "rktn",
  icon: IconRakuten,
  stepCount: 5,
  configKey: "rakuten",
  limit: normalLimit,
  showInSummaryDrawer: true
} as const;

export const destinationTbla = {
  name: "Taboola",
  state: "STABLE",
  shorthand: "tbla",
  icon: IconTaboola,
  stepCount: 6,
  configKey: "taboola",
  limit: 1,
  showInSummaryDrawer: true
} as const;

export const destinationItrb = {
  name: "Iterable",
  state: "BETA",
  shorthand: "itrb",
  icon: IconIterable,
  stepCount: 5,
  configKey: "iterable",
  limit: normalLimit,
  showInSummaryDrawer: false
} as const;

export const destinationPost = {
  name: "Postscript",
  state: "STABLE",
  shorthand: "post",
  icon: IconPostscript,
  stepCount: 5,
  configKey: "postscript",
  limit: normalLimit,
  showInSummaryDrawer: true
} as const;

export const destinationSndl = {
  name: "Sendlane",
  state: "STABLE",
  shorthand: "sndl",
  icon: IconSendlane,
  stepCount: 5,
  configKey: "sendlane",
  limit: normalLimit,
  showInSummaryDrawer: true
} as const;

export const destinationPpjm = {
  name: "Pepperjam",
  state: "BETA",
  shorthand: "ppjm",
  icon: IconPepperjam,
  stepCount: 6,
  configKey: "pepperjam",
  limit: normalLimit,
  showInSummaryDrawer: true
} as const;

export const destinationBing = {
  name: "Microsoft Advertising",
  state: "STABLE",
  shorthand: "bing",
  icon: IconMicrosoftAds,
  stepCount: 5,
  configKey: "bing",
  limit: normalLimit,
  showInSummaryDrawer: true
} as const;

export const destinationCja = {
  name: "CJ Affiliate",
  state: "STABLE",
  shorthand: "cja",
  icon: IconCJAffiliate,
  stepCount: 6,
  configKey: "cj",
  limit: normalLimit,
  showInSummaryDrawer: true
} as const;

export const destinationMxpl = {
  name: "Mixpanel",
  state: "STABLE",
  shorthand: "mxpl",
  icon: IconMixpanel,
  stepCount: 5,
  configKey: "mixpanel",
  limit: normalLimit,
  showInSummaryDrawer: true
} as const;

export const destinationRddt = {
  name: "Reddit",
  state: "BETA",
  shorthand: "rddt",
  icon: IconReddit,
  stepCount: 7,
  configKey: "reddit",
  limit: normalLimit,
  showInSummaryDrawer: true
} as const;

export const destinationOmet = {
  name: "Ometria",
  state: "BETA",
  shorthand: "omet",
  icon: IconOmetria,
  stepCount: 5,
  configKey: "ometria",
  limit: normalLimit,
  showInSummaryDrawer: false
} as const;

export const destinationYtpo = {
  name: "Yotpo",
  state: "BETA",
  shorthand: "ytpo",
  icon: IconYotpo,
  stepCount: 6,
  configKey: "yotpo",
  limit: 1,
  showInSummaryDrawer: true
} as const;

export const destinationTwtr = {
  name: "X (Twitter)",
  state: "BETA",
  shorthand: "twtr",
  icon: IconTwitter,
  stepCount: 6,
  configKey: "twitter",
  limit: normalLimit,
  showInSummaryDrawer: false
} as const;

export const destinationRtbh = {
  name: "RTB House",
  state: "BETA",
  shorthand: "rtbh",
  icon: IconRtbHouse,
  stepCount: 8,
  configKey: "rtb",
  limit: 1,
  showInSummaryDrawer: false
} as const;

export const destinationAlvn = {
  name: "AppLovin",
  state: "BETA",
  shorthand: "alvn",
  icon: IconAppLovin,
  stepCount: 7,
  configKey: "applovin",
  limit: normalLimit,
  showInSummaryDrawer: false
} as const;

export const destinations = [
  destinationGa4,
  destinationFb,
  destinationIr,
  destinationTt,
  destinationSas,
  destinationVl,
  destinationCrt,
  destinationSnp,
  destinationSmt,
  destinationPin,
  destinationGads,
  destinationKlvo,
  destinationPrze,
  destinationOutb,
  destinationAwin,
  destinationAttv,
  destinationPbsb,
  destinationRktn,
  destinationTbla,
  destinationItrb,
  destinationPost,
  destinationSndl,
  destinationPpjm,
  destinationBing,
  destinationCja,
  destinationMxpl,
  destinationRddt,
  destinationOmet,
  destinationYtpo,
  destinationTwtr,
  destinationRtbh,
  destinationAlvn
] as const;

export type Destination = (typeof destinations)[number];
