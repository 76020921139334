import { useState } from "react";
import styled, { useTheme } from "styled-components";

import { type WebsiteDetails } from "elevar-common-ts/src/apiTypes";

import { iconTextButtonStyles } from "elevar-design-system/src/buttons/buttonStyles";
import {
  ButtonPrimary,
  ButtonPrimaryAsLink,
  ButtonPrimaryAsLinkExternal,
  ButtonSecondary
} from "elevar-design-system/src/buttons/ButtonVariants";
import {
  IconCheckMark,
  IconClipboard,
  IconExternalLink,
  IconKey
} from "elevar-design-system/src/icons";
import { InputFieldText } from "elevar-design-system/src/inputs/InputFieldText";
import { InputFieldTextArea } from "elevar-design-system/src/inputs/InputFieldTextArea";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import { linkStyles } from "elevar-design-system/src/links/links";
import {
  heading3Styles,
  normalBodyStyles
} from "elevar-design-system/src/typography/typography";

import {
  rotateSigningKey,
  useAgnosticAatSnippetQuery,
  useAgnosticServerUrlQuery,
  useAgnosticSourceInstallMutation
} from "../../api/handlers/website";
import { Modal } from "../../components/Modal";
import { PageCard } from "../../components/PageCard";
import {
  type MyTrackingDetailsAgnosticSource,
  useMyTrackingDetails
} from "../../context/MyTrackingDetails";
import { createSetupFlow } from "../../context/SetupFlowDetails";
import { useCompanyId, useWebsiteId } from "../../utils/idHooks";
import { toast } from "../../utils/toast";
import { ConfigSummary } from "./ConfigSummary";
import { ConsentModeWriter } from "./ConsentModeWriter";
import { sourceAgnostic } from "./data";
import { DomainDetails } from "./DomainDetails";

/* ========================================================================== */

type SetupFlowContext = {
  website: WebsiteDetails;
  sourceDetails: MyTrackingDetailsAgnosticSource;
};

const { SetupFlow, useSetupFlowDetails, useSetupFlowContext } =
  createSetupFlow<SetupFlowContext>().source(sourceAgnostic);

type SourceAgnosticProps = {
  website: WebsiteDetails;
  isCompanyAdmin: boolean;
};

export const SourceAgnostic: React.FC<SourceAgnosticProps> = ({
  website,
  isCompanyAdmin
}) => {
  const { sourceInfo } = useMyTrackingDetails();

  return (
    <SetupFlow
      isCompanyAdmin={isCompanyAdmin}
      steps={[
        { details: { type: "CONSENT_MODE" } },
        { details: { type: "CUSTOM", name: "Domain Details" } },
        { details: { type: "CUSTOM", name: "Browser Setup" } },
        { details: { type: "CUSTOM", name: "Server Setup" } }
      ]}
      context={{ website, sourceDetails: sourceInfo.agnostic! }}
    >
      <StepContent />
    </SetupFlow>
  );
};

/* ========================================================================== */

const StepContent: React.FC = () => {
  const setupFlow = useSetupFlowDetails();

  switch (setupFlow.currentStep) {
    case 0:
      return <Step0 />;
    case 1:
      return <Step1 />;
    case 2:
      return <Step2 />;
    case 3:
      return <Step3 />;
    case 4:
      return <Step4 />;
  }
};

/* ========================================================================== */

const Step0: React.FC = () => {
  const { sourceDetails } = useSetupFlowContext();

  if (sourceDetails.state === "SETUP") {
    return <Step0Setup />;
  } else {
    return <Step0NotSetup />;
  }
};

/* -------------------------------------------------------------------------- */

const Step0Setup: React.FC = () => {
  const theme = useTheme();
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();
  const { eventsConnectorConfig } = useMyTrackingDetails();
  const setupFlow = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);
  const [isKeyModalShown, setIsKeyModalShown] = useState(false);

  const apexDomains = eventsConnectorConfig.globalConfig.apex_domains;
  const websiteUrl = `/company/${companyId}/website/${websiteId}`;
  const myTrackingUrl = `${websiteUrl}/my-tracking`;

  return (
    <>
      <PageCard>
        <Step0SetupHeader>
          <div>
            <div>
              <IconCheckMark size="24px" color={theme.palette.green} />
            </div>
            <div>Marked as Complete</div>
          </div>
          <div>You've completed the required steps to set up this source.</div>
        </Step0SetupHeader>
        <Step0SetupMainContent>
          <ConfigSummary
            isLoading={isLoading}
            initialIsOpen={false}
            setCurrentStep={setupFlow.setCurrentStep}
            items={[
              {
                step: 1,
                type: "CONSENT_MODE",
                inEnabled: eventsConnectorConfig.globalConfig.consentModeEnabled
              },
              {
                step: 2,
                type: "CUSTOM",
                render: () =>
                  apexDomains.length === 1 ? (
                    <>
                      Your Overarching Domain is: <span>{apexDomains[0]}</span>
                    </>
                  ) : (
                    <>
                      Your Overarching Domains are:{" "}
                      <span>
                        {apexDomains.slice(0, -1).join(", ")} &{" "}
                        {apexDomains.at(-1)}
                      </span>
                    </>
                  )
              }
            ]}
          />
        </Step0SetupMainContent>
        <Step0SetupFooter>
          <ButtonPrimaryAsLink variant="SMALL" to={myTrackingUrl}>
            Go to My Tracking
          </ButtonPrimaryAsLink>
          <Step0SetupTokenButton onClick={() => setIsKeyModalShown(true)}>
            <div>
              <IconKey size="24px" />
            </div>
            <div>Generate New Signing Key</div>
          </Step0SetupTokenButton>
        </Step0SetupFooter>
      </PageCard>
      <Modal
        isVisible={isKeyModalShown}
        onClose={() => setIsKeyModalShown(false)}
        disallowClose={isLoading}
      >
        <ModalContents>
          <ModalTitle>Generate New Access Signing Key</ModalTitle>
          <ModalBody>
            By continuing, a new key will be created to send your events to
            Elevar, and your old key will be invalidated shortly after. Only do
            this if Elevar's support team has advised you to.
          </ModalBody>
          <ModalButtons>
            <ButtonSecondary
              variant="SMALL"
              state={isLoading ? "DISABLED" : "IDLE"}
              onClick={() => setIsKeyModalShown(false)}
            >
              Go Back
            </ButtonSecondary>
            <ButtonPrimary
              variant="SMALL"
              state={isLoading ? "LOADING" : "IDLE"}
              onClick={async () => {
                setIsLoading(true);
                await rotateSigningKey({ websiteId });
                toast.success("New key generated");
                setIsKeyModalShown(false);
                setIsLoading(false);
              }}
            >
              Generate New Key
            </ButtonPrimary>
          </ModalButtons>
        </ModalContents>
      </Modal>
    </>
  );
};

const Step0SetupHeader = styled.div`
  padding-bottom: ${props => props.theme.gridBase * 2.5}px;
  border-bottom: 1px solid ${props => props.theme.palette.grey7};
  margin-bottom: ${props => props.theme.gridBase * 3}px;

  > div:first-child {
    display: flex;
    gap: ${props => props.theme.gridBase}px;
    margin-bottom: ${props => props.theme.gridBase * 1.5}px;

    > div:last-child {
      ${heading3Styles};
    }
  }

  > div:last-child {
    ${normalBodyStyles};
  }
`;

const Step0SetupMainContent = styled.div`
  padding-bottom: ${props => props.theme.gridBase * 3}px;
  border-bottom: 1px solid ${props => props.theme.palette.grey7};
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const Step0SetupFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Step0SetupTokenButton = styled.button`
  ${iconTextButtonStyles};
`;

const ModalContents = styled.div`
  width: ${props => props.theme.gridBase * 40}px;
  position: relative;
`;

const ModalTitle = styled.div`
  ${heading3Styles};
  text-align: center;
  color: ${props => props.theme.palette.grey1};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const ModalBody = styled.div`
  ${normalBodyStyles};
  text-align: center;
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 3}px;

  > a {
    ${linkStyles};
  }
`;

const ModalButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: ${props => props.theme.gridBase}px;
`;

/* -------------------------------------------------------------------------- */

const Step0NotSetup: React.FC = () => {
  const setupFlow = useSetupFlowDetails();

  const { mutateAsync: agnosticSourceInstallMutation } =
    useAgnosticSourceInstallMutation();

  const [isLoading, setIsLoading] = useState(false);

  return (
    <PageCard>
      <Step0NotSetupHeader>
        <div>Overview</div>
        <div>
          <p>
            Set up this source to share your data with Elevar for server-side
            data enrichment and delivery to your marketing channels.
          </p>
          <p>Note, this requires advanced knowledge of your website's code.</p>
        </div>
      </Step0NotSetupHeader>
      {!setupFlow.isStepCompleted ? (
        <Step0NotSetupButtonWrapper>
          <ButtonPrimary
            variant="SMALL"
            state={isLoading ? "LOADING" : "IDLE"}
            onClick={async () => {
              setIsLoading(true);
              await setupFlow.configMutation({});
              await agnosticSourceInstallMutation();
            }}
          >
            Get Started
          </ButtonPrimary>
        </Step0NotSetupButtonWrapper>
      ) : null}
    </PageCard>
  );
};

const Step0NotSetupHeader = styled.div`
  > div:first-child {
    ${heading3Styles};
    margin-bottom: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    ${normalBodyStyles};
    color: ${props => props.theme.palette.grey2};

    > p:not(:last-child) {
      margin-bottom: ${props => props.theme.gridBase * 0.5}px;
    }
  }
`;

const Step0NotSetupButtonWrapper = styled.div`
  padding-top: ${props => props.theme.gridBase * 3}px;
  border-top: 1px solid ${props => props.theme.palette.grey6};
  margin-top: ${props => props.theme.gridBase * 3}px;
`;

/* ========================================================================== */

const Step1: React.FC = () => {
  const setupFlow = useSetupFlowDetails();
  const { website } = useSetupFlowContext();

  return (
    <ConsentModeWriter
      isStepCompleted={setupFlow.isStepCompleted}
      isMarketsAllowed={website.permissions.includes("MARKETS")}
      isUpgradingOrUpdating={false}
      onSaveAndContinue={() => setupFlow.configMutation({})}
    />
  );
};

/* ========================================================================== */

const Step2: React.FC = () => {
  const { eventsConnectorConfig } = useMyTrackingDetails();
  const setupFlow = useSetupFlowDetails();

  return (
    <DomainDetails
      type="AGNOSTIC"
      isStepCompleted={setupFlow.isStepCompleted}
      initialApexDomains={eventsConnectorConfig.globalConfig.apex_domains}
      onSaveAndContinue={() => setupFlow.configMutation({})}
    />
  );
};

/* ========================================================================== */

const Step3: React.FC = () => {
  const setupFlow = useSetupFlowDetails();

  const agnosticAatSnippet = useAgnosticAatSnippetQuery();

  const [isLoading, setIsLoading] = useState(false);

  const agnosticAatTemplate =
    agnosticAatSnippet.data && "template" in agnosticAatSnippet.data
      ? agnosticAatSnippet.data.template.trim()
      : null;

  return (
    <PageCard>
      <Step3Heading>Browser Setup</Step3Heading>
      <Step3Explainer>
        Please follow our guide to set up browser-to-server data processing.
      </Step3Explainer>
      <Step3GuideButton variant="SMALL" href="https://example.com">
        <div>Go to Guide</div>
        <div>
          <IconExternalLink size="16px" />
        </div>
      </Step3GuideButton>
      <Step3ScriptSection>
        <Step4Explainer>
          Follow the guide above on where to use the script below.
        </Step4Explainer>
        <Step4InputFieldTextArea
          variant="SMALL"
          disabled={agnosticAatTemplate === null}
          value={agnosticAatTemplate ?? "Loading..."}
          spellCheck={false}
          autoCapitalize="off"
          readOnly={true}
        />
        <Step4IconButton
          variant="SMALL"
          state={agnosticAatTemplate === null ? "DISABLED" : "IDLE"}
          onClick={async () => {
            if (agnosticAatTemplate !== null) {
              await navigator.clipboard.writeText(agnosticAatTemplate);
              toast.info("Code copied to clipboard");
            }
          }}
        >
          <div>
            <IconClipboard size="24px" />
          </div>
          <div>Copy to Clipboard</div>
        </Step4IconButton>
      </Step3ScriptSection>
      {!setupFlow.isStepCompleted ? (
        <Step3FinishButtonWrapper>
          <ButtonPrimary
            variant="SMALL"
            state={isLoading ? "LOADING" : "IDLE"}
            onClick={async () => {
              setIsLoading(true);
              await setupFlow.configMutation({});
            }}
          >
            Mark as Complete
          </ButtonPrimary>
        </Step3FinishButtonWrapper>
      ) : null}
    </PageCard>
  );
};

const Step3Heading = styled.div`
  ${heading3Styles};
  margin-bottom: ${props => props.theme.gridBase}px;
`;

const Step3Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const Step3GuideButton = styled(ButtonPrimaryAsLinkExternal)`
  align-items: center;
  gap: ${props => props.theme.gridBase}px;

  > div:last-child {
    display: flex;
  }
`;

const Step3ScriptSection = styled.div`
  padding-top: ${props => props.theme.gridBase * 3}px;
  border-top: 1px solid ${props => props.theme.palette.grey6};
  margin-top: ${props => props.theme.gridBase * 3}px;
`;

const Step3IconButton = styled(ButtonSecondary)`
  display: flex;
  align-items: center;
  padding-top: ${props => props.theme.gridBase}px;
  padding-bottom: ${props => props.theme.gridBase}px;
  padding-left: ${props => props.theme.gridBase * 1.5}px;
  padding-right: ${props => props.theme.gridBase * 2}px;
  margin-top: ${props => props.theme.gridBase * 1.5}px;

  > div:first-child {
    display: flex;
    margin-right: ${props => props.theme.gridBase}px;
  }
`;

const Step3FinishButtonWrapper = styled.div`
  padding-top: ${props => props.theme.gridBase * 3}px;
  border-top: 1px solid ${props => props.theme.palette.grey6};
  margin-top: ${props => props.theme.gridBase * 3}px;
`;

/* ========================================================================== */

const Step4: React.FC = () => {
  const { eventsConnectorConfig } = useMyTrackingDetails();
  const setupFlow = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);

  const agnosticServerUrl = useAgnosticServerUrlQuery();

  const serverInfo = {
    url: agnosticServerUrl.data ?? null,
    id: eventsConnectorConfig.unique_id,
    key: eventsConnectorConfig.globalConfig.signingKeys.at(-1)!
  };

  return (
    <PageCard>
      <Step4Heading>Server Setup</Step4Heading>
      <Step4Explainer>
        Please follow our guide to set up server-to-server data processing.
      </Step4Explainer>
      <Step4GuideButton variant="SMALL" href="https://example.com">
        <div>Go to Guide</div>
        <div>
          <IconExternalLink size="16px" />
        </div>
      </Step4GuideButton>
      <Step4DataSection>
        <Step3Explainer>
          Follow the guide above on where to use the data below.
        </Step3Explainer>
        <Step4DataItemWrapper>
          <InputWrapper labelText="Endpoint URL">
            <InputFieldText
              variant="SMALL"
              disabled={serverInfo.url === null}
              value={serverInfo.url ?? "Loading..."}
              spellCheck={false}
              readOnly={true}
            />
          </InputWrapper>
          <Step3IconButton
            variant="SMALL"
            state={serverInfo.url === null ? "DISABLED" : "IDLE"}
            onClick={async () => {
              if (serverInfo.url !== null) {
                await navigator.clipboard.writeText(serverInfo.url);
                toast.info("URL copied to clipboard");
              }
            }}
          >
            <div>
              <IconClipboard size="24px" />
            </div>
            <div>Copy to Clipboard</div>
          </Step3IconButton>
        </Step4DataItemWrapper>
        <Step4DataItemWrapper>
          <InputWrapper labelText="Website ID">
            <InputFieldText
              variant="SMALL"
              value={serverInfo.id}
              spellCheck={false}
              readOnly={true}
            />
          </InputWrapper>
          <Step3IconButton
            variant="SMALL"
            onClick={async () => {
              await navigator.clipboard.writeText(serverInfo.id);
              toast.info("ID copied to clipboard");
            }}
          >
            <div>
              <IconClipboard size="24px" />
            </div>
            <div>Copy to Clipboard</div>
          </Step3IconButton>
        </Step4DataItemWrapper>
        <Step4DataItemWrapper>
          <InputWrapper labelText="Signing Key">
            <InputFieldText
              variant="SMALL"
              value={serverInfo.key}
              spellCheck={false}
              readOnly={true}
            />
          </InputWrapper>
          <Step3IconButton
            variant="SMALL"
            onClick={async () => {
              await navigator.clipboard.writeText(serverInfo.key);
              toast.info("Key copied to clipboard");
            }}
          >
            <div>
              <IconClipboard size="24px" />
            </div>
            <div>Copy to Clipboard</div>
          </Step3IconButton>
        </Step4DataItemWrapper>
      </Step4DataSection>
      {!setupFlow.isStepCompleted ? (
        <Step4FinishButtonWrapper>
          <ButtonPrimary
            variant="SMALL"
            state={isLoading ? "LOADING" : "IDLE"}
            onClick={async () => {
              setIsLoading(true);
              await setupFlow.configMutation({});
            }}
          >
            Mark as Complete
          </ButtonPrimary>
        </Step4FinishButtonWrapper>
      ) : null}
    </PageCard>
  );
};

const Step4Heading = styled.div`
  ${heading3Styles};
  margin-bottom: ${props => props.theme.gridBase}px;
`;

const Step4Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const Step4GuideButton = styled(ButtonPrimaryAsLinkExternal)`
  align-items: center;
  gap: ${props => props.theme.gridBase}px;

  > div:last-child {
    display: flex;
  }
`;

const Step4DataSection = styled.div`
  padding-top: ${props => props.theme.gridBase * 3}px;
  border-top: 1px solid ${props => props.theme.palette.grey6};
  margin-top: ${props => props.theme.gridBase * 3}px;
`;

const Step4DataItemWrapper = styled.div`
  display: flex;
  align-items: end;
  gap: ${props => props.theme.gridBase * 2}px;

  > :first-child {
    flex: 1;
  }

  &:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 2}px;
  }
`;

const Step4InputFieldTextArea = styled(InputFieldTextArea)`
  white-space: pre;
`;

const Step4IconButton = styled(ButtonSecondary)`
  display: flex;
  align-items: center;
  padding-top: ${props => props.theme.gridBase}px;
  padding-bottom: ${props => props.theme.gridBase}px;
  padding-left: ${props => props.theme.gridBase * 1.5}px;
  padding-right: ${props => props.theme.gridBase * 2}px;
  margin-top: ${props => props.theme.gridBase * 1.5}px;

  > div:first-child {
    display: flex;
    margin-right: ${props => props.theme.gridBase}px;
  }
`;

const Step4FinishButtonWrapper = styled.div`
  padding-top: ${props => props.theme.gridBase * 3}px;
  border-top: 1px solid ${props => props.theme.palette.grey6};
  margin-top: ${props => props.theme.gridBase * 3}px;
`;
