import { produce } from "immer";
import { useEffect, useId, useState } from "react";
import styled, { useTheme } from "styled-components";

import { type OptionalPromise } from "elevar-common-ts/src/utils";

import {
  iconButtonStyles,
  iconTextButtonStyles
} from "elevar-design-system/src/buttons/buttonStyles";
import { ButtonPrimary } from "elevar-design-system/src/buttons/ButtonVariants";
import { IconCircledPlus, IconCross } from "elevar-design-system/src/icons";
import { InputFieldText } from "elevar-design-system/src/inputs/InputFieldText";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import { LabeledRadioText } from "elevar-design-system/src/labeledRadios/LabeledRadioText";
import {
  heading3Styles,
  normalBodyStyles
} from "elevar-design-system/src/typography/typography";

import { fetchShopifyApexDomain } from "../../api/handlers/shopify";
import { useGlobalConfigMutation } from "../../api/handlers/website";
import { PageCard } from "../../components/PageCard";
import { useWebsiteId } from "../../utils/idHooks";
import { toast } from "../../utils/toast";

/* ========================================================================== */

type DomainDetailsProps = {
  type: "SHOPIFY" | "AGNOSTIC";
  isStepCompleted: boolean;
  initialApexDomains: Array<string>;
  onSaveAndContinue: () => OptionalPromise<void>;
};

export const DomainDetails: React.FC<DomainDetailsProps> = ({
  type,
  isStepCompleted,
  initialApexDomains,
  onSaveAndContinue
}) => {
  const theme = useTheme();
  const websiteId = useWebsiteId();
  const radioGroupName = useId();

  const { mutateAsync: globalConfigMutation } = useGlobalConfigMutation();

  const [isLoading, setIsLoading] = useState(false);
  const [apexDomains, setApexDomains] = useState(initialApexDomains);
  const [hasMultipleSubdomains, setHasMultipleSubdomains] = useState(
    type === "SHOPIFY"
      ? true
      : isStepCompleted
        ? initialApexDomains.length > 0
        : null
  );

  const isFetching = type === "SHOPIFY" && apexDomains.length === 0;

  useEffect(() => {
    const effectHandler = async () => {
      if (isFetching) {
        setApexDomains([await fetchShopifyApexDomain({ websiteId })]);
      }
    };
    void effectHandler();
  }, [isFetching, apexDomains, websiteId]);

  return (
    <DomainDetailsWrapper>
      {type === "AGNOSTIC" ? (
        <PageCard>
          <DomainDetailsHeading>
            Do you have multiple subdomains?
          </DomainDetailsHeading>
          <DomainDetailsExplainer>
            For example, <span>blog.website.com</span> and{" "}
            <span>shop.website.com</span>
          </DomainDetailsExplainer>
          <LabeledRadioText
            groupName={radioGroupName}
            isSelected={hasMultipleSubdomains === false}
            setIsSelected={() => setHasMultipleSubdomains(false)}
            text="No"
            isDisabled={isLoading}
          />
          <LabeledRadioText
            groupName={radioGroupName}
            isSelected={hasMultipleSubdomains === true}
            setIsSelected={() => setHasMultipleSubdomains(true)}
            text="Yes"
            isDisabled={isLoading}
          />
          {hasMultipleSubdomains === false ? (
            <DomainDetailsButtonWrapper>
              <ButtonPrimary
                variant="SMALL"
                state={isLoading ? "LOADING" : "IDLE"}
                onClick={async () => {
                  setIsLoading(true);
                  await globalConfigMutation({ apex_domains: [] });
                  setApexDomains([]);

                  if (isStepCompleted) {
                    toast.success("Source updated");
                  } else {
                    await onSaveAndContinue();
                  }

                  setIsLoading(false);
                }}
              >
                {isStepCompleted ? "Save" : "Save & Continue"}
              </ButtonPrimary>
            </DomainDetailsButtonWrapper>
          ) : null}
        </PageCard>
      ) : null}
      {hasMultipleSubdomains ? (
        <PageCard>
          <DomainDetailsHeading>Domain Details</DomainDetailsHeading>
          <DomainDetailsExplainer>
            <p>Please provide the overarching domain(s) of your site.</p>
            <p>
              Note: this does not allow for cross-domain tracking (for example,
              if you have a blog on <span>blog.website.com</span> and your shop
              is on <span>shopwebsite.com</span>). When adding multiple
              overarching domains, the attribution information will be shared
              across subdomains of each overarching domain, not across
              overarching domains.
            </p>
          </DomainDetailsExplainer>
          <DomainDetailsInputsWrapper>
            <DomainDetailsInputWrapper
              labelText="Overarching Domain(s)"
              disabled={isLoading}
              tooltip={{
                maxWidth: `${theme.gridBase * 58}px`,
                render: () => (
                  <DomainDetailsTooltipContent>
                    For instance, if your have the subdomains{" "}
                    <span>blog.website.com</span> and{" "}
                    <span>shop.website.com</span>, your overarching domain is{" "}
                    <span>website.com</span>. We will automatically reformat to
                    exclude http, https, & subdomains.
                  </DomainDetailsTooltipContent>
                )
              }}
            >
              <InputFieldText
                variant="SMALL"
                disabled={isFetching || isLoading}
                value={apexDomains[0] ?? ""}
                onChange={event => {
                  setApexDomains(
                    produce(apexDomains, draft => {
                      draft[0] = event.target.value;
                    })
                  );
                }}
                placeholder={isFetching ? "Loading..." : "website.com"}
                spellCheck={false}
                autoCapitalize="off"
              />
            </DomainDetailsInputWrapper>
            {apexDomains.length > 1
              ? apexDomains.slice(1).map((domain, index) => (
                  <DomainDetailsRemovalInputWrapper key={index}>
                    <InputFieldText
                      variant="SMALL"
                      disabled={isLoading}
                      value={domain}
                      onChange={event => {
                        setApexDomains(
                          produce(apexDomains, draft => {
                            draft[index + 1] = event.target.value;
                          })
                        );
                      }}
                      placeholder={`website${index + 2}.com`}
                      spellCheck={false}
                      autoCapitalize="off"
                    />
                    <button
                      onClick={() => {
                        setApexDomains(apexDomains.toSpliced(index + 1, 1));
                      }}
                    >
                      <IconCross size="16px" />
                    </button>
                  </DomainDetailsRemovalInputWrapper>
                ))
              : null}
            <DomainDetailsAddAnotherButton
              onClick={() => setApexDomains([...apexDomains, ""])}
            >
              <div>
                <IconCircledPlus size="24px" />
              </div>
              <div>Add Another</div>
            </DomainDetailsAddAnotherButton>
          </DomainDetailsInputsWrapper>
          <DomainDetailsButtonWrapper>
            <ButtonPrimary
              variant="SMALL"
              state={
                isLoading
                  ? "LOADING"
                  : apexDomains.length === 0 ||
                      apexDomains.some(apexDomain => apexDomain.length === 0)
                    ? "DISABLED"
                    : "IDLE"
              }
              onClick={async () => {
                setIsLoading(true);

                try {
                  const result = await globalConfigMutation({
                    apex_domains: apexDomains
                  });

                  if (isStepCompleted) {
                    toast.success("Source updated");
                  } else {
                    await onSaveAndContinue();
                  }

                  setApexDomains(result.apex_domains);
                } catch {
                  toast.errorExpected(
                    "You've entered an invalid domain. Please check your domains for spelling errors"
                  );
                } finally {
                  setIsLoading(false);
                }
              }}
            >
              {isStepCompleted ? "Save" : "Save & Continue"}
            </ButtonPrimary>
          </DomainDetailsButtonWrapper>
        </PageCard>
      ) : null}
    </DomainDetailsWrapper>
  );
};

/* ========================================================================== */

const DomainDetailsWrapper = styled.div`
  > ${PageCard}:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }
`;

const DomainDetailsHeading = styled.div`
  ${heading3Styles};
  margin-bottom: ${props => props.theme.gridBase}px;
`;

const DomainDetailsExplainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 1.5}px;

  span {
    font-weight: 500;
    color: ${props => props.theme.palette.grey1};
  }

  > p {
    &:not(:last-child) {
      margin-bottom: ${props => props.theme.gridBase}px;
    }
  }
`;

const DomainDetailsButtonWrapper = styled.div`
  margin-top: ${props => props.theme.gridBase * 3}px;
`;

const DomainDetailsTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;

  span {
    font-weight: 500;
    color: ${props => props.theme.palette.grey1};
  }
`;

const DomainDetailsInputsWrapper = styled.div`
  margin-top: ${props => props.theme.gridBase * 3}px;
  margin-bottom: ${props => props.theme.gridBase}px;
`;

const DomainDetailsInputWrapper = styled(InputWrapper)`
  max-width: ${props => props.theme.gridBase * 40}px;
  margin-bottom: ${props => props.theme.gridBase}px;
`;

const DomainDetailsRemovalInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.gridBase}px;
  max-width: ${props => props.theme.gridBase * 44}px;
  margin-bottom: ${props => props.theme.gridBase}px;

  > button {
    ${iconButtonStyles};
  }
`;

const DomainDetailsAddAnotherButton = styled.button`
  ${iconTextButtonStyles};
  margin-top: ${props => props.theme.gridBase * 1.5}px;
`;
