import { ResponsiveLine } from "@nivo/line";
import dayjs from "dayjs";
import { clamp, maxBy, sumBy } from "lodash-es";
import { transparentize } from "polished";
import { useState } from "react";
import { Link } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import { type SetNonNullable } from "type-fest";

import { type WebsiteDetails } from "elevar-common-ts/src/apiTypes";

import { ButtonWarningAsLink } from "elevar-design-system/src/buttons/ButtonVariants";
import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import { IconCircledInfo, IconCross } from "elevar-design-system/src/icons";
import { StyledLinkExternal } from "elevar-design-system/src/links/LinkExternal";
import { linkStyles } from "elevar-design-system/src/links/links";
import { ProgressBar } from "elevar-design-system/src/ProgressBar";
import { Spinner } from "elevar-design-system/src/Spinner";
import { TooltipBig } from "elevar-design-system/src/Tooltip";
import {
  heading2Styles,
  heading3Styles,
  largeTextStyles,
  normalBodyStyles,
  normalTextStyles,
  smallTextStyles,
  subheadingStyles
} from "elevar-design-system/src/typography/typography";

import {
  useWebsiteBillingCycleQuery,
  useWebsiteDetailsQuery,
  useWebsiteUsageAllotmentQuery,
  useWebsiteUsageRecordsQuery,
  type WebsiteBillingCycle
} from "../../../api/handlers/website";
import { Modal } from "../../../components/Modal";
import { PageCard } from "../../../components/PageCard";
import { useOnboardingDetails } from "../../../context/OnboardingDetails";
import { TimezoneNotice, useZonedDayjs } from "../../../context/Timezone";
import { formatPrice } from "../../../utils/format";
import { useGraphTheme } from "../../../utils/graphTheme";
import { useCompanyId, useWebsiteId } from "../../../utils/idHooks";

/* ========================================================================== */

export const PlanAndUsage: React.FC = () => {
  const websiteDetails = useWebsiteDetailsQuery();
  const websiteBillingCycle = useWebsiteBillingCycleQuery();
  const websiteUsageAllotment = useWebsiteUsageAllotmentQuery();

  if (
    websiteDetails.error !== null ||
    websiteBillingCycle.error !== null ||
    websiteUsageAllotment.error !== null
  ) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  if (
    websiteDetails.data === undefined ||
    websiteBillingCycle.data === undefined ||
    websiteUsageAllotment.data === undefined
  ) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  if (
    websiteUsageAllotment.data === null ||
    websiteBillingCycle.data.start_date === null ||
    websiteBillingCycle.data.end_date === null ||
    websiteBillingCycle.data.billable_start_date === null
  ) {
    return (
      <PlanAndUsageContents
        websiteDetails={websiteDetails.data}
        details={{ usageAllotment: null }}
      />
    );
  } else {
    return (
      <PlanAndUsageWithUsageData
        websiteDetails={websiteDetails.data}
        websiteUsageAllotment={websiteUsageAllotment.data}
        websiteBillingCycle={{
          start_date: websiteBillingCycle.data.start_date,
          end_date: websiteBillingCycle.data.end_date,
          billable_start_date: websiteBillingCycle.data.billable_start_date
        }}
      />
    );
  }
};

const CenteredWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* ========================================================================== */

type PlanAndUsageWithUsageDataProps = {
  websiteDetails: WebsiteDetails;
  websiteUsageAllotment: number;
  websiteBillingCycle: SetNonNullable<WebsiteBillingCycle>;
};

const PlanAndUsageWithUsageData: React.FC<PlanAndUsageWithUsageDataProps> = ({
  websiteDetails,
  websiteUsageAllotment,
  websiteBillingCycle
}) => {
  const websiteUsageRecords = useWebsiteUsageRecordsQuery({
    startDateAfter: websiteBillingCycle.billable_start_date,
    startDateBefore: websiteBillingCycle.end_date
  });

  if (websiteUsageRecords.error) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  if (websiteUsageRecords.data === undefined) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  const mostRecentlyCreatedWebsiteUsageRecord = maxBy(
    websiteUsageRecords.data,
    record => new Date(record.created_at).getTime()
  );

  const validCurrentPeriodRecords = websiteUsageRecords.data.filter(
    record => record.billable && !record.reported
  );

  return (
    <PlanAndUsageContents
      websiteDetails={websiteDetails}
      details={{
        usageAllotment: websiteUsageAllotment,
        usageResetDate: websiteBillingCycle.end_date,
        lastCalculatedDate:
          mostRecentlyCreatedWebsiteUsageRecord?.created_at ?? null,
        currentPeriodUsage: sumBy(
          validCurrentPeriodRecords,
          record => record.processed + record.unprocessed - record.ignored
        ),
        currentPeriodProcessed: sumBy(
          validCurrentPeriodRecords,
          record => record.processed
        )
      }}
    />
  );
};

/* ========================================================================== */

type PlanAndUsageDetailsAllotmentAbsent = { usageAllotment: null };

type PlanAndUsageDetailsAllotmentPresent = {
  usageAllotment: number;
  usageResetDate: string;
  lastCalculatedDate: string | null;
  currentPeriodUsage: number;
  currentPeriodProcessed: number;
};

type PlanAndUsageContentsProps = {
  websiteDetails: WebsiteDetails;
  details:
    | PlanAndUsageDetailsAllotmentAbsent
    | PlanAndUsageDetailsAllotmentPresent;
};

const PlanAndUsageContents: React.FC<PlanAndUsageContentsProps> = ({
  websiteDetails,
  details
}) => {
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();

  const websiteUrl = `/company/${companyId}/website/${websiteId}`;

  const plan = websiteDetails.plan;
  const planName = plan.name;
  const planCost = Math.ceil(plan.amount / 100);
  const totalCost =
    planCost + sumBy(websiteDetails.add_ons, addOn => addOn.base_amount / 100);

  const showManagePlan = websiteDetails.permissions.includes("UPGRADEABLE");
  const managePlanUrl = `${websiteUrl}/settings/plan/manage`;

  const isWebsiteOnFreePlan = plan.id === "DEFAULT_FREE_PLAN";
  const websiteInfoUrl = `${websiteUrl}/settings/info`;
  const websiteCancelPlanUrl = `${websiteUrl}/settings/plan/cancel`;

  return (
    <PageWrapper>
      <PageHeading>Plan & Usage</PageHeading>
      <PlanAndUsagePageCard>
        {details.usageAllotment !== null ? (
          <UsageInfo websiteDetails={websiteDetails} details={details} />
        ) : null}
        <PlanSectionHeading>Plan & Services</PlanSectionHeading>
        <PlanSectionBreakdown>
          <div>
            <div>Plan: {planName}</div>
            <div>
              ${planCost} /{plan.interval}
            </div>
          </div>
          {websiteDetails.add_ons.map(addOn => (
            <div key={addOn.id}>
              <div>Service: {addOn.name}</div>
              <div>
                ${Math.ceil(addOn.base_amount / 100)} /{plan.interval}
              </div>
            </div>
          ))}
        </PlanSectionBreakdown>
        <PlanSectionSummary>
          <div>
            ${totalCost} /{plan.interval}
          </div>
          {showManagePlan ? (
            <ManagePlanLink to={managePlanUrl}>
              Manage Plan & Services
            </ManagePlanLink>
          ) : null}
        </PlanSectionSummary>
      </PlanAndUsagePageCard>
      {isWebsiteOnFreePlan ? (
        <PlanWarningCard>
          Want to archive this Website?{" "}
          <Link to={websiteInfoUrl}>Click here</Link>.
        </PlanWarningCard>
      ) : (
        <ButtonWarningAsLink variant="SMALL" to={websiteCancelPlanUrl}>
          Cancel Website Plan
        </ButtonWarningAsLink>
      )}
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  padding: ${props => props.theme.gridBase * 4}px;
`;

const PageHeading = styled.h1`
  ${heading2Styles};
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const PlanAndUsagePageCard = styled(PageCard)`
  max-width: ${props => props.theme.gridBase * 70.5}px;
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const PlanSectionHeading = styled.div`
  ${heading2Styles};
  margin-bottom: ${props => props.theme.gridBase * 2.5}px;
`;

const PlanSectionBreakdown = styled.div`
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div:first-child {
      ${heading3Styles};
    }

    > div:last-child {
      ${normalTextStyles};
      color: ${props => props.theme.palette.grey1};
    }

    &:not(:last-child) {
      margin-bottom: ${props => props.theme.gridBase}px;
    }
  }
`;

const PlanSectionSummary = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${props => props.theme.gridBase * 2}px;
  border-top: 1px solid ${props => props.theme.palette.grey6};
  padding-top: ${props => props.theme.gridBase * 2}px;

  > div:first-child {
    ${normalTextStyles};
    color: ${props => props.theme.palette.grey3};
  }
`;

const ManagePlanLink = styled(Link)`
  ${normalBodyStyles};
  ${linkStyles};
`;

const PlanWarningCard = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.red1};
  background-color: ${props => transparentize(0.9, props.theme.palette.red1)};
  padding-top: ${props => props.theme.gridBase}px;
  padding-bottom: ${props => props.theme.gridBase}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;
  width: max-content;
  border-radius: 4px;
  margin-bottom: ${props => props.theme.gridBase * 2}px;

  > a {
    font-weight: 500;
  }
`;

/* ========================================================================== */

type UsageInfoProps = {
  websiteDetails: WebsiteDetails;
  details: PlanAndUsageDetailsAllotmentPresent;
};

const UsageInfo: React.FC<UsageInfoProps> = ({ websiteDetails, details }) => {
  const theme = useTheme();
  const { isAgnosticSourceEnabled } = useOnboardingDetails();

  const [isUsageHistoryModalVisible, setIsUsageHistoryModalVisible] =
    useState(false);

  const plan = websiteDetails.plan;
  const formattedUsage = details.currentPeriodUsage.toLocaleString("en");
  const formattedAllotment = details.usageAllotment.toLocaleString("en");
  const percent = (100 / details.usageAllotment) * details.currentPeriodUsage;
  const overageCount = details.currentPeriodUsage - details.usageAllotment;
  const formattedOverageCount = overageCount.toLocaleString("en");
  const overageUnitCost = Number(plan.overage_amount);
  const formattedOverageUnitCost = formatPrice(overageUnitCost);
  const hasProcessed = details.currentPeriodProcessed > 0;
  const shouldChargeOverage = plan.charge_overage_if_no_orders_processed;
  const maxOveragesCost = hasProcessed ? 10_000 : shouldChargeOverage ? 50 : 0;
  const overagesCost = clamp(overageCount * overageUnitCost, maxOveragesCost);

  return (
    <>
      <UsageSectionWrapper>
        <UsageSectionHeader>
          <div>
            <div>Usage</div>
            <TooltipBig
              placement="top"
              maxWidth={`${theme.gridBase * 44.5}px`}
              render={() => (
                <UsageSectionHeaderTooltipContent>
                  <p>
                    If you have live server-side destinations, we will only
                    count orders sent to your destinations.
                  </p>
                  <p>
                    If you're on the Starter plan and don't have any live
                    server-side destinations, we count all orders{" "}
                    {isAgnosticSourceEnabled
                      ? "received by our API"
                      : "created in your Shopify Store"}
                    , capped at a max fee of $50.
                  </p>
                  <p>
                    We calculate usage every 24 hours using your Website's
                    timezone.
                  </p>
                  <StyledLinkExternal
                    href="https://docs.getelevar.com/docs/elevar-billing-faqs"
                    text="Learn more"
                  />
                </UsageSectionHeaderTooltipContent>
              )}
            >
              <UsageSectionHeaderTooltipTrigger>
                <IconCircledInfo size="16px" />
              </UsageSectionHeaderTooltipTrigger>
            </TooltipBig>
          </div>
          {details.lastCalculatedDate ? (
            <div>
              Last calculated {dayjs(details.lastCalculatedDate).fromNow()}
            </div>
          ) : null}
        </UsageSectionHeader>
        <UsageSectionUsageText>
          <div>
            <div>
              <span>{formattedUsage}</span> / {formattedAllotment} orders
            </div>
            <TooltipBig
              placement="top"
              maxWidth={`${theme.gridBase * 31}px`}
              render={() => (
                <UsageSectionUsageTextTooltipContent>
                  You have {formattedAllotment} orders /{plan.interval} included
                  in your plan. Any extra order costs {formattedOverageUnitCost}
                  .
                </UsageSectionUsageTextTooltipContent>
              )}
            >
              <UsageSectionUsageTextTooltipTrigger>
                <IconCircledInfo size="16px" />
              </UsageSectionUsageTextTooltipTrigger>
            </TooltipBig>
          </div>
          <div>
            <span>{Math.round(percent).toLocaleString("en")}%</span> used
          </div>
        </UsageSectionUsageText>
        <UsageSectionUsageBar percentageFilled={percent} />
        {overageCount > 0 ? (
          <UsageSectionOverageInfo>
            <div>
              <div>
                <span>{formattedOverageCount}</span> extra orders x{" "}
                {formattedOverageUnitCost}
              </div>
            </div>
            <div>
              <span>
                {overagesCost === maxOveragesCost
                  ? "Extra Charge (Limit Hit):"
                  : "Extra Charge:"}
              </span>
              <span>{formatPrice(overagesCost)}</span>
            </div>
          </UsageSectionOverageInfo>
        ) : null}
        <UsageSectionMoreInfo>
          <div>
            Usage resets <span>{dayjs(details.usageResetDate).fromNow()}</span>
          </div>
          <button onClick={() => setIsUsageHistoryModalVisible(true)}>
            View Usage History
          </button>
        </UsageSectionMoreInfo>
      </UsageSectionWrapper>
      <UsageHistoryModal
        isVisible={isUsageHistoryModalVisible}
        onClose={() => setIsUsageHistoryModalVisible(false)}
      />
    </>
  );
};

const UsageSectionWrapper = styled.div`
  margin-bottom: ${props => props.theme.gridBase * 5}px;
`;

const UsageSectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => props.theme.gridBase * 3}px;

  > div:first-child {
    display: flex;
    align-items: center;

    > div:first-child {
      ${heading2Styles};
    }
  }

  > div:last-child {
    ${normalBodyStyles};
    color: ${props => props.theme.palette.grey3};
  }
`;

const UsageSectionHeaderTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding: ${props => props.theme.gridBase * 2}px;

  > p:not(:last-of-type) {
    margin-bottom: ${props => props.theme.gridBase}px;
  }

  > p:last-of-type {
    margin-bottom: ${props => props.theme.gridBase * 0.75}px;
  }
`;

const UsageSectionHeaderTooltipTrigger = styled.div`
  display: flex;
  color: ${props => props.theme.palette.grey4};
  padding: ${props => props.theme.gridBase * 0.5}px;
  margin-left: ${props => props.theme.gridBase * 0.5}px;
`;

const UsageSectionUsageText = styled.div`
  ${normalBodyStyles};
  display: flex;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.gridBase * 1.5}px;

  > div:first-child {
    display: flex;

    > div:first-child {
      color: ${props => props.theme.palette.grey2};

      > span {
        font-weight: 500;
        color: ${props => props.theme.palette.grey1};
      }
    }
  }

  > div:last-child {
    color: ${props => props.theme.palette.grey3};

    > span {
      font-weight: 500;
    }
  }
`;

const UsageSectionUsageBar = styled(ProgressBar)`
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const UsageSectionOverageInfo = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  background-color: ${props =>
    transparentize(0.95, props.theme.palette.purple2)};
  padding-top: ${props => props.theme.gridBase * 1.25}px;
  padding-bottom: ${props => props.theme.gridBase * 1.25}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;
  margin-bottom: ${props => props.theme.gridBase * 2}px;

  > div:first-child {
    display: flex;

    > div:first-child {
      > span {
        font-weight: 500;
      }
    }
  }

  > div:last-child {
    display: flex;
    gap: ${props => props.theme.gridBase * 0.75}px;

    > span:last-child {
      font-weight: 600;
    }
  }
`;

const UsageSectionMoreInfo = styled.div`
  ${normalBodyStyles};
  display: flex;
  justify-content: space-between;

  > div {
    color: ${props => props.theme.palette.grey3};

    > span {
      font-weight: 500;
      color: ${props => props.theme.palette.grey3};
    }
  }

  > button {
    ${linkStyles};
  }
`;

const UsageSectionUsageTextTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;
`;

const UsageSectionUsageTextTooltipTrigger = styled.div`
  display: flex;
  color: ${props => props.theme.palette.grey4};
  padding: ${props => props.theme.gridBase * 0.5}px;
  margin-left: ${props => props.theme.gridBase * 0.5}px;
`;

/* ========================================================================== */

type UsageHistoryModalProps = {
  isVisible: boolean;
  onClose: () => void;
};

const UsageHistoryModal: React.FC<UsageHistoryModalProps> = ({
  isVisible,
  onClose
}) => {
  const theme = useTheme();

  return (
    <Modal isVisible={isVisible} onClose={onClose}>
      <ModalContents>
        <ModalHeader>
          <div>
            <div>Usage History</div>
            <TooltipBig
              placement="top"
              maxWidth={`${theme.gridBase * 36}px`}
              render={() => (
                <ModalTooltipContent>
                  Your usage by months. Historical data may not be available
                  based on when you signed up or changed your plan.
                </ModalTooltipContent>
              )}
            >
              <ModalTooltipTrigger>
                <IconCircledInfo size="16px" />
              </ModalTooltipTrigger>
            </TooltipBig>
          </div>
          <div>
            <TimezoneNotice />
            <button onClick={onClose}>
              <IconCross size="16px" />
            </button>
          </div>
        </ModalHeader>
        <ModalContentsInner>
          <UsageHistoryModalContents isVisible={isVisible} />
        </ModalContentsInner>
      </ModalContents>
    </Modal>
  );
};

const ModalContents = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => props.theme.gridBase * 90}px;
  height: ${props => props.theme.gridBase * 60}px;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => props.theme.gridBase * 4}px;

  > div:first-child {
    display: flex;
    align-items: center;

    > div:first-child {
      ${heading2Styles};
    }
  }

  > div:last-child {
    display: flex;
    align-items: center;
    gap: ${props => props.theme.gridBase * 2.5}px;

    > button {
      display: flex;
      border-radius: 2px;
      padding: ${props => props.theme.gridBase}px;
      background-color: ${props => props.theme.palette.grey8};
      color: ${props => props.theme.palette.grey3};
      transition: background-color ${props => props.theme.other.transition};

      &:hover {
        background-color: ${props => props.theme.palette.grey6};
      }
    }
  }
`;

const ModalTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;
`;

const ModalTooltipTrigger = styled.div`
  display: flex;
  color: ${props => props.theme.palette.grey4};
  padding: ${props => props.theme.gridBase * 0.5}px;
  margin-left: ${props => props.theme.gridBase * 0.5}px;
`;

const ModalContentsInner = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* ========================================================================== */

type UsageHistoryModalContentsProps = {
  isVisible: boolean;
};

const UsageHistoryModalContents: React.FC<UsageHistoryModalContentsProps> = ({
  isVisible
}) => {
  const theme = useTheme();
  const graphTheme = useGraphTheme();
  const zonedDayjs = useZonedDayjs();

  const graphEndDate = zonedDayjs().add(1, "day").startOf("day");
  const graphStartDate = graphEndDate.subtract(1, "year").set("date", 1);

  const websiteUsageRecords = useWebsiteUsageRecordsQuery({
    startDateAfter: graphStartDate.toISOString(),
    startDateBefore: graphEndDate.toISOString(),
    enabled: isVisible
  });

  if (websiteUsageRecords.error) {
    return <ErrorOccurred />;
  }

  if (websiteUsageRecords.data === undefined) {
    return <Spinner size="24px" />;
  }

  const billableRecords = websiteUsageRecords.data.filter(r => r.billable);

  const data = [...Array(13).keys()].map(offset => {
    const pointStartDate = graphStartDate.add(offset, "month");
    return {
      x: pointStartDate.toDate(),
      y: sumBy(
        billableRecords.filter(r => {
          const recordStartDate = zonedDayjs(r.start_date);
          return (
            recordStartDate.year() === pointStartDate.year() &&
            recordStartDate.month() === pointStartDate.month()
          );
        }),
        r => r.unprocessed + r.processed - r.ignored
      )
    };
  });

  return (
    <ResponsiveLine
      data={[{ id: 1, data }]}
      theme={graphTheme}
      axisLeft={{
        tickSize: 0,
        tickPadding: theme.gridBase * 2,
        legend: "Order Volume",
        legendPosition: "middle",
        legendOffset: theme.gridBase * -8.5,
        format: (value: string | number) => {
          if (Number.isInteger(value)) {
            return Number(value).toLocaleString("en", { notation: "compact" });
          } else {
            return "";
          }
        }
      }}
      axisBottom={{
        tickSize: 0,
        tickPadding: theme.gridBase * 2,
        tickRotation: 0,
        format: (v: string) => zonedDayjs(v).format("MMM")
      }}
      xFormat={v => zonedDayjs(v).format("MMM")}
      yFormat={value => Number(value).toLocaleString()}
      gridYValues={[0]}
      animate={false}
      yScale={{
        type: "linear",
        min: 0,
        // Ensures that points do not get centered
        max: data.every(d => d.y === 0) ? 0.1 : "auto"
      }}
      margin={{
        top: theme.gridBase * 2,
        bottom: theme.gridBase * 5.5,
        left: theme.gridBase * 10,
        right: theme.gridBase * 5
      }}
      curve="monotoneX"
      enableArea={true}
      lineWidth={2}
      colors={theme.palette.purple2}
      pointSize={10}
      pointBorderWidth={2}
      pointBorderColor={theme.palette.white}
      enableSlices="x"
      sliceTooltip={({ slice }) => (
        <TooltipWrapper>
          <TooltipHeader>
            {zonedDayjs(slice.points[0]!.data.x).format("MMMM YYYY")}
          </TooltipHeader>
          <TooltipMetric>Order Volume</TooltipMetric>
          <TooltipValue>{slice.points[0]!.data.yFormatted}</TooltipValue>
        </TooltipWrapper>
      )}
    />
  );
};

const TooltipWrapper = styled.div`
  background-color: ${props => props.theme.palette.white};
  padding: ${props => props.theme.gridBase * 1.5}px;
  box-shadow: ${props => props.theme.other.boxShadowDropdown};
  border-radius: 4px;
`;

const TooltipHeader = styled.div`
  ${subheadingStyles};
  color: ${props => props.theme.palette.grey3};
  margin-bottom: ${props => props.theme.gridBase}px;
`;

const TooltipMetric = styled.div`
  ${smallTextStyles};
  color: ${props => props.theme.palette.grey2};
`;

const TooltipValue = styled.div`
  ${largeTextStyles};
  margin-top: ${props => props.theme.gridBase * 0.5}px;
`;
