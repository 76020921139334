import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import {
  type RedirectProps,
  Route,
  Switch,
  useLocation
} from "react-router-dom";
import styled from "styled-components";

import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";

import { RedirectWithoutLastLocation } from "./context/LastLocation";
import {
  PageScrollContainerProvider,
  usePageScrollContainer
} from "./context/PageScrollContainer";
import { UserProvider, useUserOptional } from "./context/User";
import { AccountSettings } from "./routes/account/index";
import { Alias } from "./routes/Alias";
import { Auth } from "./routes/Auth";
import { CompaniesAndWebsites } from "./routes/CompaniesAndWebsites";
import { Company } from "./routes/company/index";
import { CreateCompany } from "./routes/CreateCompany";
import { ExtensionSync } from "./routes/ExtensionSync";
import { Invite } from "./routes/Invite";
import { OAuthReturn } from "./routes/OAuthReturn";
import { ShopifyFlowApprove } from "./routes/shopifyFlow/ShopifyFlowApprove";
import { ShopifyFlowFinalize } from "./routes/shopifyFlow/ShopifyFlowFinalize";
import { ShopifyFlowLogin } from "./routes/shopifyFlow/ShopifyFlowLogin";
import { WebsiteRedirect } from "./routes/WebsiteRedirect";
import { track } from "./utils/track";
import { LargeViewportOnly } from "./LargeViewportOnly";

/* ========================================================================== */

type DidMountProps = {
  onMount: () => void;
};

const DidMount: React.FC<DidMountProps> = ({ onMount }) => {
  useEffect(onMount, [onMount]);
  return null;
};

/* ========================================================================== */

export const App: React.FC = () => {
  const pageScrollContainerRef = useRef<HTMLDivElement | null>(null);
  const [mounted, setMounted] = useState(false);

  return (
    <ScrollContainer ref={pageScrollContainerRef}>
      <ErrorBoundary
        onError={() => track.error()}
        FallbackComponent={() => (
          <CenteredWrapper>
            <ErrorOccurred />
          </CenteredWrapper>
        )}
      >
        <DidMount onMount={() => setMounted(true)} />
        {mounted && pageScrollContainerRef.current ? (
          <PageScrollContainerProvider value={pageScrollContainerRef.current}>
            <UserProvider>
              <AppInner />
            </UserProvider>
          </PageScrollContainerProvider>
        ) : null}
      </ErrorBoundary>
    </ScrollContainer>
  );
};

const ScrollContainer = styled.div`
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: scroll;
`;

const CenteredWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* ========================================================================== */

type RedirectLocationState = { redirect?: RedirectProps["to"] } | undefined;

const AppInner: React.FC = () => {
  const location = useLocation<RedirectLocationState>();
  const pageScrollContainer = usePageScrollContainer();
  const user = useUserOptional();

  useLayoutEffect(() => {
    pageScrollContainer.scrollTo({ top: 0, left: 0 });
  }, [pageScrollContainer, location.pathname]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const planCode = searchParams.get("plan");
    if (planCode) sessionStorage.setItem("planCode", planCode);
  }, [location.search]);

  return (
    <Switch>
      <Route
        exact={false}
        path="/shopify_login"
        render={() => <ShopifyFlowLogin />}
      />
      <Route
        exact={true}
        path="/finalize"
        render={() => <ShopifyFlowFinalize />}
      />
      <Route
        exact={true}
        path="/approve_subscription"
        render={() => <ShopifyFlowApprove />}
      />
      <Route
        exact={true}
        path="/invite/:inviteToken"
        render={() => (
          <LargeViewportOnly showLogout={user.isLoggedIn}>
            <Invite />
          </LargeViewportOnly>
        )}
      />
      {user.isLoggedIn ? (
        <Switch>
          <Route
            exact={true}
            path="/"
            render={() => (
              <LargeViewportOnly showLogout={true}>
                <CompaniesAndWebsites />
              </LargeViewportOnly>
            )}
          />
          <Route
            exact={false}
            path="/account"
            render={() => (
              <LargeViewportOnly showLogout={true}>
                <AccountSettings />
              </LargeViewportOnly>
            )}
          />
          <Route
            exact={true}
            path="/company/create"
            render={() => (
              <LargeViewportOnly showLogout={true}>
                <CreateCompany />
              </LargeViewportOnly>
            )}
          />
          <Route
            exact={false}
            path="/company/:companyId"
            render={() => (
              <LargeViewportOnly showLogout={true}>
                <Company />
              </LargeViewportOnly>
            )}
          />
          <Route
            exact={true}
            path="/extension-sync"
            render={() => (
              <LargeViewportOnly showLogout={true}>
                <ExtensionSync />
              </LargeViewportOnly>
            )}
          />
          <Route
            exact={true}
            path="/oauth/return/:target"
            render={() => <OAuthReturn />}
          />
          <Route
            exact={true}
            path="/auth/reset-password"
            render={() => (
              <RedirectWithoutLastLocation to="/account/password" />
            )}
          />
          <Route
            exact={true}
            path="/auth/reset-password/:key"
            render={({ match }) => (
              <RedirectWithoutLastLocation
                to={`/account/password/${match.params.key}`}
              />
            )}
          />
          <Route
            exact={false}
            path="/website/:websiteId"
            render={() => <WebsiteRedirect />}
          />
          <Route exact={false} path="/alias" render={() => <Alias />} />
          <RedirectWithoutLastLocation to={location.state?.redirect ?? "/"} />
        </Switch>
      ) : (
        <Switch>
          <Route exact={false} path="/auth" render={() => <Auth />} />
          <RedirectWithoutLastLocation
            to={{ pathname: "/auth/login", state: { redirect: location } }}
          />
        </Switch>
      )}
    </Switch>
  );
};
