type IconBoltProps = {
  size: "24px" | "16px";
  color?: string;
  className?: string;
};

export const IconBolt: React.FC<IconBoltProps> = ({
  size,
  color = "currentColor",
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M13 2L3 14H12L11 22L21 10H12L13 2Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case "16px":
      return (
        <svg
          className={className}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M8.66667 1.33301L2 9.33301H8L7.33333 14.6663L14 6.66634H8L8.66667 1.33301Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
  }
};
