import styled from "styled-components";

import { type WebsiteDetails } from "elevar-common-ts/src/apiTypes";

import {
  heading2Styles,
  heading3Styles
} from "elevar-design-system/src/typography/typography";

import { useServerSideDetails } from "../../../../context/ServerSideDetails";
import { PageCardGoogle } from "./PageCardGoogle";
import { PageCardGtmServer } from "./PageCardGtmServer";
import { PageCardGtmWeb } from "./PageCardGtmWeb";

/* ========================================================================== */

type ConnectionsProps = {
  websiteDetails: WebsiteDetails;
};

export const Connections: React.FC<ConnectionsProps> = ({ websiteDetails }) => {
  const { isFullyManaged } = useServerSideDetails();

  const isGtmWebCardVisible = websiteDetails.permissions.includes("GTM_AUTH");
  const isGtmServerCardVisible =
    websiteDetails.workspace.ss_client_id !== null && !isFullyManaged;

  return (
    <ConnectionsWrapper>
      <PageHeading>Connections</PageHeading>
      <PageSectionHeading>Google</PageSectionHeading>
      <PageSectionContent>
        <div>
          <PageCardGoogle websiteDetails={websiteDetails} />
        </div>
        {isGtmWebCardVisible ? (
          <div>
            <PageCardGtmWeb websiteDetails={websiteDetails} />
          </div>
        ) : null}
        {isGtmServerCardVisible ? (
          <div>
            <PageCardGtmServer websiteDetails={websiteDetails} />
          </div>
        ) : null}
      </PageSectionContent>
    </ConnectionsWrapper>
  );
};

/* ========================================================================== */

const ConnectionsWrapper = styled.div`
  padding: ${props => props.theme.gridBase * 4}px;
  max-width: ${props => props.theme.gridBase * 70.5}px;
`;

const PageHeading = styled.h1`
  ${heading2Styles};
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const PageSectionHeading = styled.h2`
  ${heading3Styles};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const PageSectionContent = styled.div`
  > div:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 2}px;
  }
`;
