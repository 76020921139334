import { uniq } from "lodash-es";
import { useState } from "react";
import useMeasure from "react-use-measure";
import styled, { useTheme } from "styled-components";

import { IconChevronDown, IconChevronUp } from "elevar-design-system/src/icons";
import { InputFieldSelect } from "elevar-design-system/src/inputs/InputFieldSelect";
import { InputFieldText } from "elevar-design-system/src/inputs/InputFieldText";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import { LinkExternal } from "elevar-design-system/src/links/LinkExternal";
import { linkStyles } from "elevar-design-system/src/links/links";
import {
  normalBodyStyles,
  normalTextStyles
} from "elevar-design-system/src/typography/typography";

import { PageCard } from "../../components/PageCard";
import { createSetupFlow } from "../../context/SetupFlowDetails";
import { ConsentModeReader } from "./ConsentModeReader";
import { destinationSas as destination } from "./data";
import { FilterTransactions } from "./FilterTransactions";
import { MarketGroupSettings } from "./MarketGroupSettings";
import { Overview } from "./Overview";
import { ProductIdentifier } from "./ProductIdentifier";
import { containsNumbersOnly, conversionValueOptions } from "./shared";
import { StepSection } from "./StepSection";
import { Subscriptions } from "./Subscriptions";
import { TransactionIdentifier } from "./TransactionIdentifier";

/* ========================================================================== */

const setupGuideHref =
  "https://docs.getelevar.com/docs/how-to-set-up-shareasale-merchant-api-in-elevar-server-side-destination";

const { SetupFlow, useConfigRequired, useSetupFlowDetails } =
  createSetupFlow().destination(destination);

type DestinationSasProps = {
  isCompanyAdmin: boolean;
};

export const DestinationSas: React.FC<DestinationSasProps> = ({
  isCompanyAdmin
}) => {
  return (
    <SetupFlow
      isCompanyAdmin={isCompanyAdmin}
      steps={[
        { details: { type: "SETTINGS" } },
        { details: { type: "CONSENT_MODE" } },
        { details: { type: "TRANSACTION_IDENTIFIER" } },
        { details: { type: "PRODUCT_IDENTIFIER" } },
        { details: { type: "FILTER_TRANSACTIONS" } },
        { details: { type: "SUBSCRIPTIONS" } }
      ]}
    >
      <StepContent />
    </SetupFlow>
  );
};

/* ========================================================================== */

const StepContent: React.FC = () => {
  const { currentStep } = useSetupFlowDetails();

  switch (currentStep) {
    case 0:
      return <Step0 />;
    case 1:
      return <Step1 />;
    case 2:
      return <Step2 />;
    case 3:
      return <Step3 />;
    case 4:
      return <Step4 />;
    case 5:
      return <Step5 />;
    case 6:
      return <Step6 />;
  }
};

/* ========================================================================== */

const Step0: React.FC = () => {
  const setupFlow = useSetupFlowDetails();

  return (
    <Overview
      destination={destination}
      config={setupFlow.config}
      configMutation={setupFlow.configMutation}
      configLabel={setupFlow.configLabel}
      completedStep={setupFlow.completedStep}
      isStepCompleted={setupFlow.isStepCompleted}
      setCurrentStep={setupFlow.setCurrentStep}
      getConfigSummaryItems={config => [
        {
          step: 1,
          type: "CUSTOM",
          render: () => (
            <>
              The ShareASale Merchant ID used is{" "}
              <span>{config.merchantId}</span>
            </>
          )
        },
        {
          step: 2,
          type: "CONSENT_MODE",
          inEnabled: config.consentMode.enabled
        },
        {
          step: 3,
          type: "TRANSACTION_IDENTIFIER",
          value: config.dataConfig.orderAttributeId
        },
        {
          step: 4,
          type: "PRODUCT_IDENTIFIER",
          value: config.dataConfig.productAttributeMapping
        },
        {
          step: 5,
          type: "FILTER_TRANSACTIONS",
          filters: config.orderFilters
        },
        {
          step: 6,
          type: "SUBSCRIPTIONS",
          filters: config.orderFilters,
          tagName: config.subscriptionTagName
        }
      ]}
      description={
        <Step0Explainer>
          Guarantee near 100% accuracy for ShareASale transactions by
          configuring this server-side integration.
        </Step0Explainer>
      }
      integrationNotes={{
        type: "ACTION_REQUIRED",
        content: (
          <div>
            You must contact ShareASale customer support to enable the Merchant
            API & authorize New Transaction API calls prior to turning on the
            Elevar server-side integration. You must also disable the IP address
            matching in your ShareASale account.{" "}
            <LinkExternal href="https://docs.getelevar.com/docs/how-to-set-up-shareasale-merchant-api-in-elevar-server-side-destination#prerequisites">
              How do I do all this?
            </LinkExternal>
          </div>
        )
      }}
    />
  );
};

const Step0Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

/* ========================================================================== */

const Step1: React.FC = () => {
  const theme = useTheme();
  const [inputWrapperRef, { width: inputWrapperWidth }] = useMeasure();
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  const [merchantId, setMerchantId] = useState(config.merchantId);
  const [accessToken, setAccessToken] = useState(config.accessToken);
  const [apiSecret, setApiSecret] = useState(config.apiSecret);
  const [storeId, setStoreId] = useState(config.storeId);
  const [isAdvancedShown, setIsAdvancedShown] = useState(false);
  const [conversionValue, setConversionValue] = useState(
    config.dataConfig.conversionValue
  );

  const selectedConversionValueOption =
    conversionValueOptions.find(o => o.value === conversionValue) ?? null;

  const shareASaleApiVersionOption = { name: "3.0", value: "3.0" };
  const areShareASaleFieldsFilledIn =
    merchantId !== "" && accessToken !== "" && apiSecret !== "";
  const areShareASaleFieldsUnique =
    uniq([merchantId, accessToken, apiSecret]).length === 3;

  const isMerchantIdValid = containsNumbersOnly(merchantId);

  return (
    <Step1Wrapper>
      <PageCard>
        <StepSection
          title="ShareASale Settings"
          setupGuideHref={setupGuideHref}
          description={
            <Step1Explainer>
              In order to send your transactions data to this destination, we
              need the following information from your ShareASale account.
            </Step1Explainer>
          }
        >
          <Step1SectionInnerWrapper ref={inputWrapperRef}>
            <div>
              <InputWrapper
                labelText="ShareASale Merchant ID"
                disabled={setupFlow.isLoading}
                tooltip={{
                  maxWidth: `${theme.gridBase * 40}px`,
                  render: () => (
                    <Step1InputWrapperTooltipContent>
                      This ID can be found in your ShareASale account. Please
                      see{" "}
                      <LinkExternal href="https://docs.getelevar.com/docs/how-to-find-shareasale-merchant-id">
                        here
                      </LinkExternal>{" "}
                      for how to find it.
                    </Step1InputWrapperTooltipContent>
                  )
                }}
              >
                <InputFieldText
                  variant="SMALL"
                  disabled={setupFlow.isLoading}
                  value={merchantId}
                  onChange={event => setMerchantId(event.target.value)}
                  spellCheck={false}
                  autoCapitalize="off"
                />
              </InputWrapper>
            </div>
            <div>
              <InputWrapper
                labelText="ShareASale Access Token"
                disabled={setupFlow.isLoading}
                tooltip={{
                  maxWidth: `${theme.gridBase * 41}px`,
                  render: () => (
                    <Step1InputWrapperTooltipContent>
                      This token can be found in your ShareASale account. Please
                      see{" "}
                      <LinkExternal href="https://docs.getelevar.com/docs/how-to-find-shareasale-token-api-secret">
                        here
                      </LinkExternal>{" "}
                      for how to find it.
                    </Step1InputWrapperTooltipContent>
                  )
                }}
              >
                <InputFieldText
                  variant="SMALL"
                  disabled={setupFlow.isLoading}
                  value={accessToken}
                  onChange={event => setAccessToken(event.target.value)}
                  spellCheck={false}
                  autoCapitalize="off"
                />
              </InputWrapper>
            </div>
            <div>
              <InputWrapper
                labelText="ShareASale API Version"
                disabled={setupFlow.isLoading}
                tooltip={{
                  maxWidth: `${theme.gridBase * 34}px`,
                  render: () => (
                    <Step1InputWrapperTooltipContent>
                      Elevar sets this to the latest version by default. See{" "}
                      <LinkExternal href="https://blog.shareasale.com/2021/08/18/merchant-api-version-3-0-released">
                        here
                      </LinkExternal>{" "}
                      for more info.
                    </Step1InputWrapperTooltipContent>
                  )
                }}
              >
                <InputFieldSelect
                  variant="SMALL"
                  disabled={true}
                  value={shareASaleApiVersionOption}
                  setValue={() => null}
                  options={[shareASaleApiVersionOption]}
                  placeholder="Select a Version"
                />
              </InputWrapper>
            </div>
            <div>
              <InputWrapper
                labelText="ShareASale API Secret"
                disabled={setupFlow.isLoading}
                tooltip={{
                  maxWidth: `${theme.gridBase * 41}px`,
                  render: () => (
                    <Step1InputWrapperTooltipContent>
                      This secret can be found in your ShareASale account.
                      Please see{" "}
                      <LinkExternal href="https://docs.getelevar.com/docs/how-to-find-shareasale-token-api-secret">
                        here
                      </LinkExternal>{" "}
                      for how to find it.
                    </Step1InputWrapperTooltipContent>
                  )
                }}
              >
                <InputFieldText
                  variant="SMALL"
                  disabled={setupFlow.isLoading}
                  value={apiSecret}
                  onChange={event => setApiSecret(event.target.value)}
                  spellCheck={false}
                  autoCapitalize="off"
                />
              </InputWrapper>
            </div>
            <div>
              <InputWrapper
                labelText="ShareASale Store ID"
                disabled={setupFlow.isLoading}
                tooltip={{
                  maxWidth: `${theme.gridBase * 40.5}px`,
                  render: () => (
                    <Step1InputWrapperTooltipContent>
                      The store ID can be found in your ShareASale account.
                      Please see{" "}
                      <LinkExternal href="https://docs.getelevar.com/docs/how-to-find-your-shareasale-store-id">
                        here
                      </LinkExternal>{" "}
                      for how to find it. This value is only required if you
                      have multiple stores in ShareASale and are using the
                      'Store Connect' feature.
                    </Step1InputWrapperTooltipContent>
                  )
                }}
                optional={true}
              >
                <InputFieldText
                  variant="SMALL"
                  disabled={setupFlow.isLoading}
                  value={storeId}
                  onChange={event => setStoreId(event.target.value)}
                  spellCheck={false}
                  autoCapitalize="off"
                />
              </InputWrapper>
            </div>
          </Step1SectionInnerWrapper>
        </StepSection>
        <Step1AdvancedOptionsToggleButton
          onClick={() => setIsAdvancedShown(!isAdvancedShown)}
        >
          <div>Advanced Options</div>
          <div>
            {isAdvancedShown ? (
              <IconChevronUp size="16px" />
            ) : (
              <IconChevronDown size="16px" />
            )}
          </div>
        </Step1AdvancedOptionsToggleButton>
        {isAdvancedShown ? (
          <Step1AdvancedOptionsWrapper inputWrapperWidth={inputWrapperWidth}>
            <InputWrapper
              labelText="Conversion Value"
              disabled={setupFlow.isLoading}
            >
              <InputFieldSelect
                variant="SMALL"
                disabled={setupFlow.isLoading}
                value={selectedConversionValueOption}
                setValue={option => setConversionValue(option.value)}
                options={conversionValueOptions}
                placeholder="Select a Conversion Value"
              />
            </InputWrapper>
          </Step1AdvancedOptionsWrapper>
        ) : null}
        <MarketGroupSettings
          config={config}
          destination={destination}
          isLoading={setupFlow.isLoading}
          isStepCompleted={setupFlow.isStepCompleted}
          saveButtonDisabledTooltipOverride={
            !areShareASaleFieldsFilledIn
              ? 'Please fill out all fields (except from "Store ID”)'
              : !isMerchantIdValid
                ? "The ShareASale Merchant ID field must only contain numbers"
                : !areShareASaleFieldsUnique
                  ? "Please ensure all values are unique"
                  : null
          }
          onSave={async data => {
            await setupFlow.configMutation({
              ...data,
              merchantId,
              accessToken,
              apiVersion: shareASaleApiVersionOption.value,
              apiSecret,
              storeId,
              dataConfig: { conversionValue }
            });
          }}
        />
      </PageCard>
    </Step1Wrapper>
  );
};

const Step1Wrapper = styled.div`
  > ${PageCard}:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }
`;

const Step1Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

const Step1SectionInnerWrapper = styled.div`
  > div:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 3}px;
  }
`;

const Step1InputWrapperTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;

  > a {
    ${linkStyles};
  }
`;

const Step1AdvancedOptionsToggleButton = styled.button`
  margin-top: ${props => props.theme.gridBase * 3}px;
  display: flex;
  align-items: center;

  > div:first-child {
    ${normalTextStyles};
    margin-right: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    display: flex;
  }
`;

type Step1AdvancedOptionsWrapperProps = {
  inputWrapperWidth: number;
};

const Step1AdvancedOptionsWrapper = styled.div<Step1AdvancedOptionsWrapperProps>`
  width: ${props => props.inputWrapperWidth}px;
  padding-top: ${props => props.theme.gridBase * 2}px;
`;

/* ========================================================================== */

const Step2: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  return (
    <ConsentModeReader
      details={{
        type: "DESTINATION",
        isStepCompleted: setupFlow.isStepCompleted,
        setupGuideHref,
        name: destination.name
      }}
      isLoading={setupFlow.isLoading}
      initial={config.consentMode}
      onSave={data => setupFlow.configMutation(data)}
    />
  );
};

/* ========================================================================== */

const Step3: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  const [orderAttributeId, setOrderAttributeId] = useState(
    config.dataConfig.orderAttributeId
  );

  return (
    <TransactionIdentifier
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      options={["order_number", "name", "id"]}
      orderAttributeId={orderAttributeId}
      setOrderAttributeId={setOrderAttributeId}
      onSave={async () => {
        await setupFlow.configMutation({
          dataConfig: { orderAttributeId }
        });
      }}
    />
  );
};

/* ========================================================================== */

const Step4: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  const [productAttributeMapping, setProductAttributeMapping] = useState(
    config.dataConfig.productAttributeMapping
  );

  return (
    <ProductIdentifier
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      options={["sku", "product_id", "variant_id"]}
      productAttributeMapping={productAttributeMapping}
      setProductAttributeMapping={setProductAttributeMapping}
      onSave={async () => {
        await setupFlow.configMutation({
          dataConfig: { productAttributeMapping }
        });
      }}
    />
  );
};

/* ========================================================================== */

const Step5: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  return (
    <FilterTransactions
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      initialFilters={config.orderFilters}
      usesUnifiedCheckout={config.uses_unified_checkout}
      subscriptionTagName={config.subscriptionTagName}
      onSave={(data, options) => setupFlow.configMutation(data, options)}
    />
  );
};

/* ========================================================================== */

const Step6: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  return (
    <Subscriptions
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      details={{
        key: destination.configKey,
        config,
        onSave: (data, options) => setupFlow.configMutation(data, options)
      }}
    />
  );
};
